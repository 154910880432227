<template>
  <SecondaryPage
    title="이메일 변경"
    type="email"
    result="true"
    :isDone="isDone"
  >
    <BlackScreen :isShow="isDone" />
    <LabelWraps>
      <LabelWrap label="이메일">
        <Input
          type="email"
          placeholder="email@example.com"
          v-model="inputEmail"
          @focusInput="isDisabled = false;"
          @pressEnter="ChangeNickname"
        />
      </LabelWrap>
    </LabelWraps>
    <SubmitButton
      title="이메일 변경"
      :isDisabled="!inputEmail || isDisabled"
      @clickButton="ChangeNickname"
    />
    <Notification
      type="error"
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </SecondaryPage>
</template>

<script>
import SecondaryPage from "@/components/SecondaryPage.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import Notification from "@/components/Alerts/Notification.vue";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import LabelWraps from "@/components/LabelWraps.vue";
import LabelWrap from "@/components/LabelWrap.vue";
import Input from "@/components/Forms/Input.vue";
import axios from "axios";
export default {
  name: "ChangeNickname",
  components: {
    SecondaryPage,
    BlackScreen,
    Notification,
    SubmitButton,
    LabelWraps,
    LabelWrap,
    Input
  },
  data() {
    return {
      isDone: false,
      inputEmail: "",
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
    };
  },
  methods: {
    async ChangeNickname() {
      if(this.inputEmail.length > 0 && !this.isDisabled) {
        const url = "https://www.iflab.run/api/user/email";
        const token = localStorage.getItem("token")
        const header = { headers: { "Authorization": token } };
        await axios.patch(url, {
            email: this.inputEmail,
        }, header).catch((error) => {
          if (error.response.status === 401) {
            this.showNotification("이메일 형식이 올바르지 않습니다.");
            this.isDisabled = true;
          }
        }).then((response) => {
          if (response.status === 200) {
            this.isDone = true;
          }
        });
      }
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
  },
};
</script>
