<template>
  <div :class="{ modal: true, 'appear-modal': isShowModal }">
    <div class="modal-content">
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
    </div>
    <div class="button-wrap">
      <div class="button cancel" @click="clickCancel">{{ confirmText }}</div>
      <div class="button" @click="clickConfirm">{{ cancelText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickCancel() {
      this.$emit('cancel');
    },
    clickConfirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .modal {
    background-color: #26272c;
    & > .modal-content {
      & > .title {
        color: #c0c3ce;
      }
      & > .content {
        color: #c0c3ce;
      }
    }
    & > .button-wrap {
      & > .cancel {
        background-color: #383f4a;
        color: #8894ab;
      }
    }
  }
}
.modal {
  transition: all 0.3s;
  width: calc( 100vw - 48px );
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 500;

  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  & > .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 8px 8px 40px 8px;
    & > .title {
      color: #4f5560;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 4px;
      letter-spacing: -1px;
      text-align: left;
      word-break: keep-all;
    }
    & > .content {
      color: #4f5560;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -1px;
      text-align: left;
      text-align: left;
      word-break: keep-all;
    }
  }
  & > .button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    & > .button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;

      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -1px;

      background-color: #408cff;
      border-radius: 12px;
      padding: 16px 0;
      flex-grow: 1;
      flex-basis: 0;
      &:active {
        transition: all 0.1s;
        transform: scale(0.95);
        filter: brightness(0.7);
      }
    }
    & > .cancel {
      background-color: #f0f1f5;
      color: #5d646f;
    }
  }
}

.appear-modal {
  transition: all 0.3s;
  opacity: 1;
  transform: translate(-50%, -50%);
  pointer-events: auto;
}
</style>
