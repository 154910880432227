<template>
  <div class="calendar-page">
    <BackgroundScreen />
    <BlackScreen
      :isShow="isSelectDate"
      @close="closeSelectDate"
    />
    <TopBar
      :today="false"
      :calendar="true"
      :title="nowMonthText"
      @selectDate="openSelectDate"
    />
    <div
      v-for="(calendar, i) in calendarArray"
      :key="i"
      :class="{ calendar: true, dragging: dragY != 0, 'calendar-left': !dragging && changeMonth == -1, 'calendar-right': !dragging && changeMonth == 1 }"
      @touchstart="startDrag"
      @touchmove="handleDrag"
      @touchend="endDrag"
    >
      <div
        :class="{ 'calendar-wrap': true, no: isClickedDate }"
        :style="{
          transform: `translateY(${dragY}px)`,
          opacity: (dragY >= 0 ? 1-dragY/30 : 1+dragY/30)
        }"
      >
        <Loading v-if="isLoading" />
        <div
          v-else
          v-for="(date, i) in calendar"
          :key="i"
          :class="{
            date: true,
            'today-date':
              date.date == new Date().getDate() && date.month == (new Date().getMonth() + 1),
            'clicked-date': date.date == nowClickedDate.date && date.month == nowClickedDate.month,
            'not-now-month': date.notNowMonth
          }"
          :style="{ 'animation-delay': `${i * 0.01}s` }"
        >
          <div class="date-text">{{ date.date }}</div>
          <div class="event">
            <div
              v-for="(event, i) in date.events"
              :key="event.id"
              :class="{ 'event-item': true, 'hide-event-item': isClickedDate }"
              :style="{ 'background-color': event.color }"
            >
              <div v-if="i < 4" class="content">
                {{ event.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DatePicker
      :isSelectDate="isSelectDate"
      :nowYear="nowYear"
      :nowMonth="nowMonth"
      @close-date-picker="closeSelectDate"
      @select-date="selectDate"
    />
    <!--div :class="{ 'bottom-section': true, 'bottom-section-open': isClickedDate }" /-->
  </div>
</template>

<script>
import BackgroundScreen from "@/components/BackgroundScreen.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import TopBar from '@/components/Bar/TopBar'
import Loading from "@/components/Loading.vue";
import DatePicker from "@/components/Calendar/DatePicker.vue";
import axios from 'axios'
export default {
  name: 'Calendar',
  components: {
    BackgroundScreen,
    BlackScreen,
    TopBar,
    Loading,
    DatePicker,
  },
  data () {
    return {
      isClickedDate: false,
      nowClickedDate: {
        date: 0,
        month: 0,
        year: 0,
      },
      //
      dragging: false,
      startX: 0,
      dragX: 0,
      startY: 0,
      dragY: 0,
      //
      currentDate: new Date(),
      nowMonthText: '',
      changeMonth: 0,

      nowCalendar: [],
      calendarArray: [],

      isLoading: false,
      isSelectDate: false,

      // date-card
      nowYear: new Date().getFullYear(),
      nowMonth: new Date().getMonth() + 1,
    }
  },
  async created () {
    this.setCurrentMonth();

  },
  methods: {
    clickDate(date) {
      if (this.nowClickedDate.date == date.date
        && this.nowClickedDate.month == date.month
      ) {
        this.isClickedDate = false;
        this.nowClickedDate.date = 0;
        this.nowClickedDate.month = 0;
        this.nowClickedDate.year = 0;
      } else {
        this.isClickedDate = true;
        this.nowClickedDate.date = date.date;
        this.nowClickedDate.month = date.month;
      }
    },
    startDrag(event) {
      this.dragging = true;
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    handleDrag(event) {
      if (!this.dragging) return;
      

      const currentY = event.touches[0].clientY;
      const distanceY = currentY - this.startY;

      /*
      this.dragX = distanceX / 5;
      if (distanceX >= 150) { // 오른쪽으로 드래그
        this.changeMonth = -1;
      } else if(distanceX <= -150) { // 왼쪽으로 드래그
        this.changeMonth = 1;
      } else {
        this.changeMonth = 0;
      }
      */

      this.dragY = distanceY / 10;
      if (distanceY >= 150) { // 아래쪽으로 드래그
        this.changeMonth = -1;
      } else if(distanceY <= -150) { // 위쪽으로 드래그
        this.changeMonth = 1;
      } else {
        this.changeMonth = 0;
      }
    },
    endDrag() {
      this.dragging = false;
      this.dragX = 0;
      this.dragY = 0;
      if (this.changeMonth == -1) {
        this.setPrevMonth();
      } else if (this.changeMonth == 1) {
        this.setNextMonth();
      }
      setTimeout(() => {
        this.changeMonth = 0;
      }, 500);
    },
    async setCurrentMonth() {
      // this.isLoading = true;
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      this.nowMonthText = `${year}년 ${month}월`;
      this.nowYear = year;
      this.nowMonth = month;

      const prevDateInfo = new Date(year, month - 1, 0);
      const prevYear = prevDateInfo.getFullYear();
      const prevMonth = prevDateInfo.getMonth() + 1;
      const prevDate = prevDateInfo.getDate();
      const prevDay = prevDateInfo.getDay();

      const nextDateInfo = new Date(year, month, 0);
      const nextYear = month == 12 ? year + 1 : year;
      const nextMonth = (month + 1) > 12 ? 1 : month + 1;
      const nextDate = nextDateInfo.getDate();

      const url = "https://www.iflab.run/api/calendar";
      const token = localStorage.getItem("token")
      const header = { headers: { "Authorization": token } };

      const response = await axios.post(url, {
          year: year,
          month: month
      }, header);

      /*
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
      */

      let eventArray = response.data;

      this.nowCalendar = [];
      for (let i = prevDate - prevDay; i <= prevDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: prevMonth,
          year: prevYear,
          notNowMonth: true,
          string: this.dateToString(prevYear, prevMonth, i),
        });
      }
      for (let i = 1; i <= nextDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: month,
          year: year,
          notNowMonth: false,
          string: this.dateToString(year, month, i),
        });
      }
      for (let i = 1; i <= 42 - prevDay - 1 - nextDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: nextMonth,
          year: nextYear,
          notNowMonth: true,
          string: this.dateToString(nextYear, nextMonth, i),
        });
      }
      for (let i = 0; i < this.nowCalendar.length; i++) {
        this.nowCalendar[i].events = [];
        for (let j = 0; j < eventArray.length; j++) {
          for (let k = 0; k < eventArray[j].todos.length; k++) {
            if (this.nowCalendar[i].string == eventArray[j].todos[k].deadline) {
              this.nowCalendar[i].events.push({
                id: eventArray[j].todos[k].id,
                content: eventArray[j].todos[k].content,
                color: eventArray[j].categoryColor,
              });
            }
          }
        }
      }

      this.calendarArray.push(this.nowCalendar);
    },
    setNextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.setCurrentMonth();
      this.calendarArray.shift(1);
    },
    setPrevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
      this.setCurrentMonth();
      this.calendarArray.shift(1);
    },
    selectDate(year, month) {
      this.currentDate.setFullYear(year);
      this.currentDate.setMonth(month - 1);
      this.setCurrentMonth();
      this.calendarArray.shift(1);
    },
    dateToString(year, month, date) {
      return `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0') }`;
    },
    openSelectDate() {
      this.isSelectDate = true;
    },
    closeSelectDate() {
      this.isSelectDate = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .calendar-page {
    background-color: #26272c;
    & > .calendar {
      background-color: #26272c;
      & > .calendar-wrap {
        & > .date {
          background-color: #26272c;
          &.today-date {
            & > .date-text {
              color: #408cff;
            }
          }
          & > .date-text {
            color: #ffffff;
          }
          & > .event {
            & > .event-item {
              & > .content {
                color: #1a202c;
              }
            }
          }
        }
      }
    }
  }
}


.calendar-page {
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 56px;
}

.bottom-section {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  background-color: #ffffff;
  height: calc( 25vh + 56px );
}

.bottom-section-open {
  transition: all 0.3s;
}
.calendar-left {
  animation: open-left 0.4s forwards;
}
.calendar-right {
  animation: open-right 0.4s forwards;
}
.calendar {

  transition: all 0.3s;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #f0f2f5;
  padding: 0 2px;
  gap: 2px;

  user-select: none;
  & > .calendar-wrap {
    transition: all 0.3s;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(7, 1fr);
    padding-top: 90px;
    height: calc( 100vh - 64px );
    gap: 2px;
    &.no {
      transition: all 0.3s;
      height: 75vh;
    }
    & > .date {
      animation: open-date 0.3s forwards;
      transform: scaleY(0) rotateX(90deg);

      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      height: calc( ( 100vh - 230px ) / 6 );

      background-color: #f0f2f5;
      border-radius: 0px;
      overflow: hidden;

      -webkit-tap-highlight-color: transparent;

      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        background-color: #408cff20;
        border-radius: 4px;
      }
      & > .date-text {
        transition: all 0.3s;

        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #408cff00;
        align-self: center;
        margin-top: 4px;
        flex-shrink: 0;

        color: #333333;
        font-size: 10px;
        font-weight: 300;
        text-align: center;
        line-height: 18px;
      }

      & > .event {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 2px;
        & > .event-item {
          transition: all 0.3s;
          transform: scaleY(1);
          transform-origin: top;
          width: 100%;
          height: 18px;

          display: flex;
          flex-direction: column;
          align-items: stretch;
          background-color: #e2e8f0;
          border-radius: 5px;
          padding: 2px 2px 2px 4px;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & > .content {
            font-size: 10px;
            letter-spacing: -1px;
            font-weight: 500;
            text-align: left;
            color: #f2faff;

            overflow: hidden;
          }
        }
        & > .hide-event-item {
          transition: all 0.3s;
          transform: scaleY(0);
          opacity: 0;
          height: 0;
        }
      }

      &.today-date {
        transition: all 0.3s;

        &:active {
          transition: all 0.1s;
          transform: scale(0.9);
          background-color: #408cff20;
        }

        & > .date-text {
          transition: all 0.3s;
          color: #408cff;
          font-weight: bold;
          background-color: #408cff40;
          border-radius: 50%;
        }
      }

      &.clicked-date {
        transition: all 0.3s;
        background-color: #408cff40;
        color: #408cff;

        &:active {
          transition: all 0.1s;
          transform: scale(0.9);
          background-color: #408cff20;
        }

        & > .date-text {
          transition: all 0.3s;
          background-color: #408cff;
          color: #ffffff;
        }
      }

      &.not-now-month {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.dragging {
  transition: none;
  pointer-events: none;
  & > .calendar-wrap {
    transition: none;
    & > .date {
      transition: none;
      &:active {
        transition: none;
        transform: scale(1);
        background-color: #f0f2f5;
      }
      &.clicked-date {
        transition: none;
        &:active {
          transition: none;
          background-color: #408cff40;
          transform: scale(1);
        }
      }
    }
  }
}

@keyframes open-right {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes open-left {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes open-date {
  from {
    transform: scaleY(0) rotateX(-90deg);
  }
  to {
    transform: scaleY(1) rotateX(0deg);
  }
}
</style>
