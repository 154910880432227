<template>
  <div
    :class="{
     'black-screen': true,
     'active-black-screen': noScroll
    }"
    @click="close"
  />
</template>


<script>
export default {
  name: 'BlackScreen',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  // isShow에 따라 변경
  computed: {
    noScroll() {
      return this.$store.state.editCategory ||
      this.$store.state.editTodo ||
      this.$store.state.editSubject ||
      this.$store.state.editSubjectInfo ||
      this.$store.state.openCard ||
      this.isShow;
    },
  },
  watch: {
    noScroll(newValue) {
      const body = document.querySelector('body');
      if(newValue) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    },

  },
  methods: {
    close() {
      if(this.$store.state.openCard) {
        this.$store.commit('setEditOpenCard', false);
        this.$store.commit('setEditBeforeCard', false);
        setTimeout(() => {
          if(!this.$store.state.openCard) {
            this.$store.commit('setEditSubjectId', -1);
            this.$store.commit('setEditSubjectName', '');
            this.$store.commit('setEditSubjectDay', []);
            this.$store.commit('setEditSubjectExam', []);
            this.$store.commit('setEditSubjectAssignment', []);
          }
        }, 300);
      } else {
        this.$emit('close');
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.black-screen {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000bf;
  z-index: 101;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
.active-black-screen {
  transition: all 0.3s;
  opacity: 1;
  pointer-events: auto;
}
</style>
