<template>
  <div class="development-note">
    <TopBar
      title="개발 노트"
      @clickButton="closeNote"
    />
    <div class="intro">
      <div class="intro-content" style="color: #408cff">
        새로 나올 기능들을
      </div>
      <div class="intro-content">
        미리 알려드릴게요!
      </div>
      <img src="@/assets/new/intro-00.svg" />
    </div>
    <div class="introduce">
      <div class="wrap" style="animation-delay: 7.5s">
        <div class="label">히스토리</div>
        <div class="title">
          ‘저번 주에 뭐했었더라?’
        </div>
        <div class="content">
          과거에 완료한 할 일들을 언제든지<br>
          다시 확인할 수 있는 서비스에요!<br>
          실수로 할 일을 완료한 경우에도 복구할 수 있어요! 🤗
        </div>
      </div>
      <div class="wrap" style="animation-delay: 7.8s">
        <div class="label">운동</div>
        <div class="title">
          ‘운동 좋아하시나요?’
        </div>
        <div class="content">
          운동하는 사람들을 위해<br>
          주기를 관리할 수 있는 서비스에요!<br>
          이번 기회에 건강을 챙겨보시는건 어떨까요? 💪
        </div>
      </div>
      <div class="wrap" style="animation-delay: 8.1s">
        <div class="label">친구</div>
        <div class="title">
          ‘너 다음주에 뭐해?’
        </div>
        <div class="content">
          친구들끼리 일정을 공유할 수 있도록 도와드려요!<br>
          물론 혼자만 알고 싶은 일정들은<br>
          ‘비공개'할 수도 있답니다 🤫
        </div>
      </div>
      <div class="wrap" style="animation-delay: 9s">
        <div class="more">
          추가로 원하는 기능이 있다면 언제든지 문의해주세요!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/Bar/SecondaryTopBar";
export default {
  name: "DevelopmentNote",
  components: { TopBar, },
  methods: {
    closeNote() {
      this.$router.go(-1);
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.development-note {
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  min-height: 100vh;
  position: relative;
  & > .intro {
    z-index: 1000;
    animation: showIntro 2s ease-in-out forwards, hideIntro 1s 7s ease-in-out forwards;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #000000, #408cff);
    user-select: none;
    pointer-events: none;
    & > .intro-content {
      animation: showText 1s 3s ease-in-out forwards, showText 1s 5.5s ease-in-out forwards reverse;
      color: #ffffff;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -1px;
      opacity: 0;
    }
    & > img {
      animation: showImg 1s 2s cubic-bezier(0.21, 1.28, 0.29, 1.28) forwards, showImg 1s 6s cubic-bezier(0.21, 1.28, 0.29, 1.28) forwards reverse;

      opacity: 0;
      width: 160px;
      height: 160px;
      margin-top: 24px;
    }
  }
  & > .introduce {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px 16px;
    & > .wrap {
      animation: showWrap 1s ease-in-out forwards;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      opacity: 0;

      margin-bottom: 40px;
      & > .label {
        background-color: #408cff;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -1px;
        padding: 2px 8px;
        margin-bottom: 4px;

        border-radius: 4px;
        user-select: none;
      }
      & > .title {
        color: #4f7ec6;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -1px;
        margin-bottom: 4px;
        text-align: left;
      }
      & > .content {
        color: #85a9de;
        font-size: 16px;
        letter-spacing: -1px;
        text-align: left;
      }
      & > .more {
        width: 100%;
        background-color: #408cff;
        border-radius: 32px;
        padding: 8px 0;
        margin-top: 40px;
        color: #ffffff;
        font-size: 16px;
        letter-spacing: -1px;
        text-align: center;
      }
    }
  }
}

@keyframes showIntro {
  0% {
    filter: brightness(0);
  }
  100% {
    filter: brightness(1);
  }
}

@keyframes showImg {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hideIntro {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes showWrap {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
