import Vue from 'vue'
import VueRouter from 'vue-router'
import TodolistRoutes from '@/router/Todolist'
import SettingRoutes from '@/router/Setting'
import WebRoutes from '@/router/Web'
import HomePage from '../views/old/HomePage'
import History from '../views/History'
import Calendar from '../views/Calendar'
import Timetable from '../views/Timetable'
import SignIn from '../views/SignIn'

Vue.use(VueRouter)

if (!window.Kakao.isInitialized()) {
  window.Kakao.init('32a7278b8c5fe6083e633d07857725d5');
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

const routes = [
  ...TodolistRoutes,
  ...SettingRoutes,
  ...WebRoutes,
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/timetable',
    name: 'Timetable',
    component: Timetable
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/homepage',
    name: 'HomePage',
    component: HomePage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

export default router
