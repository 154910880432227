import WebTodolist from '../views/web/Todolist'
import WebSignIn from '../views/web/SignIn'
  
const WebRoutes = [
  {
    path: '/web/',
    name: 'WebTodolist',
    component: WebTodolist
  },
  {
    path: '/web/signin',
    name: 'WebSignIn',
    component: WebSignIn
  },
]

export default WebRoutes;
