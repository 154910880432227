<template>
  <div class="guide">
    <div class="guide-text-wrap">
      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="30" height="30" rx="8" fill="#F2F2F2"></rect><path d="M18.5855 11.4102H20.5C21.6046 11.4102 22.5 12.3056 22.5 13.4102V22.4102C22.5 23.5147 21.6046 24.4102 20.5 24.4102H10.5C9.39543 24.4102 8.5 23.5147 8.5 22.4102V13.4102C8.5 12.3056 9.39543 11.4102 10.5 11.4102H12.2303" stroke="#0381FE" stroke-linecap="round"></path><path d="M12.6768 8.41341L15.2507 5.83944C15.3886 5.70161 15.612 5.70161 15.7499 5.83944L18.3238 8.41341M15.5003 16.8251V5.58984" stroke="#0381FE" stroke-linecap="round"></path></svg>
      <div class="text">
        아이콘을 누르고<br>
        <b>‘홈 화면에 추가’</b>를 누르면<br>
        어플처럼 사용할 수 있어요!
      </div>
    </div>
    <img src="@/assets/new/guide-icon.svg" />
  </div>
</template>

<script>
export default {
  name: "IOSGuide",
};
</script>

<style lang="scss" scoped>
.guide {
  animation: fadeIn 2s forwards;
  z-index: 200;
  position: fixed;
  bottom: 16px;
  left: 50%;
  width: max-content;

  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  user-select: none;
  pointer-events: none;
  & > .guide-text-wrap {
    padding: 16px 16px 24px 16px;
    border-radius: 32px 32px 16px 16px;
    background: linear-gradient(to bottom, #408cff, transparent);
    margin-bottom: 12px;
    & > .text {
      color: #ffffff;
      letter-spacing: -1px;
    }
  }
  & > img { 
    animation: move-up-down 4s 0.5s infinite;
    width: 64px;
    height: 64px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move-up-down {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(6px) scale(1.1);
  }
  50% {
    transform: translateY(-6px);
  }
  75% {
    transform: translateY(6px) scale(1.2) rotateY(180deg);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
