var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subject"},[_c('div',{staticClass:"wrap",on:{"click":_vm.openCard}},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"subject-wrap"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"day"},[_vm._v(_vm._s(_vm.day.join(', ')))])]),_c('div',{staticClass:"dday-wrap"},[_c('div',{class:{
          dday: true,
          exam: true,
          deadline: Number(_vm.diffExamDday) <= 7,
          expired: _vm.stringExamDday == '기한만료'
        }},[_vm._v(" "+_vm._s(_vm.stringExamDday)+" ")]),_c('div',{class:{
          dday: true,
          assignment: true,
          deadline: Number(_vm.diffAssignmentDday) <= 5,
          expired: _vm.stringAssignmentDday == '기한만료'
        }},[_vm._v(" "+_vm._s(_vm.stringAssignmentDday)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }