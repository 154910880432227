<template>
  <div
    :class="{
    'permit-modal': true,
    'appear-permit-modal': isShowModal
    }"
  >
    <div class="icon">
      <img src="@/assets/new/notification-icon.svg" />
    </div>
    <div class="content">
      <div class="title">일정 알림을 받아보시겠어요?</div>
      <div class="content">알림을 허용하면 일정을 미리 알려드릴게요!</div>
    </div>
    <div class="button-wrap">
      <div class="button" @click="permitPushNotification">알림 받기</div>
    </div>
  </div>
</template>

<script>
import { registerServiceWorker } from "@/utils/register_service_worker.js";
export default {
  name: "PermitModal",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    permitPushNotification() {
      this.$emit("permit");
      return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
          resolve(result);
          registerServiceWorker('./service_worker.js', 'BD0FccV41zq80ZCCmEgFrT8zt9D9RBrmYAHi5K4o2C76YphbA2uyg7veFD3aDSpDDR0vLilQiUvl31wHm4yw6yc');
        });
        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }}).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
          // 거절한 경우
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.permit-modal {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;

  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 24px 24px 0 0;
  padding: 16px 16px 24px 16px;
  & > .icon {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 16px;
    align-self: center;
    padding: 4px;
    margin-bottom: 8px;

    & > img {
      width: 32px;
      height: 32px;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: " ";
      width: 40px;
      height: 40px;
      filter: blur(12px);
      background-color: #ffb800;
      opacity: 0.5;
    }
  }
  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 8px 0 40px;
    & > .title {
      color: #4f5560;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 4px;
      letter-spacing: -1px;
      text-align: left;
      word-break: keep-all;
    }
    & > .content {
      color: #4f5560;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -1px;
      text-align: left;
      text-align: left;
      word-break: keep-all;
    }
  }
  & > .button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    & > .button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;

      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -1px;

      background-color: #408cff;
      border-radius: 12px;
      padding: 16px 0;
      flex-grow: 1;
      flex-basis: 0;
      &:active {
        transition: all 0.1s;
        transform: scale(0.95);
        filter: brightness(0.7);
      }
    }
    & > .cancel {
      background-color: #f0f1f5;
      color: #5d646f;
    }
  }
}

.appear-permit-modal {
  opacity: 1;
  transform: translateY(0%);
  & > .icon {
    & > img {
      animation: rotate 3s 0.5s infinite ease-in-out;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(30deg);
  }
  9% {
    transform: rotate(-15deg);
  }
  12% {
    transform: rotate(7deg);
  }
  14% {
    transform: rotate(0deg);
  }
}
</style>
