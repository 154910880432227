import User from "@/api/User";
import Todo from "@/api/Todo";
import Calendar from "@/api/Calendar";

export const UserAPI = User;
export const TodoAPI = Todo;
export const CalendarAPI = Calendar;

export * from "@/api/instance";
export * from "@/api/Todo";
export * from "@/api/Calendar";
export * from "@/api/User";
