<template>
  <div
    :class="{
      'submit-button': true,
      gray: isGray && isDisabled,
      disabled: isDisabled
    }"
    @click="emitClickButton"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    isGray: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClickButton() {
      this.$emit('clickButton');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .submit-button {
    background-color: #408cff;
    &:hover {
      transition: all 0.1s;
      filter: brightness(1.1);
    }
    &:active {
      transition: all 0.1s;
      filter: brightness(1.2);
    }
  }
  & .disabled {
    background-color: #393b43;
    &:hover {
      transition: all 0.1s;
      filter: brightness(1);
    }
  }
}

.submit-button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 0;

  background-color: #408cff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;

  cursor: pointer;
  user-select: none;

  &:hover {
    transition: all 0.1s;
    filter: brightness(0.9);
  }
  &:active {
    transition: all 0.1s;
    filter: brightness(0.7);
  }
}
.disabled {
  transition: all 0.2s;
  background-color: #ffffff;
  color: #cecece;
  cursor: default;
  &:hover {
    transition: all 0.1s;
    filter: brightness(1);
  }
  &:active {
    transition: all 0.1s;
    filter: brightness(1);
  }
}
.gray {
  background-color: #f0f0f0;
  color: #a0a0a0;
}
</style>
