<template>
  <div
    class="background-screen"
  />
</template>

<script>
export default {
  name: 'BackgroundScreen',
}
</script>
<style lang="scss" scoped>
.dark-mode {
  & .background-screen {
    background-color: #26272c;
  }
}

.background-screen {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #f0f2f5;
}
</style>
