<template>
  <div class="empty" v-if="isExist">
    <img src="@/assets/new/empty-icon.svg" />
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "EmptyTodolist",
  props: {
    isExist: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .empty {
    opacity: 0.25;
    animation: fadeIn-dark 1s;
  }
}
.empty {
  animation: fadeIn 1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 120px;
    height: 80px;
    margin-bottom: 16px;
  }
  color: #a0a0a0;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -1px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeIn-dark {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 0.25;
    transform: translate(-50%, -50%);
  }
}
</style>
