<template>
	<div class="homepage">
		<div class="top-bar">
			<div class="left">
				<img src="@/assets/HomePage/small-todolist-logo.svg">
				<div class="text">
					TODOLIST
				</div>
				<div class="text small">
					Beta
				</div>
			</div>
			<div class="right">
				<div class="btn signin">
					<div class="text">
						로그인
					</div>
				</div>
				<div class="btn">
					<div class="text">
						회원가입
					</div>
				</div>
				<img src="@/assets/HomePage/change-mode.svg">
			</div>
		</div>
		<div class="container">
			<div class="wrap">
				<div class="banner">
				</div>
				<div class="content">
					<div class="intro">
						<div class="title">
							TODOLIST
						</div>
						<div class="sub">
							당신의 모든 일정을 관리합니다.	
						</div>
					</div>
					<div class="menu">
						<div class="signin-box main">
							<div class="text">
								빠른 로그인	
							</div>
						</div>
						<div class="signin-box">
							<img src="@/assets/HomePage/google.svg">
							<div class="text">
								Google로 연결
							</div>
						</div>
						<div class="signin-box">
							<img src="@/assets/HomePage/instagram.svg">
							<div class="text">
								Instagram으로 연결
							</div>
						</div>
						<div class="signin-box">
							<img src="@/assets/HomePage/github.svg">
							<div class="text">
								Github로 연결
							</div>
						</div>
					</div>
					<div class="line" />
					<div class="notice">
						Todolist에 가입하면&nbsp;
						<span class="link">서비스 이용약관</span>
						&nbsp;및&nbsp;
						<span class="link">개인정보처리방침</span>
						에 동의하게 됩니다.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomePage',
	}
</script>

<style lang="scss" scoped>
	.homepage {
		display: flex;
		flex-direction: column;
		& > .top-bar {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			background-color: #4E4E4E;
			
			height: 44px;
			padding: 0 40px;
			& > .left {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				cursor: pointer;
				user-select: none;
				& > img {
					margin-right: 4px;
				}
				& > .text {
					color: #ffffff;
					font-size: 16px;
					font-weight: bold;
				}
				& > .small {
					color: #106DFA;
					font-size: 10px;
					font-style: italic;
				}
			}
			& > .right {
				display: flex;
				flex-direction: row;
				gap: 16px;
				& > .btn {
					display: flex;
					flex-direction: row;
					align-items: center;
					cursor: pointer;
					& > .text {
						color: #ffffff;
						font-size: 12px;
						font-weight: bold;
					}
				}
				& > .signin {
					background-color: #00A3FF;
					padding: 8px 16px;
					border-radius: 8px;
				}
				& > img {
					cursor: pointer
				}
			}
		}
		& > .container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 300px;
			background-color: #1C1C1C;
			& > .wrap {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 80px 0;
				width: 100%;
				& > .banner {
					width: 770px;
					height: 770px;
					background-color: #cecece;
					border-radius: 40px;
				}
				& > .content {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					& > .intro {
						display: flex;
						flex-direction: column;
						margin-bottom: 80px;
						& > .title {
							color: #ffffff;
							font-size: 64px;
							font-weight: bold;
						}
						& > .sub {
							color: #B4B4B4;
							font-size: 24px;
						}
					}
					& > .menu {
						display: flex;
						flex-direction: column;
						gap: 20px;
						user-select: none;
						& > .signin-box {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							
							width: 330px;
							height: 52px;
							border: solid 1px #8B8B8B;
							border-radius: 16px;
							
							cursor: pointer;
							gap: 4px;
							& > .text {
								color: #ffffff;
								font-size: 16px;
								font-weight: 300;
							}
							&:hover {
								background-color: #333333;
							}
							&:active {
								filter: brightness(0.7);
							}
						}
						& > .main {
							border: none;
							background: linear-gradient(to bottom, #04A3FB, #6A5EFF);
							& > .text {
								font-weight: 500;
							}
							&:hover {
								background: linear-gradient(to bottom, #46BDFF, #9B93FF);
							}
							&:active {
								background: linear-gradient(to bottom, #04A3FB, #6A5EFF);
								filter: brightness(0.7);
							}
						}
					}
					& > .line {
						width: 440px;
						height: 1px;
						background-color: #ADADAD;
						margin: 20px 0 24px 0;
					}
					& > .notice {
						display: flex;
						flex-direction: row;
						color: #ffffff;
						font-size: 12px;
						font-weight: 200;
						& > .link {
							color: #1070FF;
							font-weight: bold;
							cursor: pointer;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
</style>