<template>
  <div :class="{ wrap: true, 'no-active': noActive }" @click="clickOption">
    <div class="left-wrap">
      <div class="title"> {{ title }} </div>
      <div v-if="subtitle" class="subtitle"> {{ subtitle }} </div>
    </div>
    <div class="right-wrap">
      <img
        v-if="type == 'blueArrow'"
        src="@/assets/new/blue-arrow-icon.svg"
      />
      <img
        v-else-if="type == 'redArrow'"
        src="@/assets/new/red-arrow-icon.svg"
      />
      <div
        v-else-if="type == 'radioButton'"
        :class="{ 'radio-button': true, 'radio-button-on': isOn }"
         @click="clickRadioButton"
       />
      <div
        v-else-if="type == 'toggle'"
        :class="{ toggle: true, 'toggle-on': isOn }"
        @click="clickToggle"
      />
      <span v-else-if="type == 'text'"> {{ text }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingOption",
  props: {
    noActive: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    isOn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickOption() {
      this.$emit("clickOption");
    },
    clickRadioButton() {
      this.$emit("clickRadioButton");
    },
    clickToggle() {
      this.$emit("clickToggle");
    }
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .wrap {
    &:active {
      filter: brightness(1.3);
      background-color: #26272c;
    }
    & > .title {
      color: #87898f;
    }
    & > .right-wrap {
      & > .radio-button {
        //background-color: #1c1c1c;
        transition: all 0.3s;
        background-color: #3e3e3e;
        border: 0px solid #408cff00;
      }
      & > .radio-button-on {
        background-color: #3e3e3e;
        border: 6px solid #408cff;
      }
      & > .toggle {
        background-color: #3e3e3e;
      }
      & > .toggle-on {
        background-color: #408cff;
      }
    }
  }
  & > .no-active {
    transition: all 0.3s;
    &:active {
      filter: brightness(1);
      background-color: transparent;
    }
  }
}

.wrap {
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin: 0 4px;
  border-radius: 8px;
  user-select: none;
  &:active {
    transition: all 0.1s;
    transform: scale(0.98);
    filter: brightness(0.9);
    background-color: #f0f0f0;
  }
  & > .left-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    & > .title {
      font-size: 16px;
      font-weight: 400;
      color: #606060;
      letter-spacing: -1px;
    }
    & > .subtitle {
      font-size: 12px;
      font-weight: 400;
      color: #a0a0a0;
      letter-spacing: -1px;
    }
  }
  & > .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #606060;
    font-size: 14px;
    font-weight: bold;
    & > .radio-button {
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e3e5ea;
      border: 0px solid #408cff00;
    }
    & > .radio-button-on {
      transition: all 0.3s;
      background-color: #ffffff;
      border: 6px solid #408cff;
    }
    & > .toggle {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.3s;
      position: relative;
      width: 40px;
      height: 20px;
      border-radius: 10px;
      background-color: #e3e5ea;
      cursor: pointer;
      &::after {
        -webkit-tap-highlight-color: transparent;
        transition: all 0.3s;
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #ffffff;
      }
      &:active {
        &::after {
          transform: scale(1.1, 0.9);
          filter: brightness(0.95);
        }
      }
    }
    & > .toggle-on {
      transition: all 0.3s;
      background-color: #408cff;
      &::after {
        top: 2px;
        width: 16px;
        height: 16px;
        left: 22px;
      }
    }
    & > img {
      width: 16px;
      height: 16px;
    }
  }
  &.no-active {
    transition: all 0.3s;
    &:active {
      transform: scale(1);
      filter: brightness(1);
      background-color: transparent;
    }
  }
}
</style>
