<template>
  <div class="calendar-container">
    <div class="topbar">
      <div class="title">
        {{ nowMonth }}
      </div>
    </div>
    <div
      :class="{ calendar: true, dragging: dragX != 0, }"
      v-on:mousedown="startDrag"
      v-on:mouseup="endDrag"
    >
      <div
        v-for="(calendar, i) in calendarArray"
        :key="i"
        :class="{ 'calendar-wrap': true, no: isClickedDate,'calendar-left': !dragging && changeMonth == -1, 'calendar-right': !dragging && changeMonth == 1  }"
        :style="{
          transform: `translateX(${dragX}px)`,
          opacity: (dragX >= 0 ? 1-dragX/60 : 1+dragX/60)
        }"
      >
        <Loading v-if="isLoading" />
        <div
          v-else
          v-for="(date, i) in calendar"
          :key="i"
          :class="{
            date: true,
            'today-date':
              date.date == new Date().getDate() && date.month == (new Date().getMonth() + 1),
            'clicked-date': date.date == nowClickedDate.date && date.month == nowClickedDate.month,
            'not-now-month': date.notNowMonth
          }"
        >
          <div class="date-text">{{ date.date }}</div>
          <div class="event">
            <div
              v-for="(event, i) in date.events"
              :key="event.id"
              :class="{ 'event-item': true, 'hide-event-item': isClickedDate }"
              :style="{ 'background-color': event.color }"
            >
              <div v-if="i < 4" class="content">
                {{ event.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { CalendarAPI } from '@/api';
import Loading from "@/components/Loading.vue";
import EventBus from '@/utils/EventBus.js';
import axios from 'axios'
export default {
  name: 'Calendar',
  components: {
    Loading,
  },
  data () {
    return {
      isClickedDate: false,
      nowClickedDate: {
        date: 0,
        month: 0,
        year: 0,
      },
      dragging: false,
      startX: 0,
      currentDate: new Date(),
      nowMonth: '',
      changeMonth: 0,
      dragX: 0,

      nowCalendar: [],
      calendarArray: [],
      isLoading: false,
    }
  },
  async created () {
    await this.setCurrentMonth();
    EventBus.$on('updateTodo', (mode, newTodo) => {
      this.updateCurrentMonth(mode, newTodo);
    });
  },
  methods: {
    clickDate(date) {
      if (this.nowClickedDate.date == date.date
        && this.nowClickedDate.month == date.month
      ) {
        this.isClickedDate = false;
        this.nowClickedDate.date = 0;
        this.nowClickedDate.month = 0;
        this.nowClickedDate.year = 0;
      } else {
        this.isClickedDate = true;
        this.nowClickedDate.date = date.date;
        this.nowClickedDate.month = date.month;
      }
    },
    startDrag() {
      this.dragging = true;
      this.startX = event.clientX;
      // 마우스 다운 이벤트 처리 로직을 작성합니다.
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('mouseup', this.endDrag);
    },
    handleDrag(event) {
      if (!this.dragging) return;
      
      const currentX = event.clientX;
      const distanceX = currentX - this.startX;

      this.dragX = distanceX / 5;
      if (distanceX >= 300) { // 오른쪽으로 드래그
        this.changeMonth = -1;
      } else if(distanceX <= -300 ) { // 왼쪽으로 드래그
        this.changeMonth = 1;
      } else {
        this.changeMonth = 0;
      }
    },
    endDrag() {
      if (!this.dragging) return;
      this.dragging = false;
      this.dragX = 0;

      if (this.changeMonth == -1) {
        this.setPrevMonth();
      } else if (this.changeMonth == 1) {
        this.setNextMonth();
      }
      this.changeMonth = 0;
      // 마우스 업 이벤트 처리 로직을 작성합니다.
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('mouseup', this.endDrag);
    },
    async setCurrentMonth() {
      // this.isLoading = true;
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      this.nowMonth = `${year}년 ${month}월`;

      const prevDateInfo = new Date(year, month - 1, 0);
      const prevYear = prevDateInfo.getFullYear();
      const prevMonth = prevDateInfo.getMonth() + 1;
      const prevDate = prevDateInfo.getDate();
      const prevDay = prevDateInfo.getDay();

      const nextDateInfo = new Date(year, month, 0);
      const nextYear = month == 12 ? year + 1 : year;
      const nextMonth = (month + 1) > 12 ? 1 : month + 1;
      const nextDate = nextDateInfo.getDate();
      
      const url = "https://www.iflab.run/api/calendar";
      const token = localStorage.getItem("token")
      const header = { headers: { "Authorization": token } };

      const response = await axios.post(url, {
          year: year,
          month: month
      }, header);

      
      /*
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
      */
      
      let eventArray = response.data;

      this.nowCalendar = [];
      for (let i = prevDate - prevDay; i <= prevDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: prevMonth,
          year: prevYear,
          notNowMonth: true,
          string: this.dateToString(prevYear, prevMonth, i),
        });
      }
      for (let i = 1; i <= nextDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: month,
          year: year,
          notNowMonth: false,
          string: this.dateToString(year, month, i),
        });
      }
      for (let i = 1; i <= 42 - prevDay - 1 - nextDate; i++) {
        this.nowCalendar.push({
          date: i,
          month: nextMonth,
          year: nextYear,
          notNowMonth: true,
          string: this.dateToString(nextYear, nextMonth, i),
        });
      }
      for (let i = 0; i < this.nowCalendar.length; i++) {
        this.nowCalendar[i].events = [];
        for (let j = 0; j < eventArray.length; j++) {
          for (let k = 0; k < eventArray[j].todos.length; k++) {
            if (this.nowCalendar[i].string == eventArray[j].todos[k].deadline) {
              this.nowCalendar[i].events.push({
                id: eventArray[j].todos[k].id,
                content: eventArray[j].todos[k].content,
                color: eventArray[j].categoryColor,
              });
            }
          }
        }
      }

      this.calendarArray.push(this.nowCalendar);
    },
    setNextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.setCurrentMonth();
      this.calendarArray.shift(1);
    },
    setPrevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
      this.setCurrentMonth();
      this.calendarArray.shift(1);
    },
    dateToString(year, month, date) {
      return `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0') }`;
    },
    updateCurrentMonth(mode, newTodo) {
      // 생성인 경우 캘린더에 추가한다.
      // 하지만 현재 보여지는 캘린더의 날짜안에 포함되지 않으면 제외한다
      if (mode == '생성') {
        for (let i = 0; i < this.nowCalendar.length; i++) {
          if (this.nowCalendar[i].string == newTodo.deadline) {
            this.nowCalendar[i].events.push({
              id: newTodo.id,
              content: newTodo.content,
              color: newTodo.category_color,
            });
          }
        }
      } else if (mode == '수정') {
        for (let i = 0; i < this.nowCalendar.length; i++) {
          // 해당 이벤트 삭제
          if (this.nowCalendar[i].string == newTodo.deadline) {
            for (let j = 0; j < this.nowCalendar[i].events.length; j++) {
              if (this.nowCalendar[i].events[j].id == newTodo.id) {
                this.nowCalendar[i].events.splice(j, 1);
              }
            }
            // 새로운 이벤트 추가
            if (this.nowCalendar[i].string == newTodo.deadline) {
              this.nowCalendar[i].events.push({
                id: newTodo.id,
                content: newTodo.content,
                color: newTodo.category_color,
              });
            }
          }
        }
      } else if (mode == '삭제') {
        for (let i = 0; i < this.nowCalendar.length; i++) {
          if (this.nowCalendar[i].string == newTodo.deadline) {
            for (let j = 0; j < this.nowCalendar[i].events.length; j++) {
              if (this.nowCalendar[i].events[j].content == newTodo.content) {
                this.nowCalendar[i].events.splice(j, 1);
              }
            }
          }
        }
      }
      this.calendarArray.shift(1);
      this.calendarArray.push(this.nowCalendar);
      this.calendarArray = this.calendarArray.slice();

    }
  }
}
</script>

<style lang="scss" scoped>
.dark-mode {
  .calendar-container {
    transition: all 0.3s;
    background-color: #1c1c1c;
    & > .topbar {
      transition: all 0.3s;
      background-color: #26272c;
      & > .title {
        transition: all 0.3s;
        color: #c7c9d0;
      }
    }
    & > .calendar {
      background-color: #26272c;
      & > .calendar-wrap {
        & > .date {
          background-color: #26272c;
          &:active {
            transition: all 0.1s;
            transform: scale(0.9);
            background-color: #408cff40;
            border-radius: 4px;
          }
          & > .date-text {
            color: #ffffff;
          }
          &.today-date {
            & > .date-text {
              color: #408cff;
            }
          }
        }
      }
    }
  }
}
.calendar-container {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  flex-grow: 1;
  gap: 16px;
  & > .topbar {
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    & > .title {
      transition: all 0.3s;
      color: #2c3e50;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.calendar-left {
  animation: open-left 0.4s forwards;
}
.calendar-right {
  animation: open-right 0.4s forwards;
}
.calendar {
  transition: all 0.3s;
  height: 85vh;
  z-index: 10;

  background-color: #ffffff;
  padding: 0 2px;
  gap: 2px;

  user-select: none;
  border-radius: 16px;
  overflow: hidden;
  flex-grow: 1;
  & > .calendar-wrap {
    transition: all 0.3s;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    height: 100%;
    &.no {
      transition: all 0.3s;
      height: 75vh;
    }
    & > .date {
      animation: open-date 0.3s forwards;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      background-color: #ffffff;
      border-radius: 0px;
      overflow: hidden;

      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        background-color: #408cff40;
        border-radius: 4px;
      }
      & > .date-text {
        transition: all 0.3s;

        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #408cff00;
        align-self: center;
        margin-top: 4px;
        flex-shrink: 0;

        color: #333333;
        font-size: 12px;
        font-weight: 300;
        text-align: center;
        line-height: 18px;
      }

      & > .event {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 2px;
        & > .event-item {
          transition: all 0.3s;
          transform: scaleY(1);
          transform-origin: top;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: stretch;
          background-color: #e2e8f0;
          border-radius: 5px;
          padding: 2px 2px 2px 4px;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & > .content {
            font-size: 12px;
            letter-spacing: -1px;
            font-weight: 500;
            text-align: left;
            color: #f2faff;

            overflow: hidden;
          }
        }
        & > .hide-event-item {
          transition: all 0.3s;
          transform: scaleY(0);
          opacity: 0;
          height: 0;
        }
      }

      &.today-date {
        transition: all 0.3s;

        & > .date-text {
          transition: all 0.3s;
          color: #408cff;
          font-weight: bold;
          background-color: #408cff40;
          border-radius: 50%;
        }
      }

      &.clicked-date {
        transition: all 0.3s;
        background-color: #408cff40;
        color: #408cff;

        & > .date-text {
          transition: all 0.3s;
          background-color: #408cff;
          color: #ffffff;
        }
      }

      &.not-now-month {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
.dragging {
  transition: none;
  pointer-events: none;
  & > .calendar-wrap {
    transition: none;
    & > .date {
      transition: none;
      &:active {
        transition: none;
        transform: scale(1);
        background-color: #f0f2f5;
      }
      &.clicked-date {
        transition: none;
        &:active {
          transition: none;
          background-color: #408cff40;
          transform: scale(1);
        }
      }
    }
  }
}
@keyframes open-right {
  from {
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes open-left {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes open-date {
  from {
    transform: scaleY(0) rotateX(-90deg);
  }
  to {
    transform: scaleY(1) rotateX(0deg);
  }
}
</style>
