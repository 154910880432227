<template>
  <div class="timetable">
    <BlackScreen />
    <TopBar
      title="시간표"
      buttonContent="과목"
    />
    <Loading v-if="isLoading" />
    <Empty
      v-else
      title="과목을 생성해보세요."
      :isExist="subjectArray.length == 0"
    />
    <SubjectWrapper
      v-if="todaySubjectArray.length > 0 && !isLoading"
      title="오늘의 과목"
      :subjectArray="todaySubjectArray"
    />
    <SubjectWrapper
      v-if="notTodaySubjectArray.length > 0 && !isLoading"
      title="전체 과목"
      :subjectArray="notTodaySubjectArray"
    />
    <AddSubject @subjectNotification="subjectNotification" />
    <Card @subjectNotification="subjectNotification" />
    <AddSubjectInfo @subjectInfoNotification="subjectInfoNotification" />
    <Notification
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </div>
</template>

<script>
import BlackScreen from "@/components/BlackScreen.vue";
import Loading from "@/components/Loading.vue";
import Empty from "@/components/Empty.vue";
import TopBar from "@/components/Bar/TopBar";
import SubjectWrapper from "@/components/Timetable/SubjectWrapper.vue";
import AddSubject from "@/components/Timetable/AddSubject.vue";
import AddSubjectInfo from "@/components/Timetable/AddSubjectInfo.vue";
import Card from "@/components/Timetable/Card.vue";
import Notification from "@/components/Alerts/Notification.vue";
import { storageMixin } from '@/mixins'
import EventBus from '@/utils/EventBus'
import axios from "axios";

export default {
  name: "Timetable",
  components: {
    BlackScreen,
    Loading,
    Empty,
    TopBar,
    SubjectWrapper,
    AddSubject,
    AddSubjectInfo,
    Card,
    Notification,
  },
  computed: {
    subjectArray: {
      get() {
        return this.$store.state.subjectArray;
      },
      set(value) {
        this.$store.commit('setEditSubjectArray', value);
      }
    }
  },
  async mounted() {
    if (this.subjectArray.length == 0) {
      await this.loadFromServer();
    } else {
      this.settingSubjectArray();
    }
  },
  mixins: [storageMixin],
  methods: {
    async subjectNotification(mode) {
      await this.loadFromServer();
      this.showNotification('과목이 ' + mode + '되었습니다.');
    },
    async subjectInfoNotification(info, mode) {
      await this.loadFromServer();
      if (info == 'exam') {
        this.showNotification('시험이 ' + mode + '되었습니다.');
      } else {
        this.showNotification('과제가 ' + mode + '되었습니다.');
      }
      EventBus.$emit('updateSubjectInfo');
    },
    settingSubjectArray() {
      this.todaySubjectArray = [];
      this.notTodaySubjectArray = [];
      const today = new Date().getDay();
      const dayMapping = {
        0: "일요일",
        1: "월요일",
        2: "화요일",
        3: "수요일",
        4: "목요일",
        5: "금요일",
        6: "토요일",
      };
      this.todaySubjectArray = this.subjectArray.filter(
        (subject) => subject.subjectDay.includes(dayMapping[today])
      );
      this.notTodaySubjectArray = this.subjectArray.filter(
        (subject) => !subject.subjectDay.includes(dayMapping[today])
      );
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
    async loadFromServer() {
      if(this.subjectArray.length == 0) {
        this.isLoading = true;
      }
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/timetable";
        const token = localStorage.getItem("token")
        const header = { headers: { "Authorization": token } };
        const response = await axios.get(url, header);
        this.subjectArray = response.data;
        this.settingSubjectArray();
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        this.$router.push("/signin");
      }
      this.$store.commit('setEditSubjectArray', this.subjectArray);
    }
  },
  data() {
    return {
      todaySubjectArray: [],
      notTodaySubjectArray: [],
      appearNotification: false,
      notificationCount: 0,
      notificationContent: '',
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .timetable {
    //background-color: #14171b;
    background-color: #26272c;
  }
}
.timetable {
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 80px;
}
</style>
