<template>
  <div
    :class="{ todo: true, 'done-todo': isClickedDoneButton }"
    @mouseover="isAppearDoneButton = true"
    @mouseout="isAppearDoneButton = false"
  >
    <div :class="{ wrap: true, 'black-effect': isAppearDoneButton }" @click="emitMoreTodo">
      <div class="line" :style="{backgroundColor: categoryColor }"/>
      <div class="content">{{ content }}</div>
      <div
        class="dday"
        :class= "{
          appear: !isAppearDoneButton,
          deadline: Number(diffDday) <= 3,
          expired: stringDday == '기한만료'
        }"
      >
        {{ stringDday }}
      </div>
      <div
        class="todo-checkbox-wrap" :class="{ appear: isAppearDoneButton }">
        <div
          class="todo-checkbox"
          :style="{ border: '1px solid' + categoryColor, backgroundColor: isChecked ? categoryColor : '' }"
          @click.stop="doneTodo"
        >
          <img src="@/assets/new/white-check-icon.svg" :style="{ opacity: isChecked ? 1 : 0, scale: isChecked ? 0.8 : 0}">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { dateDiffMixin } from '@/mixins'

export default {
  name: "Todo",
  props: {
    id: {
      type: String,
      default: ""
    },
    categoryColor: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    deadline: {
      type: String,
      default: ""
    }
  },
  mixins: [dateDiffMixin],
  computed: {
    stringDday() {
      if(!this.diffDday) return;
      else if(Number(this.diffDday) == 0) return 'D-day';
      else if(Number(this.diffDday) < 0) return '기한만료';
      else return 'D-' + this.diffDday;
    },
  },
  created() {
    this.diffDday = this.getDateDiff(this.deadline);
  },
  watch: {
    deadline() {
      this.diffDday = this.getDateDiff(this.deadline);
    }
  },
  methods: {
    async doneTodo() {
      this.isChecked = !this.isChecked;
      setTimeout(async() => {
        try {
          if (localStorage.getItem("token")) {
            const url = "https://www.iflab.run/api/todo/done";
            const token = localStorage.getItem("token");
            const header = { headers: { "Authorization": token } };
            await axios.patch(url, {
              todo_id: this.id
            }, header);
            this.isClickedDoneButton = true;
          }
        } catch (error) {
          console.log(error);
        }
      }, 100);
      this.$emit("clickDoneTodo");
    },
    emitMoreTodo() {
      if (this.isClickedDoneButton) return;
      this.$store.commit("setEditTodoId", this.id);
      this.$store.commit("setEditTodoContent", this.content);
      this.$store.commit("setEditTodoDeadline", this.deadline);
      this.isAppearDoneButton = false;
      this.$emit("clickMoreTodo");
    }
  },
  data() {
    return {
      isAppearDoneButton: false,
      isClickedDoneButton: false,
      diffDday: '',
      isChecked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .todo {
    & > .wrap {
      background-color: #00000060;
      & > .content {
        color: #d6d9e2;
      }
      & > .dday {
        color: #919294;
      }
      & > .deadline {
        color: #ff6b6b;
      }
      & > .expired {
        color: #50515e;
      }
      &:hover {
        background-color: #000000a0;
      }
      &:active {
        background-color: #000000a0;
      }
    }
  }
}

.todo {
  animation: fadeIn 1s forwards;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
  & > .wrap {
    transition: all 0.2s;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 12px;
    background-color: #ffffff60;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
    flex-grow: 1;
    &:hover {
      transition: all 0.1s;
      background-color: #ffffffa0;
    }
    &:active {
      transition: all 0.1s;
      background-color: #ffffffa0;
      transform: scale(0.98);
    }
    & > .line {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: #04a3fb;
    }
    & > .content {
      color: #3e3e3e;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1.5px;
      text-align: left;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > .todo-checkbox-wrap {
      transition: all 0.2s;
      opacity: 0;
      position: absolute;
      right: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 0px 6px 12px;
      -webkit-tap-highlight-color: transparent;
      & > .todo-checkbox {
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        & > img {
          transition: all 0.2s;
        }
      }
    }
    & > .dday {
      transition: all 0.2s;
      color: #a0a0a0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.2px;
      flex-shrink: 0;
      opacity: 0;
    }
    & > .deadline {
      color: #ff6b6b;
      font-weight: bold;
    }
    & > .expired {
      color: #cecece;
    }
    & > .appear {
      transition: all 0.2s;
      opacity: 1;
    }
  }
  & > .black-effect {
    transition: all 0.2s;
    background-color: #ffffffa0;
    &:hover {
      background-color: #ffffffd0;
    }
  }
}


.button {
  -webkit-tap-highlight-color: transparent;

  position: relative;
  transition: all 0.2s;
  transition-timing-function: cubic-bezier(0.21, 1.28, 0.29, 1.28);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 22px;
  border-radius: 4px;

  user-select: none;
  overflow: hidden;
  margin-left: 4px;
  & > img {
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
  }
  &:hover {
    transition: all 0.1s;
    filter: brightness(0.9);
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.95);
    filter: brightness(0.7);
  }
}
.check {
  background-color: #42d40e;
}
.more {
  background-color: #808080;
}

.disappear-button {
  transition: all 0.2s;
  padding: 22px 0;
  opacity: 0;
  margin-left: 0px;
  & > img {
    width: 0;
  }
}

.done-todo {
  transition: all 0.5s;
  opacity: 0;
  margin-bottom: 0;
  & > .wrap {
    transition: all 0.5s;
    padding: 0px 16px 0px 12px;
    overflow: hidden;
    
    & > .content {
      transition: all 0.5s;
      font-size: 0;
      zoom: 0.1;
    }
    & > .dday {
      transition: all 0.5s;
      font-size: 0;
      zoom: 0.1;
    }
  }
  & > .button {
    position: relative;
    transition: all 0.5s;
    padding: 0px 22px;
    overflow: hidden;
    & > img {
      height: 0px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.right-wrap {
  position: relative;
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(12px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
