import Todolist from '../views/Todolist'
  
const TodolistRoutes = [
  {
    path: '/',
    name: 'Todolist',
    component: Todolist
  },
]

export default TodolistRoutes;
