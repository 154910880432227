<template>
  <div :class="{ todo: true, /*'done-todo': isClickedDoneButton, */'postit-todo': theme == 'postit'}">
    <div
      :class="{ wrap: true, 'black-effect': isAppearButtons }"
      @click="appearButtons"
    >
      <div class="line" :style="{backgroundColor: categoryColor }"/>
      <div class="content">{{ content }}</div>
      <div
        :class= "{
          dday: true,
          deadline: Number(diffDday) <= 3,
          expired: stringDday == '기한만료'
        }"
      >
        {{ stringDday }}
      </div>
    </div>
    <div
      :class="{ button: true, check: true, 'disappear-button': !isAppearButtons }"
      @click="doneTodo"
    >
      <img src="@/assets/new/white-check-icon.svg">
    </div>
    <div
      :class="{ button: true, more: true, 'disappear-button': !isAppearButtons }"
      @click="moreTodo"
    >
      <img src="@/assets/new/white-more-icon.svg">
    </div>
  </div>
</template>

<script>
import { dateDiffMixin } from '@/mixins'
import axios from "axios";

export default {
  name: "Todo",
  props: {
    id: {
      type: String,
      default: ""
    },
    categoryColor: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    deadline: {
      type: String,
      default: ""
    },
  },
  mixins: [dateDiffMixin],
  created() {
    this.diffDday = this.getDateDiff(this.deadline);
  },
  watch: {
    deadline() {
      this.diffDday = this.getDateDiff(this.deadline);
    }
  },
  computed: {
    stringDday() {
      if(!this.diffDday) {
        return;
      } else if(Number(this.diffDday) == 0) {
        return 'D-day';
      } else if(Number(this.diffDday) < 0) {
        return '기한만료';
      } else {
        return 'D-' + this.diffDday;
      }
    },
  },
  methods: {
    appearButtons() {
      this.isAppearButtons = !this.isAppearButtons;
    },
    async doneTodo() {
      //this.isClickedDoneButton = true;
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/todo/done";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        await axios.patch(url, {
          todo_id: this.id
        }, header);
      }
      this.$emit("clickDoneTodo");
    },
    moreTodo() {
      this.appearButtons();
      this.$store.commit("setEditTodoId", this.id);
      this.$store.commit("setEditTodoContent", this.content);
      this.$store.commit("setEditTodoDeadline", this.deadline);
      this.$emit("clickMoreTodo");
    },
  },
  data() {
    return {
      isAppearButtons: false,
      //isClickedDoneButton: false,
      diffDday: '',
      theme: this.$store.state.todolistTheme,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .todo {
    & > .wrap {
      background-color: #393b43;
      & > .content {
        color: #d6d9e2;
      }
      & > .dday {
        color: #919294;
      }
      & > .deadline {
        color: #ff6b6b;
      }
      & > .expired {
        color: #50515e;
      }
    }
  }
  & .postit-todo {
    & > .wrap {
      background-color: #00000060;
    }
  }
}

.todo {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  & > .wrap {
    transition: all 0.2s;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px 12px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
    flex-grow: 1;
    &:active {
      transition: all 0.1s;
      transform: scale(0.98);
    }
    & > .line {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: #04a3fb;
    }
    & > .content {
      width: 75vw;
      color: #3e3e3e;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: left;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 0;
    }
    & > .todo-checkbox-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 6px 6px 12px;
      -webkit-tap-highlight-color: transparent;
      & > .todo-checkbox {
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        & > img {
          transition: all 0.2s;
        }
      }
    }
    & > .dday {
      color: #a0a0a0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.2px;
      flex-shrink: 0;
    }
    & > .deadline {
      color: #ff6b6b;
      font-weight: bold;
    }
    & > .expired {
      color: #cecece;
    }
  }
  & > .black-effect {
    transition: all 0.2s;
    filter: brightness(0.9);
  }
}


.button {
  -webkit-tap-highlight-color: transparent;

  position: relative;
  transition: all 0.2s;
  transition-timing-function: cubic-bezier(0.21, 1.28, 0.29, 1.28);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 22px;
  border-radius: 4px;

  user-select: none;
  overflow: hidden;
  margin-left: 4px;
  & > img {
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.95);
    filter: brightness(0.9);
  }
}
.check {
  background-color: #42d40e;
}
.more {
  background-color: #808080;
}

.disappear-button {
  transition: all 0.2s;
  padding: 22px 0;
  opacity: 0;
  margin-left: 0px;
  & > img {
    width: 0;
  }
}

.done-todo {
  transition: all 0.5s;
  opacity: 0;
  margin-bottom: 0;
  & > .wrap {
    position: relative;
    transition: all 0.5s;
    padding: 0px 16px 0px 12px;
    overflow: hidden;
    
    & > .content {
      transition: all 0.5s;
      font-size: 0;
      zoom: 0.1;
    }
    & > .dday {
      transition: all 0.5s;
      font-size: 0;
      zoom: 0.1;
    }
  }
  & > .button {
    position: relative;
    transition: all 0.5s;
    padding: 0px 22px;
    overflow: hidden;
    & > img {
      height: 0px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.postit-todo {
  & > .wrap {
    background-color: #ffffff60;
  }
}
</style>
