import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    isDarkmode: localStorage.getItem('isDarkmode') === 'true',
    isSystem: localStorage.getItem('isSystem') === 'true',
    isSignIn: localStorage.getItem('isSignIn') === 'true',
    token: localStorage.getItem('token'),
    todolistTheme: localStorage.getItem('todolistTheme'),
    nickname: '',
    createdAt: '',

    allTodolist: [],
    todayTodolist: [],

    editCategory: false,
    editTodo: false,
    editSubject: false,
    editSubjectInfo: false,
    openCard: false,
    beforeCard: false,

    nowCategoryId: -1,
    nowCategoryName: '',
    nowCategoryColor: '',
    
    nowTodoId: -1,
    nowTodoContent: '',
    nowTodoDeadline: '',

    subjectArray: [],

    nowSubjectId: -1,
    nowSubjectName: '',
    nowSubjectDay: [],
    nowSubjectExam: [],
    nowSubjectAssignment: [],

    nowSubjectInfoId: -1,
    nowSubjectInfoType: '',
    nowSubjectInfoContent: '',
    nowSubjectInfoDeadline: '',
  },
  mutations: {
    setIsDarkmode(state, value) {
      state.isDarkmode = value;
      localStorage.setItem('isDarkmode', value)
    },
    setIsSystem(state, value) {
      state.isSystem = value;
      localStorage.setItem('isSystem', value)
    },
    setIsSignIn(state, value) {
      state.isSignIn = value;
      localStorage.setItem('isSignIn', value)
    },
    setToken(state, value) {
      state.token = value;
      localStorage.setItem('token', value)
    },
    setTodolistTheme(state, value) {
      state.todolistTheme = value;
      localStorage.setItem('todolistTheme', value)
    },
    setNickname(state, value) {
      state.nickname = value;
    },
    setCreatedAt(state, value) {
      state.createdAt = value;
    },
    setAllTodolist(state, value) {
      state.allTodolist = value;
    },
    setTodayTodolist(state, value) {
      state.todayTodolist = value;
    },
    setEditCategory(state, value) {
      state.editCategory = value;
    },
    setEditCategoryId(state, value) {
      state.nowCategoryId = value;
    },
    setEditCategoryName(state, value) {
      state.nowCategoryName = value;
    },
    setEditCategoryColor(state, value) {
      state.nowCategoryColor = value;
    },
    setEditTodo(state, value) {
      state.editTodo = value;
    },
    setEditTodoId(state, value) {
      state.nowTodoId = value;
    },
    setEditTodoContent(state, value) {
      state.nowTodoContent = value;
    },
    setEditTodoDeadline(state, value) {
      state.nowTodoDeadline = value;
    },
    setEditSubjectArray(state, value) {
      state.subjectArray = value;
    },
    setEditSubject(state, value) {
      state.editSubject = value;
    },
    setEditSubjectId(state, value) {
      state.nowSubjectId = value;
    },
    setEditSubjectName(state, value) {
      state.nowSubjectName = value;
    },
    setEditSubjectDay(state, value) {
      state.nowSubjectDay = value;
    },
    setEditSubjectExam(state, value) {
      state.nowSubjectExam = value;
    },
    setEditSubjectAssignment(state, value) {
      state.nowSubjectAssignment = value;
    },
    setEditSubjectInfo(state, value) {
      state.editSubjectInfo = value;
    },
    setEditSubjectInfoId(state, value) {
      state.nowSubjectInfoId = value;
    },
    setEditSubjectInfoType(state, value) {
      state.nowSubjectInfoType = value;
    },
    setEditSubjectInfoContent(state, value) {
      state.nowSubjectInfoContent = value;
    },
    setEditSubjectInfoDeadline(state, value) {
      state.nowSubjectInfoDeadline = value;
    },
    setEditOpenCard(state, value) {
      state.openCard = value;
    },
    setEditBeforeCard(state, value) {
      state.beforeCard = value;
    }
  },
  actions: {
    setIsDarkmode(context, value) {
      context.commit('setIsDarkmode', value);
    },
    setIsSystem(context, value) {
      context.commit('setIsSystem', value);
    },
    setIsSignIn(context, value) {
      context.commit('setIsSignIn', value);
    },
    setToken(context, value) {
      context.commit('setToken', value);
    },
    setTodolistTheme(context, value) {
      context.commit('setTodolistTheme', value);
    },
    setNickname(context, value) {
      context.commit('setNickname', value);
    },
    setCreatedAt(context, value) {
      context.commit('setCreatedAt', value);
    },
    setAllTodolist(context, value) {
      context.commit('setAllTodolist', value);
    },
    setTodayTodolist(context, value) {
      context.commit('setTodayTodolist', value);
    },
    setEditCategory(context, value) {
      context.commit('setEditCategory', value);
    },
    setEditCategoryId(context, value) {
      context.commit('setEditCategoryId', value);
    },
    setEditCategoryName(context, value) {
      context.commit('setEditCategoryName', value);
    },
    setEditCategoryColor(context, value) {
      context.commit('setEditCategoryColor', value);
    },
    setEditTodo(context, value) {
      context.commit('setEditTodo', value);
    },
    setEditTodoId(context, value) {
      context.commit('setEditTodoId', value);
    },
    setEditTodoContent(context, value) {
      context.commit('setEditTodoContent', value);
    },
    setEditTodoDeadline(context, value) {
      context.commit('setEditTodoDeadline', value);
    },
    setEditSubjectArray(context, value) {
      context.commit('setEditSubjectArray', value);
    },
    setEditSubject(context, value) {
      context.commit('setEditSubject', value);
    },
    setEditSubjectId(context, value) {
      context.commit('setEditSubjectId', value);
    },
    setEditSubjectName(context, value) {
      context.commit('setEditSubjectName', value);
    },
    setEditSubjectDay(context, value) {
      context.commit('setEditSubjectDay', value);
    },
    setEditSubjectExam(context, value) {
      context.commit('setEditSubjectExam', value);
    },
    setEditSubjectAssignment(context, value) {
      context.commit('setEditSubjectAssignment', value);
    },
    setEditSubjectInfo(context, value) {
      context.commit('setEditSubjectInfo', value);
    },
    setEditSubjectInfoId(context, value) {
      context.commit('setEditSubjectInfoId', value);
    },
    setEditSubjectInfoType(context, value) {
      context.commit('setEditSubjectInfoType', value);
    },
    setEditSubjectInfoContent(context, value) {
      context.commit('setEditSubjectInfoContent', value);
    },
    setEditSubjectInfoDeadline(context, value) {
      context.commit('setEditSubjectInfoDeadline', value);
    },
    setEditOpenCard(context, value) {
      context.commit('setEditOpenCard', value);
    },
    setEditBeforeCard(context, value) {
      context.commit('setEditBeforeCard', value);
    }
  },
  modules: {
  }
})
