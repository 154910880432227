import { axiosInstance } from '@/api/instance';

/**
 * 회원가입 합니다.
 */
const handleSignUp = async() => {
  return axiosInstance.request('POST', '/signup');
};

/**
 * 투두리스트의 테마를 불러옵니다.
 */
const getTodoTheme = async() => {
  return axiosInstance.get('/user/theme/todolist');
};


/**
 * 비밀번호를 변경합니다.
 */
const changePassword = async data => {
  return axiosInstance.request('POST', '/user/password', {
    password: data.password,
    new_password: data.new_password
  });
};

export default {
  handleSignUp,
  getTodoTheme,
  changePassword
};