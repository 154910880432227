<template>
  <SecondaryPage
    title="테마 변경"
    type="theme"
  >
    <LabelWraps>
      <LabelWrap label="테마 - 나만의 할 일">
        <div class="theme-wrap">
          <div
            :class="{ theme: true, 'selected-theme': theme == 'normal' }"
            @click="changeTheme('normal')"
          >
            <div class="theme-name">일반</div>
            <img v-if="isDarkmode" src="@/assets/new/dark-normal-theme.svg" />
            <img v-else src="@/assets/new/normal-theme.svg" />
            <div class="check">
              <img src="@/assets/new/check-icon.svg" />
            </div>
          </div>
          <div
            :class="{ theme: true, 'selected-theme': theme == 'postit' }"
            @click="changeTheme('postit')"
          >
            <div class="theme-name">포스트잇</div>
            <img v-if="isDarkmode" src="@/assets/new/dark-postit-theme.svg" />
            <img v-else src="@/assets/new/postit-theme.svg" />
            <div class="check">
              <img src="@/assets/new/check-icon.svg" />
            </div>
          </div>
        </div>
      </LabelWrap>
    </LabelWraps>
    <!--div class="wrap">
      <Label label="추가 기능 편집" />
      <div class="option-wrap">
        <div
          :class="{ option: true, 'selected-option': isCheckedTodolist }"
          @click="clickOption('todolist')"
        >
          <img src="@/assets/new/active-todolist-icon.svg" />
          오늘의 할 일
        </div>
      </div>
    </div-->
  </SecondaryPage>
</template>

<script>
import SecondaryPage from "@/components/SecondaryPage.vue";
import LabelWraps from "@/components/LabelWraps.vue";
import LabelWrap from "@/components/LabelWrap.vue";
import axios from "axios";
export default {
  name: 'ChangeTheme',
  components: {
    SecondaryPage,
    LabelWraps,
    LabelWrap
  },
  methods: {
    async changeTheme(theme) {
      if ( this.theme == theme ) return;
      this.theme = theme;
      const url = "https://www.iflab.run/api/user/theme/todolist";
      const token = localStorage.getItem("token");
      const header = { headers: { "Authorization": token } };
      await axios.patch(url, {
        todolist_theme: this.theme
      }, header);
      this.$store.commit('setTodolistTheme', theme);
      // API작성하기, 현재 Theme뭔지 저장하기
    },
    clickOption(option) {
      if ( option == 'todolist' ) {
        this.isCheckedTodolist = !this.isCheckedTodolist;
      }
    }
  },
  data() {
    return {
      theme: this.$store.state.todolistTheme,
      isDarkmode: this.$store.state.isDarkmode,
      isCheckedTodolist: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .theme-wrap {
    background-color: #333333;
  }
}

.theme-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  gap: 8px;
  & > .theme {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    & > .theme-name {
      transition: all 0.3s;
      color: #cfe2ff;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    & > img {
      transition: all 0.3s;
      border: 2px solid #408cff00;
      border-radius: 12px;
      &:active {
        transition: all 0.1s;
        transform: scale(0.95);
        filter: brightness(0.9);
      }
    }
    & > .check {
      position: absolute;
      right: 8px;
      bottom: 8px;

      transition: all 0.3s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
      border-radius: 4px;
      & > img {
        transition: all 0.2s;
        width: 16px;
        height: 16px;
        opacity: 0;
        transform: scale(0);
      }
    }
  }
  & > .selected-theme {
    & > .theme-name {
      transition: all 0.3s;
      color: #408cff;
    }
    & > img {
      transition: all 0.3s;
      border: 2px solid #408cffff;
      &:active {
        transition: all 0.1s;
        transform: scale(1);
        filter: brightness(1);
      }
    }
    & > .check {
      transition: all 0.3s;
      background-color: #408cff;
      & > img {
        transition: all 0.2s;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.option-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  gap: 8px;
  & > .option {
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    color: #408cff;
    font-size: 16px;
    font-weight: bold;
    user-select: none;
    padding: 8px;
    border: 1px solid #408cff;
    border-radius: 8px;
    background-color: #ffffff;

    transform: scale(1);
    filter: brightness(1);

    & > img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    &:active {
      transition: all 0.1s;
      transform: scale(0.95);
      filter: brightness(0.9);
    }
  }
  & > .selected-option {
    transition: all 0.3s;
    color: #ffffff;
    background-color: #408cff;
    & > img {
      filter: brightness(5);
    }
  }
}
</style>
