<template>
  <div :class="{ 'date-picker': true, 'appear-date-picker': isSelectDate}">
    <div class="date-wrap">
      <div
        :class="{date: true, 'selected-date': selectString == 'year'}"
        @click="setSelectDate('year')"
        @touchstart="startDrag"
        @touchmove="handleDrag"
        @touchend="endDrag"
      >
        <div
          class="date-text"
          :style="{
            transform: selectString == 'year' ? 'translateY(' + dragY + 'px)' : '',
            opacity: selectString == 'year' ? 1 - Math.abs(dragY) / 10 : '',
          }"
        >
          {{ nowYear }}
        </div>
        <div class="date-text">년</div>
      </div>
      <div
        :class="{date: true, 'selected-date': selectString == 'month'}"
        @click="setSelectDate('month')"
        @touchstart="startDrag"
        @touchmove="handleDrag"
        @touchend="endDrag"
      >
        <div
          class="date-text"
          :style="{
            transform: selectString == 'month' ? 'translateY(' + dragY + 'px)' : '',
            opacity: selectString == 'month' ? 1 - Math.abs(dragY) / 10 : '',
          }"
        >
          {{ nowMonth }}
        </div>
        <div class="date-text">월</div>
      </div>
    </div>
    <div class="button-wrap">
      <div class="button cancel" @click="closeSelectDate">취소</div>
      <div class="button" @click="emitSelectDate">확인</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    isSelectDate: {
      type: Boolean,
      default: false,
    },
    nowYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
    nowMonth: {
      type: Number,
      default: new Date().getMonth() + 1,
    },
  },
  data() {
    return {
      dragging: false,
      selectString: "",
      startY: 0,
      dragY: 0,
      changeMonth: 0,
    };
  },
  methods: {

    setSelectDate(selectString) {
      this.selectString = selectString;
    },
    closeSelectDate() {
      this.selectString = "";
      this.$emit("close-date-picker");
    },
    startDrag(e) {
      if(this.selectString == "") return;
      this.dragging = true;
      this.startY = e.touches[0].clientY;
    },
    handleDrag(e) {
      if (!this.dragging) return;
      const currentY = e.touches[0].clientY;
      const distanceY = currentY - this.startY;

      this.dragY = distanceY / 10;
      if (distanceY >= 40) { // 아래쪽으로 드래그
        this.changeMonth = -1;
      } else if(distanceY <= -40) { // 위쪽으로 드래그
        this.changeMonth = 1;
      } else {
        this.changeMonth = 0;
      }
    },
    endDrag() {
      this.dragging = false;
      this.dragY = 0;

      if (this.changeMonth == -1) {
        if (this.selectString == "year") {
          this.nowYear = this.nowYear + 1;
        } else if (this.selectString == "month") {
          if(this.nowMonth == 12) {
            this.nowMonth = 1;
          } else {
            this.nowMonth = this.nowMonth + 1;
          }
        }
      } else if (this.changeMonth == 1) {
        if (this.selectString == "year") {
          this.nowYear = this.nowYear - 1;
        } else if (this.selectString == "month") {
          if(this.nowMonth == 1) {
            this.nowMonth = 12;
          } else {
            this.nowMonth = this.nowMonth - 1;
          }
        }
      }
      setTimeout(() => {
        this.changeMonth = 0;
      }, 500);
    },
    emitSelectDate() {
      this.$emit("select-date", this.nowYear, this.nowMonth);
      this.closeSelectDate();
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .date-picker {
    background-color: #1c1c1c;
    & .date-wrap {
      & .date {
        & .date-text {
          color: #c7c9d080;
        }
        &::before {
          background-color: #383f4a;
        }
      }
      & .selected-date {
        & .date-text {
          color: #c7c9d0;
        }
      }
    }
    & .button-wrap {
      & .button {
        color: #ffffff;
      }
      & .cancel {
        background-color: #383f4a;
        color: #8894ab;
      }
    }
  }
}

.date-picker {
  transition: all 0.3s;
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(100%);

  border-radius: 16px 16px 0 0;
  background-color: #ffffff;
  padding: 40px 16px;
  gap: 48px;
  & > .date-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    & > .date {
      position: relative;
      display: flex;
      flex-direction: row;
      & > .date-text {
        color: #1c1c1c80;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -2px;
      }

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        content: "";

        transform: translate(-50%, -50%);
        width: calc(100% + 8px);
        height: 2px;
        background-color: #f0f1f5;
        border-radius: 4px;
      }
      &::after {
        transition: all 0.3s;
        position: absolute;
        width: 0%;
        height: 2px;
        content: "";
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 4px;
        background-color: #408cff;
      }
    }
    & > .selected-date {
      & > .date-text {
        color: #1c1c1c;
      }
      &::after {
        width: calc(100% + 8px);
      }
    }
  }
  & > .button-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    & > .button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;

      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -1px;

      background-color: #408cff;
      border-radius: 12px;
      padding: 16px 0;
      flex-grow: 1;
      flex-basis: 0;
      &:active {
        transition: all 0.1s;
        transform: scale(0.95);
        filter: brightness(0.7);
      }
    }
    & > .cancel {
      background-color: #f0f1f5;
      color: #5d646f;
    }
  }
}

.appear-date-picker {
  transform: translateY(0);
  opacity: 1;
}

</style>
