<template>
  <div :class="{ kakao: true, 'appear-kakao': isShowKakao }">
    <div class="topbar">
      <a href="http://pf.kakao.com/_qxbZQxj" target="blank">채널 이동</a>
      <img src="@/assets/new/close-icon.svg" @click="closeKakao" />
    </div>
    <iframe src="https://pf.kakao.com/_qxbZQxj" />
  </div>
</template>

<script>
export default {
  name: "Kakao",
  props: {
    isShowKakao: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeKakao() {
      this.$emit("closeKakao");
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .kakao {
    background-color: #1c1c1c;
    & > .topbar {
      border: 1px solid #1c1c1c;
      & > a {
        color: #1c1c1c;
      }
    }
    & > iframe {
      filter: opacity(0.75);
    }
  }
}

.kakao {
  transition: all 0.3s;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  z-index: 500;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  & > .topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    border: 1px solid #f0f0f0;
    & > a {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      background-color: #408cff;
      padding: 4px 8px;
      border-radius: 6px;
      text-decoration: none;
    }
    & > img {
      width: 24px;
      height: 24px;
    }
  }
  & > iframe {
    border: none;
    height: 100%;
  }
}

.appear-kakao {
  opacity: 1;
  transition: all 0.3s;
  transform: translateY(0px);
  pointer-events: auto;
}
</style>
