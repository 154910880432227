<template>
  <div class="sign-in">
    <SignUp
      :isSignUp="isSignUp"
      @closeSignUp="closeSignUp"
      @completeSignUp="completeSignUp"
    />
    <BlackScreen
      :isShow="isSignUp || showGuide || isShowKakao"
      @close="closeGuide"
    />
    <!--img class="close-button" src="@/assets/new/close-icon.svg" @click="goSetting()" /-->
    <div :class="{ 'logo-wrap': true, 'appear-logo': true, 'disappear-logo': isClickedGoButton }">
      <img class="logo" src="@/assets/web/Logo.svg" />
      <div class="logo-text">더 나은 하루를 보낼 수 있게</div>
    </div>
    <LabelWrap label="아이디">
      <Input
        type="text"
        placeholder="아이디"
        v-model="inputUsername"
        @focusInput="isDisabled = false; isNotSignIn = false;"
        @pressEnter="SignIn"
      />
    </LabelWrap>
    <LabelWrap label="비밀번호">
      <Input
        type="password"
        placeholder="비밀번호"
        v-model="inputPassword"
        @focusInput="isDisabled = false; isNotSignIn = false;"
        @pressEnter="SignIn"
      />
    </LabelWrap>
    <div class=text-wrap>
      <span @click="goSignUp" :class="{ 'appear-text': !inputUsername && !inputPassword }">
        <u> 아직 회원이 아니신가요? </u>
      </span>
      <span @click="openKakao" :class="{ 'appear-text': (inputUsername || inputPassword) && !isNotSignIn }">
        <u> 아이디 또는 비밀번호를 잊으셨나요? </u>
      </span>
      <span :class="{error: true, 'appear-text': isNotSignIn, 'appear-error': isNotSignIn}">
        아이디 또는 비밀번호가 일치하지 않습니다.
      </span>
    </div>
    <IOSGuide v-if="showGuide" />
    <SubmitButton
      title="로그인"
      :isDisabled="!inputUsername || !inputPassword || isDisabled"
      @clickButton="SignIn"
    />
    <Notification
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
    <Kakao
      :isShowKakao="isShowKakao"
      @closeKakao="closeKakao"
    />
  </div>
</template>

<script>
import LabelWrap from "@/components/LabelWrap.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import Notification from "@/components/Alerts/Notification.vue";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import Input from "@/components/Forms/Input.vue";
import SignUp from "@/views/SignUp.vue";
import IOSGuide from "@/components/IOSGuide.vue";
import Kakao from "@/components/Kakao.vue";
import axios from "axios";
export default {
  name: "SignIn",
  components: {
    LabelWrap,
    BlackScreen,
    Notification,
    SubmitButton,
    Input,
    SignUp,
    IOSGuide,
    Kakao
  },
  mounted() {
    if ((("standalone" in window.navigator) && window.navigator.standalone)
      || window.matchMedia('(display-mode: standalone)').matches
      || window.navigator.userAgent.includes('apple-mobile-web-app-capable')) {
      // PWA - ios 접속
    } else {
      const userAgent = navigator.userAgent; 
      if (userAgent.indexOf('Safari/') > -1 && userAgent.indexOf('Chrome/') === -1 && userAgent.indexOf('CriOS/') === -1) {
        if(!sessionStorage.getItem('showGuide')) {
          setTimeout(() => {
            this.showGuide = true;
          }, 1000);
        } else {
          return
        }
      }
    }
    window.addEventListener('resize', this.checkWindowSize)
    this.checkWindowSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    checkWindowSize() {
      if (window.innerWidth >= 800) {
        this.$router.push("/web/signin");
      }
    },
    async SignIn() {
      if(!this.inputUsername || !this.inputPassword) return;
      const url = "https://www.iflab.run/api/signin";
      await axios.post(url, {
        "username": this.inputUsername,
        "password": this.inputPassword,
      },
      {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      }}).then(response => {
        if (response.status == 200) {
          this.$store.commit('setToken', response.data.access_token);
          this.$store.commit('setIsSignIn', true);
        } else if (response.status == 400) {
          this.isNotSignIn = true;
          this.isDisabled = true;
          setTimeout(() => {
            if(this.isNotSignIn) this.isNotSignIn = false;
          }, 2000);
        }
        //uthis.$store.commit("setNickname", this.inputUsername);
        //this.$store.commit("setPassword", this.inputPassword);
        //this.$store.commit("setIsLogin", true);
        this.goMain();
      }).catch(error => {
        if(error.response.status == 400) {
          this.isNotSignIn = true;
          this.isDisabled = true;
          setTimeout(() => {
            if(this.isNotSignIn) this.isNotSignIn = false;
          }, 2000);
        }
      })
    },
    goMain() {
      this.isClickedGoButton = true;
      setTimeout(() => {
        this.$router.push("/");
        this.isClickedGoButton = false;
      }, 500);
    },
    goSetting() {
      this.isClickedGoButton = true;
      setTimeout(() => {
        this.$router.push("/setting");
        this.isClickedGoButton = false;
      }, 500);
    },
    goSignUp() {
      this.isSignUp = true;
    },
    openKakao() {
      this.isShowKakao = true;
    },
    closeKakao() {
      this.isShowKakao = false;
    },
    closeGuide() {
      this.showGuide = false;
      sessionStorage.setItem('showGuide', true);
    },
    closeSignUp() {
      this.isSignUp = false;
    },
    completeSignUp() {
      this.isSignUp = false;
      this.showNotification("성공적으로 회원가입이 완료되었습니다!");
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
  },
  data() {
    return {
      showGuide: false,
      isSignUp: false,

      inputUsername: "",
      inputPassword: "",
      isClickedGoButton: false,
      isNotSignIn: false,
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",

      isShowKakao: false,
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .sign-in {
    background: linear-gradient(to bottom, #323440, #26272c 20%);
    & > .text-wrap {
      & > span {
        color: #606060;
      }
      & > .error {
        color: #ff5151;
      }
    }
  }
}

.sign-in {
  z-index: 300;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(to bottom, #ffffff, #f0f1f5 20%);
  min-height: 100vh;
  padding-bottom: 100px;
  & > .close-button {
    position: fixed;
    top: 16px;
    left: 16px;
    width: 24px;
    height: 24px;
    z-index: 100;
  }
  & > .logo-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    flex-grow: 1;
    & > .logo {
      width: 200px;
      height: 50px;
    }
    & > .logo-text {
      font-family: "Noto Sans KR", sans-serif;
      color: #408cff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -2px;
    }
    &.appear-logo {
      animation: appear 1s forwards;
    }
    &.disappear-logo {
      animation: disappear 0.5s forwards;
    }
  }
  & > .text-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 8px 16px 16px 16px;
    user-select: none;
    & > span {
      position: absolute;
      transition: all 0.5s;
      transform: translateY(4px);
      opacity: 0;
      color: #a0a0a0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.5px;
      pointer-events: none;
    }
    & > .appear-text {
      transition: all 0.5s;
      transform: translateY(0px);
      opacity: 1;
      pointer-events: all;
    }
    & > .error {
      color: #ff5151;
    }
    & > .appear-error {
      animation: wrongAnimation 0.4s forwards;
    }
  }
}

@keyframes appear {
  0% {
    transform: scale(1.5) translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(1.5) translateY(-40px);
    opacity: 0;
  }
}

@keyframes wrongAnimation {
  0% {
    transform: translate(0px, 4px);
  }
  20% {
    transform: translate(-40px, 4px);
  }
  40% {
    transform: translate(30px, 4px);
  }
  60% {
    transform: translate(-20px, 4px);
  }
  80% {
    transform: translate(10px, 4px);
  }
  100% {
    transform: translate(0px, 4px);
  }
}
</style>
