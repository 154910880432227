<template>
  <div class="character-counter">
    {{ length }}/{{ limit }}
  </div>
</template>

<script>
export default {
  name: "CharacterCounter",
  props: {
    length: {
      type: Number,
      default: 0
    },
    limit: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.character-counter {
  transition: all 0.2s;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  color: #a0a0a0;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
</style>
