<template>
  <div class="checkbox">
    <div :class="{ check: true, checked: isChecked }">
      <div class="action-box" @click="check" />
      <img src="@/assets/new/check-icon.svg" />
    </div>
    <div class="content">
      <u @click="contentClick">{{ underlineContent }}</u>{{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    underlineContent: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    check() {
      this.$emit('check');
    },
    contentClick() {
      this.$emit('contentClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .checkbox {
    & > .check {
      background-color: #333333;
      border-color: #333333;
    }
    & > .checked {
      background-color: #408cff;
      border-color: #3a7de5;
    }
    & > .content {
      color: #606060;
    }
  }
}

.checkbox {
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 16px 8px 16px;
  & > .check {
    position: relative;
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #cecece;
    margin-right: 4px;
    & > .action-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 12px;
    }
    & > img {
      transition: all 0.2s;
      width: 16px;
      height: 16px;
      user-select: none;
      pointer-events: none;
      opacity: 0;
      transform: scale(0);
    }
  }
  & > .checked {
    transition: all 0.1s;
    background-color: #408cff;
    border: 1px solid #3a7de5;
    & > img {
      transition: all 0.2s;
      opacity: 1;
      transform: scale(1);
    }
  }
  & > .content {
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: left;
    word-break: keep-all;
    & > u {
      cursor: pointer;
    }
  }
}
</style>
