<template>
  <div class="category" :style="{ backgroundColor: categoryColor + '40', animationDelay: delay + 's'}">
    <div class="header">
      <div class="category-name" :style="{ backgroundColor: categoryColor }" @click="editCategory">
        {{ categoryName }}
      </div>
      <div class="line" :style="{backgroundColor: categoryColor }"/>
      <div class="add-todo-button" :style="{ backgroundColor: categoryColor }" @click="addTodo">
        <img src="@/assets/new/white-plus-icon.svg">
      </div>
    </div>
    <Todo
      v-for="data in todos"
      :key="data.id"
      :id=data.id
      :categoryColor=categoryColor
      :content=data.content
      :deadline=data.deadline
      @clickDoneTodo="doneTodo"
      @clickMoreTodo="moreTodo"
    />
  </div>
</template>

<script>
import Todo from "@/components/web/Todo.vue";
import EventBus from '@/utils/EventBus.js';

export default {
  name: "Category",
  props: {
    delay: {
      type: Number,
      default: 0
    },
    categoryId: {
      type: String,
      default: ""
    },
    categoryName: {
      type: String,
      default: ""
    },
    categoryColor: {
      type: String,
      default: ""
    },
    todos: {
      type: Array,
    },
  },
  components: {
    Todo,
  },
  created() {
    this.sortingTodo();
    EventBus.$on('updateTodo', () => {
      this.sortingTodo();
    });
  },
  methods: {
    editCategory() {
      this.$store.commit("setEditCategory", true);
      this.$store.commit("setEditCategoryId", this.categoryId);
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryColor", this.categoryColor);
    },
    addTodo() {
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryColor", this.categoryColor);
      this.$store.commit("setEditTodo", true);
    },
    doneTodo() {
      this.$emit('todoNotification', '완료');
    },
    moreTodo() {
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryColor", this.categoryColor);
      this.$store.commit("setEditTodo", true);
    },
    sortingTodo() {
      if(this.todos) {
        this.todos.sort((a, b) => {
          if (!b.deadline) {
            return -1;
          }
          if (a.deadline < b.deadline) {
            return -1;
          }
          if (a.deadline > b.deadline) {
            return 1;
          }
          return 0;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .category {
    & > .header {
      & > .category-name {
        color: #26272c;
        &:hover {
          transition: all 0.1s;
          filter: brightness(1.1);
        }
        &:active {
          transition: all 0.1s;
          filter: brightness(1.2);
        }
      }
      & > .add-todo-button {
        & > img {
          opacity: 0.5;
        }
        &:hover {
          transition: all 0.1s;
          filter: brightness(1.1);
        }
        &:active {
          transition: all 0.1s;
          filter: brightness(1.2);
        }
      }
    }
  }
}

.category {
  animation: fadeIn 1s forwards;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px #60606020;
  opacity: 0;
  & > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    & > .category-name {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -1px;
      padding: 2px 8px;

      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      &:hover {
        transition: all 0.1s;
        filter: brightness(0.9);
      }
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
    }
    & > .line {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
    }
    & > .add-todo-button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 4px;

      color: #ffffff;
      font-size: 8px;
      font-weight: bold;
      cursor: pointer;
      & > img {
        width: 8px;
        height: 8px;
      }
      &:hover {
        transition: all 0.1s;
        filter: brightness(0.9);
      }
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
    }
  }
}

@keyframes fadeIn {
  from {
    transform: scale(1) translateY(40px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
</style>
