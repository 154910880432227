<template>
  <div class="weekday-wrap">
    <div
      v-for="weekday in ['일', '월', '화', '수', '목', '금', '토']"
      :key="weekday"
      class="weekday"
    > {{ weekday }} </div>
  </div>
</template>

<script>
export default {
  name: "CalendarWeekDay",
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .weekday-wrap {
    & > .weekday {
      background-color: #1c1c1c;
      color: #c7c9d0;
    }
  }
}
.weekday-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  & > .weekday {
    flex-grow: 1;
    background-color: #ffffff;
    color: #a0aec0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 4px;
  }
}
</style>
