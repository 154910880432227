<template>
  <div class="history">
    <TopBar
      title="히스토리"
    />
  </div>
</template>

<script>
import TopBar from "@/components/Bar/TopBar";
export default {
  name: "History",
  components: {
    TopBar, 
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .history {
    //background-color: #14171b;
    background-color: #26272c;
  }
}

.history {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  min-height: 100vh;
  padding: 80px 0 40px;
}
</style>
