<template>
  <div :class="{ 'sign-up': true, 'appear-sign-up': isSignUp }">
    <ServiceAgreement
      :isShowServiceAgreement="isShowServiceAgreement"
      @closeServiceAgreement="closeAgreement('service')"
    />
    <PrivacyAgreement
      :isShowPrivacyAgreement="isShowPrivacyAgreement"
      @closePrivacyAgreement="closeAgreement('privacy')"
    />
    <BlackScreen
      :isShow="isShowServiceAgreement || isShowPrivacyAgreement"
    />
    <TopBar
      title="회원가입"
      @clickButton="closeSignUp"
    />
    <LabelWraps>
      <LabelWrap label="아이디">
        <Input
          type="text"
          placeholder="4~8자의 영소문자, 숫자"
          v-model="inputUsername"
          @focusInput="isDisabled = false;"
        />
      </LabelWrap>
      <LabelWrap label="비밀번호">
        <Input
          type="password"
          placeholder="8~16자의 영문자, 숫자, 특수문자"
          v-model="inputPassword"
          @focusInput="isDisabled = false;"
        />
      </LabelWrap>
      <LabelWrap label="비밀번호 확인">
        <Input
          type="password"
          placeholder="8~16자의 영문자, 숫자, 특수문자"
          v-model="inputPasswordCheck"
          @focusInput="isDisabled = false;"
        />
      </LabelWrap>
      <LabelWrap label="닉네임">
        <Input
          type="text"
          placeholder="2~8자의 한글, 영문자, 숫자"
          v-model="inputNickname"
          @focusInput="isDisabled = false;"
        />
      </LabelWrap>
      <LabelWrap label="이메일">
        <Input
          type="email"
          placeholder="email@example.com"
          v-model="inputEmail"
          @focusInput="isDisabled = false;"
        />
      </LabelWrap>
      <CheckBox
        :isChecked="isCheckedService"
        underlineContent="서비스 이용약관"
        content="에 동의합니다."
        @check="check('service')"
        @contentClick="openAgreement('service')"
      />
      <CheckBox
        :isChecked="isCheckedPrivacy"
        underlineContent="개인정보 수집 및 이용"
        content="에 동의합니다."
        @check="check('privacy')"
        @contentClick="openAgreement('privacy')"
      />
      <CheckBox
        :isChecked="isCheckedAge"
        content="만 14세 이상입니다."
        @check="check('age')"
      />
    </LabelWraps>
    <SubmitButton
      title="회원가입"
      :isDisabled="!inputUsername
      || !inputPassword
      || !inputPasswordCheck
      || !inputNickname
      || !inputEmail
      || isDisabled
      || !isCheckedService
      || !isCheckedPrivacy
      || !isCheckedAge"
      @clickButton="SignUp"
    />
    <Notification
      type="error"
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </div>
</template>

<script>
import BlackScreen from "@/components/BlackScreen.vue";
import TopBar from "@/components/Bar/SecondaryTopBar";
import ServiceAgreement from "@/components/ServiceAgreement.vue";
import PrivacyAgreement from "@/components/PrivacyAgreement.vue";
import Notification from "@/components/Alerts/Notification.vue";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import LabelWraps from "@/components/LabelWraps.vue";
import LabelWrap from "@/components/LabelWrap.vue";
import Input from "@/components/Forms/Input.vue";
import CheckBox from "@/components/Forms/CheckBox.vue";
import axios from "axios";
export default {
  name: "SignUp",
  props: {
    isSignUp: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isSignUp: function() {
      this.inputUsername = "";
    }
  },
  components: {
    BlackScreen,
    TopBar,
    ServiceAgreement,
    PrivacyAgreement,
    Notification,
    SubmitButton,
    LabelWraps,
    LabelWrap,
    Input,
    CheckBox
  },
  methods: {
    async SignUp() {
      if (!this.inputNickname
        || !this.inputPassword
        || !this.inputPasswordCheck
        || !this.inputUsername
        || !this.inputEmail
        || !this.isCheckedService
        || !this.isCheckedPrivacy
        || !this.isCheckedAge) {
        this.isDisabled = true;
        return
      }
      if(this.inputPassword !== this.inputPasswordCheck) {
        this.showNotification("비밀번호가 일치하지 않습니다.");
        this.isDisabled = true;
        return
      }
      const url = "https://www.iflab.run/api/signup";
      await axios.post(url, {
          'username': this.inputUsername,
          'password': this.inputPassword,
          'nickname': this.inputNickname,
          'email': this.inputEmail,
      }).catch((response) => {
        if (response.response.status == 400) {
          this.showNotification("이미 존재하는 아이디입니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 401) {
          this.showNotification("아이디 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 402) {
          this.showNotification("비밀번호 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 403) {
          this.showNotification("닉네임 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 404) {
          this.showNotification("이메일 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 405) {
          this.showNotification("사용할 수 없는 아이디입니다.");
          this.isDisabled = true;
          return 0;
        }
      }).then((response) => {
        if (response.status == 200) {
          this.$emit("completeSignUp");
          this.inputUsername = "";
          this.inputPassword = "";
          this.inputPasswordCheck = "";
          this.inputNickname = "";
          this.inputEmail = "";
          this.isCheckedService = false;
          this.isCheckedPrivacy = false;
          this.isCheckedAge = false;
        }
      });
    },
    closeSignUp() {
      this.$emit("closeSignUp");
    },
    openAgreement(type) {
      // scroll 방지 이벤트
      if (type === "service") this.isShowServiceAgreement = true;
      else if (type === "privacy") this.isShowPrivacyAgreement = true;
      const body = document.querySelector('body');
      body.style.overflow = "hidden";
    },
    closeAgreement(type) {
      // scroll 방지 이벤트 해제
      if (type === "service") this.isShowServiceAgreement = false;
      else if (type === "privacy") this.isShowPrivacyAgreement = false;
      const body = document.querySelector('body');
      body.style.overflow = "";
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
    check(type) {
      if (type === "service") this.isCheckedService = !this.isCheckedService;
      else if (type === "privacy") this.isCheckedPrivacy = !this.isCheckedPrivacy;
      else if (type === "age") this.isCheckedAge = !this.isCheckedAge;
    },
  },
  data() {
    return {
      isCheckedService: false,
      isCheckedPrivacy: false,
      isCheckedAge: false,
      inputUsername: "",
      inputPassword: "",
      inputPasswordCheck: "",
      inputNickname: "",
      inputEmail: "",
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
      isShowServiceAgreement: false,
      isShowPrivacyAgreement: false,
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .sign-up {
    background-color: #1c1c1c;
  }
}
.sign-up {
  z-index: 200;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: all 0.3s;

  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;

  padding-bottom: 64px;
  transform: translateX(-100%);
}
.appear-sign-up {
  transition: all 0.3s;
  transform: translateX(0);
}
</style>
