<template>
  <div class="subject-wrapper">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div class="line" />
    </div>
    <Subject
      v-for="subject in subjectArray"
      :key="subject.id"
      :id="subject.id"
      :name="subject.subjectName"
      :day="subject.subjectDay.toString().split(',')"
      :exam="subject.exams"
      :assignment="subject.assignments"
    />
  </div>
</template>

<script>
import Subject from "@/components/Timetable/Subject.vue";
export default {
  name: 'SubjectWrapper',
  components: {
    Subject,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subjectArray: {
      type: Array,
      required: true,
    },
  },
}

</script>

<style lang="scss" scoped>
.dark-mode {
  & .subject-wrapper {
    & > .header {
      & > .title {
        color: #26272c;
      }
    }
  }
}

.subject-wrapper {
  animation: fadeIn 0.5s;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 40px;
  & > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    & > .title {
      transition: all 0.2s;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -1px;
      padding: 2px 8px;

      background-color: #408cff;
      border-radius: 4px;
    }
    & > .line {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
      background-color: #408cff;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
