export const storageMixin = {
  methods: {
    loadFromLocalStorage(key) {
      if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
      }
    },
    saveToLocalStorage(key, data) {
      localStorage.setItem(key, JSON.stringify(data));
    },
  }
}

export const dateDiffMixin = {
  methods: {
    getDateDiff(userDate) {
      if(!userDate) {
        return
      }
      const inputDate = new Date(userDate);
      const calDate = new Date(this.getToday());
      const diffInMs = inputDate - calDate;
      return Math.ceil(diffInMs / (1000 * 60 * 60 * 24)).toString();
    },

    getDday(deadline) {
      if(deadline == '') {
        return '0';
      }
      const inputDate = new Date(deadline);
      const formattedDate = new Date(this.getToday());

      const diffInMs = inputDate - formattedDate;
      let dday = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) * -1;
      if(dday > 0) {
        dday = `+${dday}`;
      } else if(dday == 0) {
        dday = '-Day';
      }
      return dday;
    },

    getToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0') }`;

      return formattedDate;
    }
  },
}
