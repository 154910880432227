<template>
  <div :class="{ 'add-subject': true, disappear: !$store.state.editSubject}">
    <div class="topbar">
      <img src="@/assets/new/close-icon.svg" @click="emitCloseSubject">
      <div v-if="$store.state.nowSubjectId == -1" class="title">과목 생성</div>
      <div v-else class="title">과목 수정</div>
    </div>
    <LabelWrap label="과목 이름">
      <div class="input-wrap">
        <input
          type="text"
          v-model="subjectName"
          placeholder="과목을 입력해주세요."
          onfocus="this.placeholder='';"
          onblur="this.placeholder='과목을 입력해주세요.'"
          @keyup.enter="addSubject(); $event.target.blur()"
        />
        <CharacterCounter
          :length="subjectName.length"
          limit="20"
        />
      </div>
    </LabelWrap>
    <LabelWrap label="요일">
      <div class="day-wrap">
        <div
          v-for="day in dayArray"
          :key="day"
          :class="{ 'day-button': true, 'active-day-button': subjectDay.includes(day) }"
          @click="clickDay(day)"
        >
          {{ day }}
        </div>
      </div>
    </LabelWrap>
    <SubmitButton
      :title="$store.state.nowSubjectId == -1 ? '생성 완료' : '수정 완료'"
      :isDisabled="subjectName.length == 0 || subjectDay.length == 0"
      @clickButton="addSubject"
      :isGray="true"
      :onCard="true"
    />
  </div>
</template>

<script>
import LabelWrap from '@/components/LabelWrap.vue';
import CharacterCounter from '@/components/CharacterCounter.vue';
import SubmitButton from '@/components/Buttons/SubmitButton.vue';
import axios from 'axios';
const dayArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];
export default {
  name: 'AddSubject',
  watch: {
    '$store.state.editSubject': function() {
      if(this.$store.state.editSubject) {
        this.subjectId = this.$store.state.nowSubjectId;
        this.subjectName = this.$store.state.nowSubjectName;
        this.subjectDay = this.$store.state.nowSubjectDay;
      }
    },
    subjectName: function() {
      if(this.subjectName.length > 20) {
        this.subjectName = this.subjectName.slice(0, 20);
      }
    },
  },
  components: {
    LabelWrap, CharacterCounter, SubmitButton
  },
  methods: {
    emitCloseSubject() {
      this.$store.commit('setEditSubject', false);
      if(this.$store.state.beforeCard) {
        this.$store.commit('setEditOpenCard', true);
      }
    },
    async addSubject() {
      if(this.subjectName.length > 0 && this.subjectDay.length > 0) {
        this.subjectDay.sort(function(day1, day2) {
            const days = ["월요일", "화요일", "수요일", "목요일", "금요일"];
            return days.indexOf(day1) - days.indexOf(day2);
        });
        if(this.$store.state.nowSubjectId == -1) {
          const url = "https://www.iflab.run/api/subject";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.post(url, {
            subject_name: this.subjectName,
            subject_day: this.subjectDay.toString()
          }, header);
          this.$emit('subjectNotification', '생성');
          this.initSubject();
        } else {
          const url = "https://www.iflab.run/api/subject";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.patch(url, {
            subject_id: this.subjectId,
            subject_name: this.subjectName,
            subject_day: this.subjectDay.toString()
          }, header);
          this.$emit('subjectNotification', '수정');
          this.$store.commit('setEditSubject', false);
          this.$store.commit('setEditOpenCard', true);
        }
      }
    },
    clickDay(day) {
      if (this.subjectDay.includes(day)) {
        this.subjectDay.splice(this.subjectDay.indexOf(day), 1);
      } else {
        this.subjectDay.push(day);
      }
    },
    initSubject() {
      this.$store.commit('setEditSubject', false);
      setTimeout(() => {
        this.subjectId = -1;
        this.subjectName = '';
        this.subjectDay = [];
        this.$store.commit('setEditSubjectId', -1);
        this.$store.commit('setEditSubjectName', '');
        this.$store.commit('setEditSubjectDay', []);
      }, 300);
    },
  },
  data() {
    return {
      dayArray,
      subjectId: -1,
      subjectName: '',
      subjectDay: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .add-subject {
    background-color: #202020;
    & > .topbar {
      background-color: #1c1c1c;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
  }
  & .input-wrap {
    background-color: #3e3e3e;
    -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    & > input {
      background-color: #3e3e3e;
      color: #f0f0f0;
      &::placeholder {
        color: #606060;
      }
    }
  }
  & .day-wrap {
    & > .day-button {
      background-color: #3e3e3e;
      color: #606060;
      border: none;
    }
    & > .active-day-button {
      background-color: #408cff;
      color: #ffffff;
    }
  }
}
.add-subject {
  transition: all 0.3s;
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 24px 24px 0 0;
  transform: translateY(0%);
  & > .topbar {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 24px 24px 0 0;

    box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

    & > .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: #408cff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.input-wrap {
  display: flex;
  position: relative;

  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;

  & > input {
    -webkit-tap-highlight-color: transparent;

    padding: 16px 48px 16px 8px;
    border: none;
    outline: none;

    color: #3e3e3e;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -1px;

    flex-grow: 1;

    &::placeholder {
      color: #cecece;
    }
  }
}
.day-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  & > .day-button {
    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s;
    padding: 6px 12px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #ffffff;
    color: #cecece;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -1px;
    box-shadow: 0px 4px 8px rgba(96, 96, 96, 0.15);
    user-select: none;
    &:active {
      transition: all 0.1s;
      transform: scale(0.95);
      filter: brightness(0.95);
    }
  }
  & > .active-day-button {
    transition: all 0.1s;
    border: 1px solid #408cff;
    color: #ffffff;
    background-color: #408cff;
    box-shadow: 0px 4px 8px rgba(63, 140, 255, 0.05);
  }
}

.disappear {
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  transform: translateY(30%);
}
</style>
