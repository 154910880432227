<template>
  <div
    :class="{
      category: true,
      'color-category': true,
      'postit-category': theme == 'postit',
      'd-day': stringDday == 'D-day'
    }"
  >
    <div class="header">
      <div
        class="category-name"
        :style="DdayStyle"
      >
        {{ stringDday }}
      </div>
      <div
        class="line"
        :style="DdayStyle"
      />
      <div
        :class="{
          'add-todo-button': true,
          'postit-add-todo-button': theme == 'postit',
          'd-day': stringDday == 'D-day' && theme == 'postit'
        }"
        @click="addTodo"
      >
        <img src="@/assets/new/white-plus-icon.svg">
      </div>
    </div>
    <div class="todo-wrap" v-for="category in todos" :key="category.id">
      <TodayTodo
        v-for="data in category.todos"
        :key="data.id.toString()"
        :id="data.id"
        :categoryColor="category.categoryColor"
        :content="data.content"
        :deadline="data.deadline"
        :isDone="data.isDone"
        @clickDoneTodo="doneTodo"
        @clickMoreTodo="moreTodo(category.categoryName, category.id)"
      />
    </div>
  </div>
</template>

<script>
import { dateDiffMixin } from '@/mixins'
import TodayTodo from "@/components/Todolist/TodayTodo.vue";

export default {
  name: "TodayCategory",
  props: {
    deadline: {
      type: String,
      default: "",
    },
    todos: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TodayTodo
  },
  mixins: [dateDiffMixin],
  created() {
    this.diffDday = this.getDateDiff(this.deadline);
  },
  computed: {
    stringDday() {
      if(!this.diffDday) {
        return;
      } else if(Number(this.diffDday) == 0) {
        return 'D-day';
      } else if(Number(this.diffDday) >= 1000) {
        return '항상';
      } else {
        return 'D-' + this.diffDday;
      }
    },
    DdayStyle() {
      return {
        backgroundColor: this.stringDday === 'D-day' ? '#408cff' : '#98BFFA',
      };
    }
  },
  methods: {
    addTodo() {
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryId", this.categoryId);
      this.$store.commit("setEditTodo", true);
    },
    doneTodo() {
      this.$emit('todoNotification', '완료');
    },
    moreTodo(categoryName, categoryId) {
      this.$store.commit("setEditCategoryName", categoryName);
      this.$store.commit("setEditCategoryId", categoryId);
      this.$store.commit("setEditTodo", true);
    },
  },
  data() {
    return {
      theme: this.$store.state.todolistTheme,
      diffDday: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .category {
    & > .header {
      & > .category-name {
        color: #26272c;
      }
      & > .add-todo-button {
        background-color: #393B43;
        & > img {
          opacity: 0.5;
        }
      }
    }
  }
}

.category {
  animation: fadeIn 0.5s;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 40px;
  & > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    & > .category-name {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -1px;
      padding: 2px 8px;

      border-radius: 4px;
      user-select: none;
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
    }
    & > .line {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
    }
    & > .add-todo-button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px;
      background-color: #cecece;
      border-radius: 4px;

      color: #ffffff;
      font-size: 8px;
      font-weight: bold;
      & > img {
        width: 8px;
        height: 8px;
      }
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
      &.postit-add-todo-button {
        background-color: #98BFFA;
      }
      &.d-day {
        background-color: #408cff;
      }
    }
  }
  & > .todo-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.postit-category {
  padding: 16px;
  margin: 0 16px 24px 16px;
  border-radius: 16px;
  background-color: #98BFFA40;
  &.d-day {
    background-color: #408cff40;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
