<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :fill=fillColor xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 9C3.67157 9 3 9.67157 3 10.5V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V10.5C21 9.67157 20.3284 9 19.5 9H4.5ZM6.75 12C6.33579 12 6 12.3358 6 12.75V14.25C6 14.6642 6.33579 15 6.75 15H8.25C8.66421 15 9 14.6642 9 14.25V12.75C9 12.3358 8.66421 12 8.25 12H6.75ZM10.5 12.75C10.5 12.3358 10.8358 12 11.25 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75V14.25C13.5 14.6642 13.1642 15 12.75 15H11.25C10.8358 15 10.5 14.6642 10.5 14.25V12.75ZM15.75 12C15.3358 12 15 12.3358 15 12.75V14.25C15 14.6642 15.3358 15 15.75 15H17.25C17.6642 15 18 14.6642 18 14.25V12.75C18 12.3358 17.6642 12 17.25 12H15.75Z"/>
    <path d="M3 7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7V7Z"/>
    <rect x="7" y="3" width="3" height="5" rx="1.5"/>
    <rect x="14" y="3" width="3" height="5" rx="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'InputCalendarIcon',
  props: {
    fillColor: {
      type: String,
      default: '#408CFF'
    }
  }
}
</script>
