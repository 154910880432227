<template>
  <div
    v-if="!isDisabled"
    class="delete-button"
    @click="clickButton"
  >
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1V1.5H7.5C7.77614 1.5 8 1.72386 8 2C8 2.27614 7.77614 2.5 7.5 2.5H7V10C7 10.2761 6.77614 10.5 6.5 10.5H1.5C1.22386 10.5 1 10.2761 1 10V2.5H0.5C0.223858 2.5 0 2.27614 0 2C0 1.72386 0.223858 1.5 0.5 1.5H3V1C3 0.723858 3.22386 0.5 3.5 0.5H4.5C4.77614 0.5 5 0.723858 5 1Z" fill="white"/>
    </svg>
    삭제
  </div>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickButton() {
      this.$emit('clickButton');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .delete-button {
    color: #ffffff80;
    & > svg {
      & > path {
        fill: #ffffff80;
      }
    }
  }
}

.delete-button {
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #ff5151;
  border-radius: 8px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  cursor: pointer;
  user-select: none;
  & > svg {
    margin-right: 4px;
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.9);
    filter: brightness(0.7);
  }
}
</style>
