<template>
  <SecondaryPage
    title="비밀번호 변경"
    type="password"
    result="true"
    :isDone="isDone"
  >
    <BlackScreen :isShow="isDone" />
    <LabelWraps>
      <LabelWrap label="현재 비밀번호" >
        <Input
          type="password"
          placeholder="8~16자의 영문자, 숫자, 특수문자"
          v-model="inputNowPassword"
          @focusInput="isDisabled = false;"
          @pressEnter="ChangePassword"
        />
      </LabelWrap>
      <LabelWrap label="새 비밀번호" >
        <Input
          type="password"
          placeholder="8~16자의 영문자, 숫자, 특수문자"
          v-model="inputPassword"
          @focusInput="isDisabled = false;"
          @pressEnter="ChangePassword"
        />
      </LabelWrap>
      <LabelWrap label="새 비밀번호 확인" >
        <Input
          type="password"
          placeholder="8~16자의 영문자, 숫자, 특수문자"
          v-model="inputPasswordCheck"
          @focusInput="isDisabled = false;"
          @pressEnter="ChangePassword"
        />
      </LabelWrap>
    </LabelWraps>
    <SubmitButton
      title="비밀번호 변경"
      :isDisabled="!inputNowPassword
      || !inputPassword
      || !inputPasswordCheck
      || isDisabled"
      @clickButton="ChangePassword"
    />
    <Notification
      type="error"
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </SecondaryPage>
</template>


<script>
import SecondaryPage from "@/components/SecondaryPage.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import Notification from "@/components/Alerts/Notification.vue";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import LabelWraps from "@/components/LabelWraps.vue";
import LabelWrap from "@/components/LabelWrap.vue";
import Input from "@/components/Forms/Input.vue";
import axios from "axios";
export default {
  name: "ChangePassword",
  components: {
    SecondaryPage,
    BlackScreen,
    Notification,
    SubmitButton,
    LabelWraps,
    LabelWrap,
    Input
  },
  data() {
    return {
      isDone: false,
      inputNowPassword: "",
      inputPassword: "",
      inputPasswordCheck: "",
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
    };
  },
  methods: {
    async ChangePassword() {
      if(this.inputPassword !== this.inputPasswordCheck) {
        this.showNotification("비밀번호가 일치하지 않습니다.");
        this.isDisabled = true;
        return
      }
      if(this.inputNowPassword.length > 0 && this.inputPassword.length > 0) {
        const url = "https://www.iflab.run/api/user/password";
        const token = localStorage.getItem("token")
        const header = { headers: { "Authorization": token } };
        await axios.patch(url, {
            password: this.inputNowPassword,
            new_password: this.inputPassword,
        }, header).catch((error) => {
          if (error.response.status === 400) {
            this.showNotification("현재 비밀번호가 일치하지 않습니다.");
            this.isDisabled = true;
          } else if (error.response.status === 401) {
            this.showNotification("비밀번호 형식이 올바르지 않습니다.");
            this.isDisabled = true;
          }
        }).then((response) => {
          if (response.status === 200) {
            this.isDone = true;
          }
        });
      }
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
  },
};
</script>
