<template>
  <div class="topbar-container">
    <div class="topbar">
      <div v-if="title == '지금 할 일'" class="left-wrap">
        <div class="box-wrap">
          <div :class="{ box: true, 'on-box': today }" />
          <div :class="{ box: true, 'on-box': !today }" />
        </div>
        <div class="title-wrap">
          <div
            :class="{ 'second-title': true, 'appear-second-title': !today }"
            @click="emitChange"
          >
            전체 할 일
          </div>
          <div
            :class="{ title: true, 'appear-title': today }"
            @click="emitChange"
          >
            {{ title }}
          </div>
        </div>
      </div>
      <div v-else @click="selectDate" class="title">{{ title }}</div>
      <div class="right-wrap">
        <Button
          v-if="buttonContent"
          :style="{opacity: (!today) ? 1 : 0, transition: 'all 0.3s', scale: (!today) ? 1 : 0.8}"
          :content="buttonContent"
          @clickButton="clickTopbarButton"
        />
      </div>
      <CalendarWeekDay v-if="calendar" />
    </div>
    <div :class="{ 'dummy-height': true, 'dummy-height-calendar': calendar }" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import CalendarWeekDay from "@/components/Calendar/CalendarWeekDay.vue";
export default {
  name: "TopBar",
  props: {
    today: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    calendar: {
      type: Boolean,
      default: false
    },
    buttonContent: {
      type: String,
      default: ""
    }
  },
  components: {
    Button, CalendarWeekDay
  },
  methods: {
    clickTopbarButton() {
      if (this.title == "지금 할 일") {
        this.$store.commit("setEditCategory", true);
      } else if (this.title == "시간표") {
        this.$store.commit("setEditSubject", true);
      }
    },
    emitChange() {
      this.$emit("changeShow");
    },
    selectDate() {
      if ( this.calendar ) {
        this.$emit("selectDate");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .topbar-container {
    & > .topbar {
      transition: all 0.3s;
      background-color: #1c1c1c;
      box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
      & > .left-wrap {
        & > .box-wrap {
          & > .box {
            background-color: #393b43;
            border-radius: 4px;
            margin-right: 8px;
          }
          & > .on-box {
            background-color: #408cff;
          }
        }
        & > .title-wrap {
          & > .title {
            color: #c7c9d0;
          }
          & > .second-title {
            color: #c7c9d0;
          }
        }
      }
      & > .title {
        color: #c7c9d0;
      }
    }
  }
}

.topbar-container {
  user-select: none;
  & > .dummy-height {
    height: 80px;
  }
  & > .dummy-height-calendar {
    height: 0px;
  }
  & > .topbar {
    transition: all 0.3s;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
    box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

    user-select: none;

    & > .left-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      & > .box-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        gap: 4px;
        & > .box {
          transition: all 0.3s;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #e3e3e3;
        }
        & > .on-box {
          transition: all 0.3s;
          background-color: #408cff;
        }
      }
      & > .title-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & > .title {
          transition: all 0.3s;
          transform: translateY(-16px);
          opacity: 0;
          color: #2c3e50;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -1px;
          padding: 3px 0;

          -webkit-tap-highlight-color: transparent;
          user-select: none;
          pointer-events: none;
        }
        & > .appear-title {
          transition: all 0.3s;
          transform: translateY(0);
          pointer-events: auto;
          opacity: 1;
          &:active {
            filter: brightness(0.9);
            transform: translateY(4px);
          }
        }
        & > .second-title {
          transition: all 0.3s;
          transform: translateY(16px);
          position: absolute;
          left: 0;
          opacity: 0;
          color: #2c3e50;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -1px;
          padding: 3px 0;
          -webkit-tap-highlight-color: transparent;
          user-select: none;
          pointer-events: none;
        }
        & > .appear-second-title {
          transition: all 0.3s;
          transform: translateY(0);
          pointer-events: auto;
          opacity: 1;
          &:active {
            filter: brightness(0.9);
            transform: translateY(-4px);
          }
        }
      }
    }
    & > .title {
      -webkit-tap-highlight-color: transparent;
      color: #2c3e50;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      padding: 3px 0;
      user-select: none;
    }

    & > .right-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
