<template>
  <div class="topbar">
    <img src="@/assets/new/blue-left-arrow-icon.svg" @click="emitBack">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "SecondaryTopBar",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    emitBack() {
      this.$emit("clickButton");
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .topbar {
    background-color: #202020;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.topbar {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;

  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

  & > .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    color: #408cff;
    font-size: 16px;
    font-weight: bold;
  }
  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
