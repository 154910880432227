<template>
  <div :class="{ card: true, disappear: !$store.state.openCard }">
    <div class="header">
      <div class="info-wrap">
        <div class="subject-title">
          {{ $store.state.nowSubjectName }}
        </div>
        <div class="subject-day">
          {{ $store.state.nowSubjectDay.join(', ') }}
        </div>
      </div>
      <div class="button-wrap">
        <Button
          content="시험"
          @clickButton="addSubjectInfo('exam')"
        />
        <Button
          buttonType="skyblue"
          content="과제"
          @clickButton="addSubjectInfo('assignment')"
        />
      </div>
    </div>
    <div class="line" v-if="$store.state.nowSubjectAssignment.length != 0 || $store.state.nowSubjectExam.length != 0" />
    <div class="assignment-wrap">
      <SubjectInfo
        v-for="exam in $store.state.nowSubjectExam"
        type="exam"
        :key="exam.id * 2"
        :subjectInfo="exam"
      />
      <SubjectInfo
        v-for="assignment in $store.state.nowSubjectAssignment"
        type="assignment"
        :key="assignment.id * 2 - 1"
        :subjectInfo="assignment"
      />
    </div>
    <div class="line" />
    <div class="more-wrap">
      <div class="wrap" @click="modifySubject">
        <img src="@/assets/new/edit-icon.svg" />
        정보 수정하기
      </div>
      <div class="wrap" @click="deleteSubject">
        <img src="@/assets/new/trashcan-icon.svg" />
        삭제하기
      </div>
    </div>
  </div>
</template>

<script>
import SubjectInfo from "@/components/Timetable/SubjectInfo.vue";
import Button from "@/components/Buttons/Button.vue";
import axios from "axios";
export default {
  name: 'Card',
  components: {
    SubjectInfo, Button
  },
  methods: {
    modifySubject() {
      this.$store.commit('setEditSubject', true);
      this.$store.commit('setEditOpenCard', false);
    },
    async deleteSubject() {
      const url = "https://www.iflab.run/api/subject";
      const token = localStorage.getItem("token")
      await axios.delete(url, {
        headers: { "Authorization": token },
        data: { subject_id: this.$store.state.nowSubjectId }
      });

      this.$store.commit('setEditOpenCard', false);
      this.$store.commit('setEditBeforeCard', false);
      setTimeout(() => {
        if(!this.$store.state.openCard) {
          this.$store.commit('setEditSubjectId', -1);
          this.$store.commit('setEditSubjectName', '');
          this.$store.commit('setEditSubjectDay', []);
          this.$store.commit('setEditSubjectExam', []);
          this.$store.commit('setEditSubjectAssignment', []);
        }
      }, 300);

      this.$emit('subjectNotification', '삭제');
    },
    addSubjectInfo(type) {
      this.$store.commit('setEditSubjectInfo', true);
      this.$store.commit('setEditSubjectInfoType', type);
      this.$store.commit('setEditOpenCard', false);
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .card {
    background-color: #202020;
    & > .header {
      & > .info-wrap {
        & > .subject-title {
          color: #f0f0f0;
        }
        & > .subject-day {
          color: #a0a0a0;
        }
      }
    }
    & > .line {
      background-color: #606060;
    }
  }
}

.card {
  transition: all 0.3s;
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 0 0;
  padding: 40px 16px;
  background-color: #ffffff;
  transform: translateY(0%);

  user-select: none;
  & > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > .info-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      & > .subject-title {
        font-size: 20px;
        font-weight: bold;
        color: #1c1c1c;
        letter-spacing: -0.5px;
      }
      & > .subject-day {
        font-size: 12px;
        font-weight: 500;
        color: #a0a0a0;
        letter-spacing: -0.5px;
      }
    }
    & > .button-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
  }
  & > .line {
    height: 1px;
    background-color: #e0e0e0;
    margin: 12px 0;
  }
  & > .assignment-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  & > .more-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    & > .wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #a0a0a0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.5px;

      & > img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }
}

.disappear {
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50%);
}
</style>
