<template>
  <div class="input">
    <form v-if="type == 'password'" onsubmit="event.preventDefault();">
      <input
        :type="type"
        :placeholder="placeholderText"
        @input="$emit('input', $event.target.value)"
        @keyup.enter="pressEnter(); $event.target.blur()"
        @focus="onFocus"
        @blur="onBlur"
        autocomplete="off"
      />
    </form>
    <input
      v-else
      :type="type"
      :placeholder="placeholderText"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="pressEnter(); $event.target.blur()"
      @focus="onFocus"
      @blur="onBlur"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputPlaceholder: this.placeholder
    };
  },
  computed: {
    placeholderText() {
      return this.inputPlaceholder;
    }
  },
  methods: {
    onFocus() {
      this.inputPlaceholder = "";
      this.$emit("focusInput");
    },
    onBlur() {
      this.inputPlaceholder = this.placeholder;
    },
    pressEnter() {
      this.$emit("pressEnter");
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .input {
    background-color: #393b43;
    -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    & > input {
      background-color: #393b43;
      color: #f0f0f0;
      &::placeholder {
        color: #6b6c72;
      }
    }
    & > form {
      & > input {
        background-color: #393b43;
        color: #f0f0f0;
        &::placeholder {
          color: #6b6c72;
        }
      }
    }
  }
}

.input {
  display: flex;

  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
}

form {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-grow: 1;
}

input {
  -webkit-tap-highlight-color: transparent;

  padding: 16px 8px;
  border: none;
  outline: none;

  color: #3e3e3e;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;

  flex-grow: 1;

  &::placeholder {
    color: #cecece;
  }
}
</style>
