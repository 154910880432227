<template>
  <div class="secondary-page">
    <TopBar
      :title="title"
      @clickButton="goBack"
    />
    <slot />
    <ResultPage
      v-if="result"
      :isDone="isDone"
      :type="type"
    >
      <SubmitButton
        title="이전 페이지로 돌아가기"
        :isDisabled="false"
        @clickButton="goBack"
      />
    </ResultPage>
  </div>
</template>

<script>
import ResultPage from "@/components/ResultPage.vue";
import TopBar from "@/components/Bar/SecondaryTopBar";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
export default {
  name: "SecondaryPage",
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    result: {
      type: Boolean,
      default: false,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ResultPage,
    TopBar,
    SubmitButton
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .secondary-page {
    background-color: #1c1c1c;
  }
}

.secondary-page {
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 100vh;
  position: relative;
}
</style>
