<template>
  <div
    :class="{ notification: true, appear: isAppear, 'type-error': type == 'error' }"
    @click="closeNotification"
  >
    <img v-if="type == 'error'" src="@/assets/new/notification-error-icon.svg">
    <img v-else src="@/assets/new/notification-check-icon.svg">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    type: {
      type: String,
      default: ""
    },
    isAppear: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ""
    },
  },
  methods: {
    closeNotification() {
      this.$emit("closeNotification");
    }
  },
}
</script>

<style lang="scss" scoped>
.notification {
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.68, -0.32, 0.89, 0.18);
  z-index: 400;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -40px;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  background-color: #408cff;
  margin: 0 16px;
  padding: 14px;
  border-radius: 16px;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;

  cursor: pointer;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  & > img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
}
.type-error {
  background-color: #ff5151;
}

.appear {
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  bottom: 80px;
  opacity: 1;
  pointer-events: auto;
}
</style>
