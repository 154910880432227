<template>
  <div class="todolist">
    <!--div class="sidebar">
      <div class="sidebar-item" @click="clickSidebar('')">
        <img v-if="nowSidebar == 'Todolist'" src="@/assets/new/active-todolist-icon.svg">
        <img v-else src="@/assets/new/todolist-icon.svg">
        오늘의 할 일
      </div>
      <div class="sidebar-item" @click="clickSidebar('timetable')">
        <img v-if="nowSidebar == 'Timetable'" src="@/assets/new/active-timetable-icon.svg">
        <img v-else src="@/assets/new/timetable-icon.svg">
        시간표
      </div>
      <div class="sidebar-item" @click="clickSidebar('setting')">
        <img v-if="nowSidebar == 'Setting'" src="@/assets/new/active-setting-icon.svg">
        <img v-else src="@/assets/new/setting-icon.svg">
        설정
      </div>
    </div-->
    <BlackScreen />
    <Calendar />
    <div class="wrap">
      <div class="topbar">
        <div class="title">
          오늘의 할 일
        </div>
        <Button
          content="카테고리"
          @clickButton="addCategory"
        />
      </div>
      <div class="categories">
        <Loading v-if="isLoading" />
        <Empty
          v-else-if="todolist.length == 0"
          title="카테고리를 추가해보세요."
          :isExist="todolist.length == 0"
        />
        <Category
          v-else
          v-for="(data, index) in todolist"
          :key="data.id"
          :delay="index * 0.1"
          :categoryId=data.id
          :categoryName=data.categoryName
          :categoryColor=data.categoryColor
          :todos=data.todos
          @todoNotification="todoNotification"
        />
      </div>
    </div>
    <AddTodo @todoNotification="todoNotification" />
    <AddCategory @categoryNotification="categoryNotification" />
    <Notification
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
    <!--Timetable /-->
    <UserCircle />
  </div>
</template>

<script>
import BlackScreen from "@/components/BlackScreen.vue";
import Loading from "@/components/web/Loading.vue";
import Empty from "@/components/web/Empty.vue";
import Category from "@/components/web/Category.vue";
import AddCategory from "@/components/web/AddCategory.vue";
import AddTodo from "@/components/web/AddTodo.vue";
import Button from "@/components/web/Button.vue";
import Notification from "@/components/web/Notification.vue";
import UserCircle from "@/components/web/UserCircle.vue";
//import Timetable from "@/views/web/Timetable.vue";
import Calendar from "@/views/web/Calendar.vue";
import axios from "axios";
import EventBus from '@/utils/EventBus.js';
import { storageMixin } from '@/mixins'

export default {
  name: "Todolist",
  components: {
    BlackScreen,
    Loading,
    Empty,
    Category,
    AddCategory,
    AddTodo,
    Button,
    Notification,
    UserCircle,
    Calendar,
    //Timetable
  },
  mixins: [storageMixin],
  computed: {
    todolist: {
      get() {
        return this.$store.state.allTodolist;
      },
      set(value) {
        this.$store.commit('setAllTodolist', value);
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', this.checkWindowSize)
    this.checkWindowSize()
    if (this.todolist.length == 0) {
      await this.loadFromServer();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    async categoryNotification(mode) {
      await this.loadFromServer();
      this.showNotification('카테고리가 ' + mode + '되었습니다.');
    },

    async todoNotification(mode, newTodo) {
      this.showNotification('할 일이 ' + mode + '되었습니다.');
      if(mode == '완료') {
        setTimeout(async() => {
          await this.loadFromServer();
        }, 510);
      } else {
        await this.loadFromServer();
        await EventBus.$emit('updateTodo', mode, newTodo);
      }
    },
    addCategory() {
      this.$store.commit('setEditCategory', true);
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },

    async loadFromServer() {
      if(this.todolist.length == 0) {
        //this.isLoading = true;
      }
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/todolist";
        const token = localStorage.getItem("token")
        const header = { headers: { "Authorization": token } };
        const response = await axios.get(url, header);
        this.todolist = response.data;
        /*
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        */
      } else if(window.innerWidth >= 800) {
        this.$router.push("/web/signin");
        localStorage.removeItem("token");
        localStorage.removeItem("isSignIn");
        this.$store.commit('setIsSignIn', false);
      } else {
        this.$router.push("/signin");
        localStorage.removeItem("token");
        localStorage.removeItem("isSignIn");
        this.$store.commit('setIsSignIn', false);
      }
    },

    checkWindowSize() {
      if (window.innerWidth < 800) {
        this.$router.push("/");
      }
    },
  },
  data() {
    return {
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .todolist {
    transition: all 0.3s;
    background-color: #1c1c1c;
    & > .wrap {
      & > .topbar {
        transition: all 0.3s;
        background-color: #26272c;
        & > .title {
          transition: all 0.3s;
          color: #c7c9d0;
        }
      }
      & > .categories {
        transition: all 0.3s;
        background-color: #26272c;
        border: 16px solid #26272c;
        &::after {
          transition: all 0.3s;
          background: linear-gradient(to top, #26272c, #26272c00);
        }
      }
    }
  }
}

.todolist {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f1f5;
  min-height: 100vh;
  gap: 40px;
  padding: 16px;
}

.wrap {
  display: flex;
  flex-direction: column;
  min-width: 432px;
  gap: 16px;
  & > .topbar {
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    & > .title {
      transition: all 0.3s;
      color: #2c3e50;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.categories {
  transition: all 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 16px solid #ffffff;
  border-radius: 16px;

  height: 85vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    height: 5%;
    background: #ffffff;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &:after {
    transition: all 0.3s;
    content: "";
    position: sticky;
    width: 100%;
    bottom: -1px;
    left: 0;
    right: 0;
    padding: 32px 0;
    background: linear-gradient(to top, #ffffff, #ffffff00);
  }
}

.sidebar {
  transition: all 0.3s;
  min-width: 140px;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 8px 0 16px;
  border-radius: 0 16px 16px 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0px -4px 16px rgba(96, 96, 96, 0.25);
  box-shadow: 0px -4px 16px rgba(96, 96, 96, 0.25);

  & > .sidebar-item {
    display: flex;
    flex-direction: row;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s;
    user-select: none;
    color: #4080ff;
    font-size: 16px;
    font-weight: 500;
    & > img {
      width: 32px;
      height: 32px;
    }
    &:active {
      transition: all 0.1s;
      -webkit-tap-highlight-color: transparent;
      transform: scaleX(1.2) scaleY(0.8);
    }
  }
}
</style>
