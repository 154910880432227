<template>
  <div
    :class="{
      'submit-button': true,
      gray: isGray && isDisabled,
      disabled: isDisabled,
      card: onCard,
    }"
    @click="emitClickButton"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    isGray: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onCard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClickButton() {
      this.$emit('clickButton');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .submit-button {
    background-color: #408cff;
  }
  & .disabled {
    color: #6b6c72;
    background-color: #393b43;
  }
}

.submit-button {
  transition: all 0.2s;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 0;

  background-color: #408cff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;

  user-select: none;
  &:active {
    transition: all 0.1s;
    filter: brightness(0.9);
  }
}
.disabled {
  transition: all 0.2s;
  background-color: #ffffff;
  color: #cecece;
  &:active {
    transition: all 0.1s;
    filter: brightness(1);
  }
}
.gray {
  background-color: #f0f0f0;
  color: #a0a0a0;
  &:active {
    transition: all 0.1s;
    filter: brightness(1);
  }
}

.card {
  position: relative;
  margin-top: 64px;
}

@media screen and (min-height: 750px) {
  .submit-button {
    padding: 20px 0 44px 0;
  }
}
</style>
