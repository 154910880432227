var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-page"},[_c('BackgroundScreen'),_c('BlackScreen',{attrs:{"isShow":_vm.isSelectDate},on:{"close":_vm.closeSelectDate}}),_c('TopBar',{attrs:{"today":false,"calendar":true,"title":_vm.nowMonthText},on:{"selectDate":_vm.openSelectDate}}),_vm._l((_vm.calendarArray),function(calendar,i){return _c('div',{key:i,class:{ calendar: true, dragging: _vm.dragY != 0, 'calendar-left': !_vm.dragging && _vm.changeMonth == -1, 'calendar-right': !_vm.dragging && _vm.changeMonth == 1 },on:{"touchstart":_vm.startDrag,"touchmove":_vm.handleDrag,"touchend":_vm.endDrag}},[_c('div',{class:{ 'calendar-wrap': true, no: _vm.isClickedDate },style:({
        transform: ("translateY(" + _vm.dragY + "px)"),
        opacity: (_vm.dragY >= 0 ? 1-_vm.dragY/30 : 1+_vm.dragY/30)
      })},[(_vm.isLoading)?_c('Loading'):_vm._l((calendar),function(date,i){return _c('div',{key:i,class:{
          date: true,
          'today-date':
            date.date == new Date().getDate() && date.month == (new Date().getMonth() + 1),
          'clicked-date': date.date == _vm.nowClickedDate.date && date.month == _vm.nowClickedDate.month,
          'not-now-month': date.notNowMonth
        },style:({ 'animation-delay': ((i * 0.01) + "s") })},[_c('div',{staticClass:"date-text"},[_vm._v(_vm._s(date.date))]),_c('div',{staticClass:"event"},_vm._l((date.events),function(event,i){return _c('div',{key:event.id,class:{ 'event-item': true, 'hide-event-item': _vm.isClickedDate },style:({ 'background-color': event.color })},[(i < 4)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(event.content)+" ")]):_vm._e()])}),0)])})],2)])}),_c('DatePicker',{attrs:{"isSelectDate":_vm.isSelectDate,"nowYear":_vm.nowYear,"nowMonth":_vm.nowMonth},on:{"close-date-picker":_vm.closeSelectDate,"select-date":_vm.selectDate}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }