<template>
  <div class="setting">
    <BlackScreen :isShow="isShowInstagram || isShowKakao || isShowModal || isShowNickname" />
    <TopBar title="설정" />
    <Loading v-if="isLoading" />
    <div v-else class="setting-list">
      <div v-if="isSignIn" class="profile">
        <img src="@/assets/new/profile-char-icon.svg" />
        <div class="nickname">
          <b>{{ nickname }}</b>
          <div class="message">
            <span :class="{ show: isShowMessage }"> 님의 오늘을 응원해요!</span>
            <span :class="{ show: !isShowMessage }"> 님의 하루를 함께할게요! </span>
          </div>
        </div>
        <div class="use-day"> D+{{ createdAt }}  </div>
      </div>
      <div class="category-wrap">
        <div class="title">계정</div>
        <SettingOption
          v-if="isSignIn"
          title="닉네임 변경"
          type="blueArrow"
          @clickOption="openNickname"
        />
        <SettingOption
          v-if="isSignIn"
          title="비밀번호 변경"
          type="blueArrow"
          @clickOption="pushRoute('/setting/changepassword')"
        />
        <SettingOption
          v-if="isSignIn"
          title="이메일 변경"
          type="blueArrow"
          @clickOption="pushRoute('/setting/changeemail')"
        />
        <SettingOption
          v-if="isSignIn && isPermitPush"
          title="알림 설정"
          type="blueArrow"
          @clickOption="pushRoute('/setting/push')"
        />
        <SettingOption
          v-if="isSignIn"
          title="로그아웃"
          type="blueArrow"
          @clickOption="openModal"
        />
        <SettingOption
          v-if="isSignIn"
          title="회원탈퇴"
          type="redArrow"
          @clickOption="pushRoute('/deleteaccount')"
        />
      </div>
      <div class="category-wrap">
        <div class="title">화면</div>
        <SettingOption
          :noActive="true"
          title="기기 테마 사용"
          type="radioButton"
          :isOn="isSystem"
          @clickRadioButton="clickSystemRadioButton"
        />
        <SettingOption
          :noActive="true"
          title="라이트 모드"
          type="radioButton"
          :isOn="!isSystem && !isDarkmode"
          @clickRadioButton="clickLightmodeRadioButton"
        />
        <SettingOption
          :noActive="true"
          title="다크 모드"
          type="radioButton"
          :isOn="!isSystem && isDarkmode"
          @clickRadioButton="clickDarkmodeRadioButton"
        />
        <SettingOption
          v-if="isSignIn"
          title="테마 변경"
          type="blueArrow"
          @clickOption="pushRoute('/setting/changetheme')"
        />
      </div>
      <div class="category-wrap">
        <div class="title">문의</div>
        <SettingOption
          title="인스타그램으로 문의"
          type="blueArrow"
          @clickOption="openChannel('instagram')"
        />
        <SettingOption
          title="카카오톡 채널로 문의"
          type="blueArrow"
          @clickOption="openChannel('kakao')"
        />
      </div>
      <Instagram
        :isShowInstagram="isShowInstagram"
        @closeInstagram="closeChannel('instagram')"
      />
      <Kakao
        :isShowKakao="isShowKakao"
        @closeKakao="closeChannel('kakao')"
      />
      <div class="category-wrap">
        <div class="title">공유</div>
        <SettingOption
          title="링크 공유하기"
          type="blueArrow"
          @clickOption="shareLink('link')"
        />
        <SettingOption
          title="카카오톡으로 공유하기"
          type="blueArrow"
          @clickOption="shareLink('kakao')"
        />
      </div>
      <div class="category-wrap">
        <div class="title">기타</div>
        <SettingOption
          title="이용약관 및 정책"
          type="blueArrow"
          @clickOption="pushRoute('/servicepolicy')"
        />
        <SettingOption
          title="개인정보 처리 방침"
          type="blueArrow"
          @clickOption="pushRoute('/privacypolicy')"
        />
        <SettingOption
          title="개발 노트"
          type="blueArrow"
          @clickOption="pushRoute('/developmentnote')"
        />
        <SettingOption
          :noActive="true"
          title="서비스 버전"
          type="text"
          text="0.4.8"
        />
      </div>
    </div>
    <ChangeNickname
      v-if="isShowNicknamePage"
      :isShowNickname="isShowNickname"
      @closeNickname="closeNickname"
    />
    <Modal
      title="로그아웃 하시겠습니까?"
      content="보다 안전하게 로그아웃을 진행해 드릴게요."
      cancelText="로그아웃"
      confirmText="취소"
      :isShowModal="isShowModal"
      @cancel="closeModal"
      @confirm="goSignIn"
    />
  </div>
</template>

<script>
import ChangeNickname from "@/views/ChangeNickname1.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import Loading from "@/components/Loading.vue";
import TopBar from "@/components/Bar/TopBar";
import Modal from "@/components/Alerts/Modal.vue";
import SettingOption from "@/components/Setting/SettingOption.vue";
import Instagram from "@/components/Instagram.vue";
import Kakao from "@/components/Kakao.vue";
import axios from "axios";
import { dateDiffMixin } from '@/mixins';

export default {
  name: "Setting",
  components: {
    BlackScreen,
    Loading,
    TopBar,
    Modal,
    SettingOption,
    Instagram,
    Kakao,
    ChangeNickname
  },
  mixins: [dateDiffMixin],
  created() {
     setTimeout(() => {
       this.isShowMessage = !this.isShowMessage;
     }, 7000);
  },
  async mounted() {
    if (this.nickname.length == 0 || this.createdAt.length == 0) {
      await this.loadFromServer();
    }

    if("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service_worker.js");

      const url = "https://www.iflab.run/api/push";
      const token = localStorage.getItem("token");
      const header = { headers: { "Authorization": token } };
      await axios.get(url, header);
    }
  },

  data() {
    return {
      isSignIn: this.$store.state.isSignIn,
      isDarkmode: this.$store.state.isDarkmode,
      isSystem: this.$store.state.isSystem,
      isPermitPush: window.Notification.permission == "granted",
      isShowInstagram: false,
      isShowKakao: false,
      isShowModal: false,
      nickname: this.$store.state.nickname,
      createdAt: this.$store.state.createdAt,
      isShowMessage: false,
      isLoading: false,

      isShowNickname: false,
      isShowNicknamePage: false,
    }
  },
  methods: {
    async loadFromServer() {
      if(this.nickname.length == 0 || this.createdAt.length == 0) {
        this.isLoading = true;
      }
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/user/info";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        const response = await axios.get(url, header);
        this.nickname = response.data.nickname;
        const date = new Date(response.data.created_at);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        this.createdAt = this.getDateDiff(`${year}-${month}-${day}`) * -1 + 1;

        this.$store.commit('setNickname', this.nickname);
        this.$store.commit('setCreatedAt', this.createdAt);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        this.$router.push("/signin");
      }
    },
    clickSystemRadioButton() {
      this.isSystem = true;
      this.setSystem();
    },
    clickLightmodeRadioButton() {
      this.isSystem = false;
      this.setSystem();
      this.isDarkmode = false;
      this.setDarkmode();
    },
    clickDarkmodeRadioButton() {
      this.isSystem = false;
      this.setSystem();
      this.isDarkmode = true;
      this.setDarkmode();
    },
    openNickname() {
      this.isShowNicknamePage = true;
      setTimeout(() => {
        this.isShowNickname = true;
      }, 100);
    },
    closeNickname(nickname) {
      this.isShowNickname = false;
      setTimeout(() => {
        this.isShowNicknamePage = false;
      }, 300);
      if (nickname) {
        this.nickname = nickname;
        this.$store.commit('setNickname', this.nickname);
      }
    },
    shareLink(type) {
      if (type == "link") {
        if (navigator.share) {
          navigator
            .share({
              title: "miruzima",
              url: "https://www.iflab.run",
            })
        } else {
          alert("현재 브라우저에서 지원하지 않습니다.");
        }
      } else if (type == "kakao") {
        window.Kakao.Share.sendCustom({
          templateId: 94694,
          installTalk: true
        });
      }
    },
    setDarkmode() {
      this.$store.commit('setIsDarkmode', this.isDarkmode)
    },
    setSystem() {
      this.$store.commit('setIsSystem', this.isSystem)
    },
    pushRoute(path) {
      setTimeout(() => {
        this.$router.push(path);
      }, 200);
    },
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      setTimeout(() => {
        this.isShowModal = false;
      }, 100);
    },
    openChannel(ch) {
      if(ch == 'instagram') this.isShowInstagram = true;
      else if(ch == 'kakao') this.isShowKakao = true;
    },
    closeChannel(ch) {
      if(ch == 'instagram') this.isShowInstagram = false;
      else if(ch == 'kakao') this.isShowKakao = false;
    },
    async goSignIn() {
      if(this.isSignIn) {
        const url = "https://www.iflab.run/api/signout";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        await axios.post(url, header);

        this.$store.commit('setToken', '');
        this.$store.commit('setIsSignIn', false);
        this.$store.commit('setNickname', '');
        this.$store.commit('setCreatedAt', '');
        this.$store.commit('setAllTodolist', []);
        this.$store.commit('setEditSubjectArray', []);
        localStorage.removeItem("token");
        localStorage.removeItem("isSignIn");
      }
      setTimeout(() => {
        this.$router.push("/signin");
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .setting {
    transition: all 0.3s;
    background-color: #26272c;
    & > .setting-list {
      & > .profile {
        transition: all 0.3s;
        background-color: #33343c;
        & > .nickname {
          transition: all 0.3s;
          color: #b7b8c9;
        }
      }
      & > .category-wrap {
        transition: all 0.3s;
        border-top: 1px solid #323340;
        & > .title {
          color: #a0a0a0;
        }
      }
    }
  }
}


.setting {
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 80px;
  & > .setting-list {
    animation: fadeIn 0.5s;
    display: flex;
    flex-direction: column;
  }
}
.profile {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 16px;
  background-color: #ffffff;
  padding: 16px;
  margin: 16px 16px 24px 16px;

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  & > .nickname {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #606060;
    font-size: 16px;
    font-weight: 500;

    text-align: left;
    letter-spacing: -1px;
    flex-grow: 1;
    & > .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > span {
        position: absolute;
        transition: all 1s;
        opacity: 1;
      }
      & > .show {
        transition: all 1s;
        opacity: 0;
      }
    }
  }

  & > .use-day {
    color: #408cff;
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
    letter-spacing: -1px;
  }
}

.category-wrap {
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding: 24px 0px 16px 0;
  border-top: 1px solid #e3e3e3;
  & > .title {
    color: #606060;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -1px;

    align-self: flex-start;
    padding-left: 16px;
    margin-bottom: 8px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
