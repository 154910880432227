<template>
  <div :class="{ app: true, 'dark-mode': isDarkmode }">
    <router-view/>
    <Navbar
      v-if="
        $route.path == '/' ||
        $route.path == '/calendar' ||
        $route.path == '/timetable' ||
        $route.path == '/setting'
      "
    />
  </div>
</template>
<script>
addEventListener("touchstart", { capture: false, once: false, passive: true });
import Navbar from './components/Bar/NavBar';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  async mounted() {
    await this.renewalToken();
  },
  created() {
    // this.showIntroduce();
  },
  computed: {
    isSystem: {
      get() {
        return this.$store.state.isSystem;
      },
      set(newValue) {
        this.$store.commit('setIsSystem', newValue);
      }
    },
    isDarkmode: {
      get() {
        const themeMetaTag = document.querySelector('meta[name="theme-color"]');

        this.darkModeMediaQuery.addEventListener('change', (e) => {
          if(this.$store.state.isSystem) {
            if(e.matches) {
              this.$store.commit('setIsDarkmode', true);
              themeMetaTag.setAttribute("content", "#1c1c1c");
              document.body.style.backgroundColor = "#000a0f";
            } else {
              this.$store.commit('setIsDarkmode', false);
              themeMetaTag.setAttribute("content", "#ffffff");
              document.body.style.backgroundColor = "#f0f2f5";
            }
          }
        });

        if(this.$store.state.isSystem) {
          if(this.darkModeMediaQuery.matches) {
            this.$store.commit('setIsDarkmode', true);
            themeMetaTag.setAttribute("content", "#1c1c1c");
            document.body.style.backgroundColor = "#000a0f";
          } else {
            this.$store.commit('setIsDarkmode', false);
            themeMetaTag.setAttribute("content", "#ffffff");
            document.body.style.backgroundColor = "#f0f2f5";
          }
        } else {
          if(this.$store.state.isDarkmode) {
            themeMetaTag.setAttribute("content", "#1c1c1c");
            document.body.style.backgroundColor = "#000a0f";
          } else {
            themeMetaTag.setAttribute("content", "#ffffff");
            document.body.style.backgroundColor = "#f0f2f5";
          }
        }
        return this.$store.state.isDarkmode;
      },
      set(newValue) {
        this.$store.commit('setIsDarkmode', newValue);
      }
    },
  },
  methods: {
    showIntroduce() {
      console.log("%c\n\n\n            _                _\n  _ __ ___ (_)_ __ _   _ ___(_)_ __ ___   __ _ \n | '_ ` _ \\| | '__| | | |_  / | '_ ` _ \\ / _` |\n | | | | | | | |  | |_| |/ /| | | | | | | (_| |\n |_| |_| |_|_|_|   \\__,_/___|_|_| |_| |_|\\__,_|\n\n" + "%c                     You will have a better day", "color: #408cff; font-weight: bold", "color: #1c1c1c");

    },
    async renewalToken() {
      if (!localStorage.getItem("token")) {
        if (this.$route.path != "/signin") {
          this.$router.push("/signin");
        }
      } else {
        const url = "https://www.iflab.run/api/token";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        try {
          const response = await axios.get(url, header);
          if (response.status == 200) {
            this.$store.commit('setToken', response.data.access_token);
          } 
        } catch (error) {
          // Access Token and Refresh Token Expired
          this.$store.commit('setToken', "");
          this.$store.commit('setIsSignIn', false);
          localStorage.removeItem("token");
          localStorage.removeItem("isSignIn");
          this.$router.push("/signin");
        }
      }
    },
  },
  data() {
    return {
      darkModeMediaQuery: window.matchMedia('(prefers-color-scheme: dark)'),
    }
  },
}
</script>
<style lang="scss">
.app {
  height: 100%;
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f0f0f0;
}

.dark-mode {
  background-color: #26272c;
}

* {
  box-sizing: border-box;
  letter-spacing: -0.1em;
}

body {
  margin: 0px;
  background-color: #f0f0f0;
  &.dark-mode {
    background-color: #26272c;
  }
}
img {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-touch-callout: none;
}
	
html, body {
	height: 100%;
}
</style>
