<template>
  <img class="loading" v-if="!isDarkmode" src="@/assets/new/loading-icon.svg" />
  <img class="loading" v-else src="@/assets/new/dark-loading-icon.svg" />
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      isDarkmode: this.$store.state.isDarkmode,
    };
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  animation: rotate 4s infinite cubic-bezier(0.25, 0.51, 0.43, 0.7);
  width: 48px;
  height: 48px;

  user-select: none;
  pointer-events: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
</style>
