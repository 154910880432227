'use strict';

import axios from "axios";

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server
  const subscription_str = JSON.stringify(subscription);
  const subscription_json = JSON.parse(subscription_str);
  const endpoint = subscription_json.endpoint;
  const keys = subscription_json.keys;

  const url = "https://www.iflab.run/api/subscribe";
  const token = localStorage.getItem("token");
  const header = { headers: { "Authorization": token } };

  const response = await axios.post(url, {
      'endpoint': endpoint,
      'keys': keys,
  }, header);

  return response;

  /*
  return fetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subscription_json: JSON.stringify(subscription)
    })
  });
  */
}

function subscribeUser(swRegistration, applicationServerPublicKey) {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  setTimeout(() => {
    swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
    .then(function(subscription) {
      console.log('User is subscribed.');

      return updateSubscriptionOnServer(subscription);
    })
    .then(function(response) {
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }
      return response.json();
    })
    .then(function(responseData) {
      console.log(responseData);
      if (responseData.status!=="성공") {
        throw new Error('Bad response from server.');
      }
    })
    .catch(function(err) {
      console.log('Failed to subscribe the user: ', err);
      console.log(err.stack);
    });
  }, 1000);
}

export function registerServiceWorker(serviceWorkerUrl, applicationServerPublicKey) {
  let swRegistration = null;
  if ('serviceWorker' in navigator) {
    console.log('Service Worker and Push is supported');

    navigator.serviceWorker.register(serviceWorkerUrl)
    .then(function(swReg) {
      console.log('Service Worker is registered', swReg);
      subscribeUser(swReg, applicationServerPublicKey);

      swRegistration = swReg;
    })
    .catch(function(error) {
      console.error('Service Worker Error', error);
    });
  } else {
    console.warn('Push messaging is not supported');
  } 
  return swRegistration;
}
