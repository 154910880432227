<template>
  <div :class="{ 'privacy-agreement': true, appear: isShowPrivacyAgreement }">
    <div class="topbar">
      <div class="title">개인정보 처리 동의</div>
    </div>
    <div class="content">
      &lt;miruzima&gt;('www.iflab.run' 이하 'miruzima'라고 합니다)는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 귀하의 개인정보보호에 최선을 다하고 있습니다. miruzima는 개인정보보호법에 근거하여 다음과 같은 내용으로 개인정보를 수집 및 처리하고자 합니다.
      <br><br>
      다음의 내용을 자세히 읽어보시고 모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다. 
      <br><br>
      <b>제1조(개인정보 수집 및 이용 목적)</b>
      이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 목적 이외의 용도로는 사용되지 않습니다. 
      <b>-<u> 본인확인 및 서비스 품질 개선</u></b>

      <br><br>
      <b>제2조(개인정보 수집 및 이용 항목) </b>
      miruzima는 개인정보 수집 목적을 위하여 다음과 같은 정보를 수집합니다. 
      <b>- <u>이메일</u></b>

      <br><br>
      <b>제3조(개인정보 보유 및 이용 기간) </b>
      1. 수집한 개인정보는 수집•이용 동의일로부터 개인정보 수집·이용 목적을 달성할 때까지 보관 및 이용합니다. 
      2. 개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 

      <br><br>
      <b> 제4조(동의 거부 관리) </b>
      귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를 거부할 권리가 있습니다. 다만, 귀하가 개인정보 동의를 거부하시는 경우에 <b><u>서비스 회원가입 제한</u></b>의 불이익이 발생할 수 있음을 알려드립니다.

      <br><br>
      본인은 위의 동의서 내용을 충분히 숙지하였으며, 위와 같이 개인정보를 수집·이용하는데 동의합니다.
    </div>
    <SubmitButton
        style="z-index: 1000; position: absolute; bottom: 0; left: 0; right: 0;"
        title="닫기"
        @clickButton="closePrivacyAgreement"
        />
  </div>
</template>

<script>
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
export default {
  name: "PrivacyAgreement",
  components: {
    SubmitButton
  },
  props: {
    isShowPrivacyAgreement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    closePrivacyAgreement() {
      this.$emit("closePrivacyAgreement");
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .privacy-agreement {
    & > .topbar {
      background-color: #202020;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
    & > .content {
      background-color: #1c1c1c;
      color: #858b94;
    }
  }
}

.privacy-agreement {
  transition: all 0.3s;
  z-index: 600;
  position: fixed;
  top: 24px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  transform: translateY(100%);
  overflow-y: scroll;
  &.appear {
    transition: all 0.3s;
    transform: translateY(0%);
    & > .content {
      animation: fadeIn 1s;
    }
  }
  & > .topbar {
    z-index: 700;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 8px 8px 0 0;

    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

    & > .title {
      color: #408cff;
      font-size: 16px;
      font-weight: bold;
    }
  }
  & > .content {
    height: 100vh;
    overflow-y: scroll;
    padding: 80px 16px 16px 16px;
    margin-bottom: 63px;
    display: flex;
    flex-direction: column;
    text-align: left;

    color: #858b94;
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-16px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
