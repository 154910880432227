import axios from 'axios';

const Instance = () => {
  const instance = axios.create({
    baseURL: 'https://www.iflab.run/api',
    validateStatus: status => status < 400,
  });

  const onFulfilled = response => response;

  const onRejected = async error => {
    const retryCount = error.config.retryCount || 1;

    if (retryCount >= 3) {
      return Promise.reject(error);
    }

    if (!localStorage.getItem("token")) {
      if (this.$route.path != "/signin") {
        this.$router.push("/signin");
      }
    } else {
      const url = "https://www.iflab.run/api/token";
      const token = localStorage.getItem("token");
      const header = { headers: { "Authorization": token } };
      try {
        const response = await axios.get(url, header);
        if (response.status == 200) {
          this.$store.commit('setToken', response.data.access_token);
        } 
      } catch (error) {
        // Access Token and Refresh Token Expired
        this.$store.commit('setToken', "");
        this.$store.commit('setIsSignIn', false);
        localStorage.removeItem("token");
        localStorage.removeItem("isSignIn");
        this.$router.push("/signin");
        return Promise.reject(error);
      }
    }
    return instance.request(error.config);
  };

  instance.interceptors.request.use(request => {
    return {
      ...request,
      timeout: 2000,
      headers: {
        ...request.headers,
        Authorization: localStorage.getItem('token'),
      },
    };
  });
  instance.interceptors.response.use(onFulfilled, onRejected);

  return instance;
};
export const axiosInstance = Instance();
