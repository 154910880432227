<template>
  <div class="label-wrap">
    <Label :label="label" />
    <slot />
  </div>
</template>

<script>
import Label from "@/components/Label.vue";
export default {
  name: "LabelWrap",
  components: {
    Label
  },
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.label-wrap {
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px 16px;
}
</style>
