<template>
  <div class="subject">
    <div class="wrap" @click="openCard">
      <div class="line" />
      <div class="subject-wrap">
        <div class="name">{{ name }}</div>
        <div class="day">{{ day.join(', ') }}</div>
      </div>
      <div class="dday-wrap">
        <div
          :class="{
            dday: true,
            exam: true,
            deadline: Number(diffExamDday) <= 7,
            expired: stringExamDday == '기한만료'
          }"
        >
          {{ stringExamDday }}
        </div>
        <div
          :class="{
            dday: true,
            assignment: true,
            deadline: Number(diffAssignmentDday) <= 5,
            expired: stringAssignmentDday == '기한만료'
          }"
        >
          {{ stringAssignmentDday }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateDiffMixin } from '@/mixins'
import EventBus from '@/utils/EventBus'
export default {
  name: 'Subject',
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    day: {
      type: Array,
      default: () => [],
    },
    exam: {
      type: Array,
      default: () => [],
    },
    assignment: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [dateDiffMixin],
  created() {
    if (this.exam.length != 0) {
      this.findClosestExam();
      this.diffExamDday = this.getDateDiff(this.examDate);
    }
    if (this.assignment.length != 0) {
      this.findClosestAssignment();
      this.diffAssignmentDday = this.getDateDiff(this.assignmentDate);
    }
    EventBus.$on('updateSubjectInfo', () => {
      if (this.exam.length != 0) {
        this.findClosestExam();
        this.diffExamDday = this.getDateDiff(this.examDate);
      } else {
        this.examDate = null;
      }
      if (this.assignment.length != 0) {
        this.findClosestAssignment();
        this.diffAssignmentDday = this.getDateDiff(this.assignmentDate);
      } else {
        this.assignmentDate = null;
      }
      if (this.id == this.$store.state.nowSubjectId) {
        this.$store.commit('setEditSubjectExam', this.exam);
        this.$store.commit('setEditSubjectAssignment', this.assignment);
      }
    });
  },
  computed: {
    stringExamDday() {
      if (!this.diffExamDday || this.exam.length == 0) {
        return;
      } else if (Number(this.diffExamDday) == 0) {
        return 'D-day';
      } else if (Number(this.diffExamDday) < 0) {
        return '기한만료';
      } else {
        return 'D-' + this.diffExamDday;
      }
    },
    stringAssignmentDday() {
      if (!this.diffAssignmentDday || this.assignment.length == 0) {
        return;
      } else if (Number(this.diffAssignmentDday) == 0) {
        return 'D-day';
      } else if (Number(this.diffAssignmentDday) < 0) {
        return '기한만료';
      } else {
        return 'D-' + this.diffAssignmentDday;
      }
    },
  },
  methods: {
    openCard() {
      this.$store.commit('setEditSubjectId', this.id);
      this.$store.commit('setEditSubjectName', this.name);
      this.$store.commit('setEditSubjectDay', this.day);
      this.$store.commit('setEditSubjectExam', this.exam);
      this.$store.commit('setEditSubjectAssignment', this.assignment);
      this.$store.commit('setEditOpenCard', true);
      this.$store.commit('setEditBeforeCard', true);
    },
    findClosestExam() {
      const today = new Date();
      const sortedExams =
        this.exam.sort((a, b) =>
        Math.abs(new Date(a.deadline) - today) - Math.abs(new Date(b.deadline) - today));
      this.examDate = this.exam[this.exam.indexOf(sortedExams[0])].deadline;
    },
    findClosestAssignment() {
      const today = new Date();
      const sortedAssignments =
        this.assignment.sort((a, b) =>
        Math.abs(new Date(a.deadline) - today) - Math.abs(new Date(b.deadline) - today));
      this.assignmentDate = this.assignment[this.assignment.indexOf(sortedAssignments[0])].deadline;
    }
  },
  data() {
    return {
      diffExamDday: '',
      diffAssignmentDday: '',
      examDate: '',
      assignmentDate: '',
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .subject {
    & > .wrap {
      //background-color: #26272c;
      background-color: #393b43;
      & > .subject-wrap {
        & > .name {
          color: #d6d9e2;
        }
        & > .day {
          color: #919294;
        }
      }
      & > .dday-wrap {
        & > .dday {
          color: #919294;
        }
        & > .expired {
          color: #50515e;
        }
      }
    }
  }
}

.subject {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 8px;
  & > .wrap {
    transition: all 0.2s;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 12px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
    flex-grow: 1;
    &:active {
      transition: all 0.1s;
      transform: scale(0.98);
    }
    & > .line {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: #408cff;
    }
    & > .subject-wrap {
      width: 65vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      letter-spacing: -1px;
      text-align: left;
      word-break: break-all;
      & > .name {
        color: #3e3e3e;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -1px;
        &::placeholder {
          color: #a0a0a0;
        }
      }
      & > .day {
        color: #606060;
        font-size: 12px;
        font-weight: 500;
      }
    }
    & > .dday-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 2px;
      & > .dday {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -1px;
        &.exam {
          color: #408cff;
          &.deadline {
            color: #ff3030;
          }
        }
        &.assignment {
          color: #b8d4ff;
          &.deadline {
            color: #ff6b6b;
          }
        }
        &.expired {
          color: #50515e;
        }
      }
    }
  }
}
</style>
