<template>
  <div :class="{ 'add-subject-info': true, disappear: !$store.state.editSubjectInfo}">
    <div class="topbar">
      <img src="@/assets/new/close-icon.svg" @click="initSubjectInfo">
      <div class="title">
        {{ ($store.state.nowSubjectInfoType == 'exam') ? '시험' : '과제' }} {{ ($store.state.nowSubjectInfoId == -1) ? '추가하기' : '수정하기' }}
      </div>
      <DeleteButton
        :isDisabled="$store.state.nowSubjectInfoId == -1"
        @clickButton="deleteSubjectInfo"
      />
    </div>
    <LabelWrap label="기간">
      <div class="calendar-wrap">
        <div :class="{ 'calendar-input': true, 'check-input-date': deadline != ''}">
          <input type="date" v-model="deadline" :min="formattedDate" />
          <div :class="{ disabled: true, 'active-disabled': isClickedDeadlineButton }" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 9C3.67157 9 3 9.67157 3 10.5V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V10.5C21 9.67157 20.3284 9 19.5 9H4.5ZM6.75 12C6.33579 12 6 12.3358 6 12.75V14.25C6 14.6642 6.33579 15 6.75 15H8.25C8.66421 15 9 14.6642 9 14.25V12.75C9 12.3358 8.66421 12 8.25 12H6.75ZM10.5 12.75C10.5 12.3358 10.8358 12 11.25 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75V14.25C13.5 14.6642 13.1642 15 12.75 15H11.25C10.8358 15 10.5 14.6642 10.5 14.25V12.75ZM15.75 12C15.3358 12 15 12.3358 15 12.75V14.25C15 14.6642 15.3358 15 15.75 15H17.25C17.6642 15 18 14.6642 18 14.25V12.75C18 12.3358 17.6642 12 17.25 12H15.75Z" fill="#408CFF"/>
            <path d="M3 7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7V7Z" fill="#408CFF"/>
            <rect x="7" y="3" width="3" height="5" rx="1.5" fill="#408CFF"/>
            <rect x="14" y="3" width="3" height="5" rx="1.5" fill="#408CFF"/>
          </svg>
          {{ formattedDate }} ~&nbsp;<span :class="{ 'input-date': true, 'active-input-date': deadline != '' }">{{ deadline }}</span>
          <span :class="{ dday: true, 'appear-dday': deadline != '' }">
            D{{ dday }}
          </span>
        </div>
        <div
          @click="isClickedDeadlineButton = !isClickedDeadlineButton"
          :class="{
           'no-deadline-button': true,
           'active-button': isClickedDeadlineButton
          }"
        >
          기한없음
        </div>
      </div>
    </LabelWrap>
    <LabelWrap label="내용">
      <div class="input-wrap">
        <input
          type="text"
          v-model="content"
          placeholder="내용을 입력해주세요."
          onfocus="this.placeholder='';"
          onblur="this.placeholder='내용을 입력해주세요.'"
          @keyup.enter="addSubjectInfo(); $event.target.blur()"
        />
        <CharacterCounter
          :length="content.length"
          limit="30"
        />
      </div>
    </LabelWrap>
    <SubmitButton
      :title="($store.state.nowSubjectInfoId == -1) ? '작성 완료' : '수정 완료'"
      :isDisabled="content.length == 0 || (deadline.length == 0 && !isClickedDeadlineButton)"
      @clickButton="addSubjectInfo"
      :isGray="true"
      :onCard="true"
    />
  </div>
</template>

<script>
import LabelWrap from '@/components/LabelWrap.vue';
import DeleteButton from '@/components/Buttons/DeleteButton.vue';
import SubmitButton from '@/components/Buttons/SubmitButton.vue';
import CharacterCounter from '@/components/CharacterCounter.vue';
import axios from 'axios';
import { dateDiffMixin } from '@/mixins'
export default {
  name: 'AddSubjectInfo',
  mixins: [dateDiffMixin],
  components: {
    LabelWrap,
    DeleteButton,
    CharacterCounter,
    SubmitButton
  },
  watch: {
    deadline: function() {
      this.dday = this.getDday(this.deadline);
    },
    '$store.state.editSubjectInfo': function() {
      if(this.$store.state.editSubjectInfo && this.$store.state.nowSubjectInfoContent.length > 0) {
        this.content = this.$store.state.nowSubjectInfoContent;
        if(this.$store.state.nowSubjectInfoDeadline) {
          this.deadline = this.$store.state.nowSubjectInfoDeadline;
        } else {
          this.isClickedDeadlineButton = true;
        }
      }
    },
    content: function() {
      if(this.content.length > 30) {
        this.content = this.content.slice(0, 30);
      }
    },
  },
  created() {
    this.formattedDate = this.getToday();
    this.dday = this.getDday(this.deadline);
  },
  methods: {
    async addSubjectInfo() {
      if(this.content.length > 0 && (this.deadline.length > 0 || this.isClickedDeadlineButton)) {
        if(this.$store.state.nowSubjectInfoId == -1) {
          const url = "https://www.iflab.run/api/subject/info";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.post(url, {
            type: this.$store.state.nowSubjectInfoType,
            subject_name: this.$store.state.nowSubjectName,
            content: this.content,
            deadline: this.isClickedDeadlineButton ? null : this.deadline,
          }, header);
          this.$emit('subjectInfoNotification', this.$store.state.nowSubjectInfoType, '추가');
        } else {
          const url = "https://www.iflab.run/api/subject/info";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.patch(url, {
            info_id: this.$store.state.nowSubjectInfoId,
            type: this.$store.state.nowSubjectInfoType,
            subject_name: this.$store.state.nowSubjectName,
            content: this.content,
            deadline: this.isClickedDeadlineButton ? null : this.deadline,
          }, header);
          this.$emit('subjectInfoNotification', this.$store.state.nowSubjectInfoType, '수정');
        }
        this.initSubjectInfo();
      }
    },
    async deleteSubjectInfo() {
      const url = "https://www.iflab.run/api/subject/info";
      const token = localStorage.getItem("token")
      await axios.delete(url, {
        headers: { "Authorization": token },
        data: {
          type: this.$store.state.nowSubjectInfoType,
          info_id: this.$store.state.nowSubjectInfoId,
        }
      });
      this.$emit('subjectInfoNotification', this.$store.state.nowSubjectInfoType, '삭제');
      this.initSubjectInfo();
    },
    initSubjectInfo() {
      this.$store.commit('setEditSubjectInfo', false);
      this.$store.commit('setEditOpenCard', true);
      setTimeout(() => {
        this.content = '';
        this.deadline = '';
        this.dday = '';
        this.isClickedDeadlineButton = false;
        this.$store.commit('setEditSubjectInfoId', -1);
        this.$store.commit('setEditSubjectInfoContent', '');
        this.$store.commit('setEditSubjectInfoDeadline', '');
      }, 300);
    },
  },
  data() {
    return {
      content: '',
      deadline: '',
      dday: '',
      formattedDate: '',
      isClickedDeadlineButton: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .add-subject-info {
    background-color: #202020;
    & > .topbar {
      background-color: #1c1c1c;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
  }
  & .calendar-wrap {
    & > .calendar-input {
      & > .dday {
        color: #202020;
      }
      & > .appear-dday {
        color: #ffffff;
      }
    }
  }
  & .input-wrap {
    background-color: #3e3e3e;
    -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
    & > input {
      background-color: #3e3e3e;
      color: #f0f0f0;
      &::placeholder {
        color: #606060;
      }
    }
  }
}

.add-subject-info {
  transition: all 0.3s;
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 24px 24px 0 0;
  transform: translateY(0%);
  & > .topbar {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 24px 24px 0 0;

    box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

    & > .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: #408cff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.calendar-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  & > .calendar-input {
    transition: all 0.3s;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    margin-right: 8px;
    border: 2px solid #408cff;
    border-radius: 4px;
    flex-grow: 1;

    color: #408cff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -1px;
    & > input {
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border: none;
      outline: none;
      opacity: 0;

      -webkit-appearance: none;
      -moz-appearance: none;
    }
    & > .disabled {
      transition: all 0.2s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000000;
      border-radius: 2px;
    }
    & > .active-disabled {
      z-index: 200;
      transition: all 0.1s;
      background-color: #00000080;
    }
    & > svg {
      transition: all 0.3s;
      margin-right: 8px;
    }
    & > .input-date {
      flex-shrink: 0;
      transition: all 0.3s;
      color: #ffffff;
      opacity: 0;
    }
    & > .active-input-date {
      transition: all 0.3s;
      opacity: 1;
    }
    & > .dday {
      flex-grow: 1;
      transition: all 0.3s;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.2px;
      text-align: right;
    }
    & > .appear-dday {
      transition: all 0.3s;
      opacity: 0.5;
    }
  }
  & > .check-input-date {
    transition: all 0.3s;
    color: #ffffff;
    background-color: #408cff;
    & > svg {
      & > path {
        transition: all 0.3s;
        fill: #ffffff;
      }
      & > rect {
        transition: all 0.3s;
        fill: #ffffff;
      }
    }
  }
  & > .no-deadline-button {
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 8px;
    border: 2px solid #408cff;
    border-radius: 4px;

    color: #408cff;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: -1px;
    -webkit-tap-highlight-color: transparent;
  }
  & > .active-button {
    transition: all 0.1s;
    background-color: #408cff;
    color: #ffffff;
  }
}
.input-wrap {
  display: flex;
  position: relative;

  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;

  & > input {
    -webkit-tap-highlight-color: transparent;

    padding: 16px 48px 16px 8px;
    border: none;
    outline: none;

    color: #3e3e3e;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -1px;

    flex-grow: 1;

    &::placeholder {
      color: #cecece;
    }
  }
}
.disappear {
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  transform: translateY(30%);
}
</style>
