var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    category: true,
    'color-category': true,
    'postit-category': _vm.theme == 'postit',
    'd-day': _vm.stringDday == 'D-day'
  }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"category-name",style:(_vm.DdayStyle)},[_vm._v(" "+_vm._s(_vm.stringDday)+" ")]),_c('div',{staticClass:"line",style:(_vm.DdayStyle)}),_c('div',{class:{
        'add-todo-button': true,
        'postit-add-todo-button': _vm.theme == 'postit',
        'd-day': _vm.stringDday == 'D-day' && _vm.theme == 'postit'
      },on:{"click":_vm.addTodo}},[_c('img',{attrs:{"src":require("@/assets/new/white-plus-icon.svg")}})])]),_vm._l((_vm.todos),function(category){return _c('div',{key:category.id,staticClass:"todo-wrap"},_vm._l((category.todos),function(data){return _c('TodayTodo',{key:data.id.toString(),attrs:{"id":data.id,"categoryColor":category.categoryColor,"content":data.content,"deadline":data.deadline,"isDone":data.isDone},on:{"clickDoneTodo":_vm.doneTodo,"clickMoreTodo":function($event){return _vm.moreTodo(category.categoryName, category.id)}}})}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }