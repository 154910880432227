<template>
  <div :class="{ 'subject-info': true, 'type-exam': type == 'exam' }"
    @click="modifySubjectInfo"
  >
    <div class="wrap">
      <div class="content">{{ subjectInfo.content }}</div>
      <div v-if="subjectInfo.deadline" class="content-deadline">
        마감: {{ subjectInfo.deadline }}
      </div>
      <div v-else class="content-deadline">마감 없음</div>
    </div>
    <div class="dday">
      {{ stringDday }}
    </div>
  </div>
</template>

<script>
import { dateDiffMixin } from '@/mixins'
export default {
  name: 'SubjectInfo',
  props: {
    type: {
      type: String,
      default: '',
    },
    subjectInfo: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [dateDiffMixin],
  created() {
    this.diffDday = this.getDateDiff(this.subjectInfo.deadline);
  },
  watch: {
    subjectInfo: {
      handler() {
        this.diffDday = this.getDateDiff(this.subjectInfo.deadline);
      },
      deep: true,
    },
  },
  computed: {
    stringDday() {
      if(!this.diffDday) {
        return;
      } else if(Number(this.diffDday) == 0) {
        return 'D-Day';
      } else if(Number(this.diffDday) < 0) {
        return '기한만료';
      } else {
        return 'D-' + this.diffDday;
      }
    },
  },
  methods: {
    modifySubjectInfo() {
      this.$store.commit('setEditSubjectInfo', true);
      this.$store.commit('setEditSubjectInfoType', this.type);
      this.$store.commit('setEditOpenCard', false);
      this.$store.commit('setEditSubjectInfoId', this.subjectInfo.id);
      this.$store.commit('setEditSubjectInfoContent', this.subjectInfo.content);
      this.$store.commit('setEditSubjectInfoDeadline', this.subjectInfo.deadline);
    },
  },
  data() {
    return {
      diffDday: '',
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  .subject-info {
    background-color: #303030;
    & > .wrap {
      & > .content {
        color: #f0f0f0;
      }
      & > .content-deadline {
        color: #a0a0a0;
      }
    }
  }
  .type-exam {
    background-color: #408cff;
    & > .wrap {
      & > .content {
        color: #ffffff;
      }
      & > .content-deadline {
        color: #c6ddff;
      }
    }
  }
}

.subject-info {
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  border-radius: 8px;
  background-color: #f8f8f8;
  & > .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    & > .content {
      font-size: 14px;
      font-weight: bold;
      color: #3e3e3e;
      letter-spacing: -0.5px;
    }
    & > .content-deadline {
      font-size: 12px;
      font-weight: 500;
      color: #a0a0a0;
      letter-spacing: -0.5px;
    }
  }
  & > .dday {
    font-size: 16px;
    font-weight: bold;
    color: #408cff;
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.97);
  }
}

.type-exam {
  background-color: #408cff;
  & > .wrap {
    & > .content {
      color: #ffffff;
    }
    & > .content-deadline {
      color: #c6ddff;
    }
  }
  & > .dday {
    color: #ffffff;
  }
}
</style>
