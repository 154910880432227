<template>
  <div
    :class="{ button: true, 'type-skyblue': buttonType == 'skyblue' }"
    @click="emitClickButton"
  >
    <svg v-if="buttonType == 'skyblue'" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H3V7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7V5H7C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3H5V1Z" fill="#408CFF"/>
    </svg>
    <svg v-else width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H3V7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7V5H7C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3H5V1Z" fill="white"/>
    </svg>
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    content: {
      type: String,
      default: "",
    },
    buttonType: {
      type: String,
      default: "default",
    },
  },
  methods: {
    emitClickButton() {
      this.$emit("clickButton");
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .button {
    color: #26272c;
    & > svg {
      & > path {
        fill: #26272c;
      }
    }
    &:hover {
      transition: all 0.1s;
      filter: brightness(1.1);
    }
    &:active {
      transition: all 0.1s;
      filter: brightness(1.2);
    }
  }
}
.button {
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  padding: 8px;
  background-color: #408CFF;
  border-radius: 8px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  cursor: pointer;
  user-select: none;
  &:hover {
    transition: all 0.1s;
    filter: brightness(0.9);
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.9);
    filter: brightness(0.7);
  }
  & > svg {
    transition: all 0.3s;
    fill: #1c1c1c;
    margin-right: 4px;
  }
}

.type-skyblue {
  color: #408cff;
  background-color: #cfe2ff;
}
</style>
