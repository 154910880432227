var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-container"},[_c('div',{staticClass:"topbar"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.nowMonth)+" ")])]),_c('div',{class:{ calendar: true, dragging: _vm.dragX != 0, },on:{"mousedown":_vm.startDrag,"mouseup":_vm.endDrag}},_vm._l((_vm.calendarArray),function(calendar,i){return _c('div',{key:i,class:{ 'calendar-wrap': true, no: _vm.isClickedDate,'calendar-left': !_vm.dragging && _vm.changeMonth == -1, 'calendar-right': !_vm.dragging && _vm.changeMonth == 1  },style:({
        transform: ("translateX(" + _vm.dragX + "px)"),
        opacity: (_vm.dragX >= 0 ? 1-_vm.dragX/60 : 1+_vm.dragX/60)
      })},[(_vm.isLoading)?_c('Loading'):_vm._l((calendar),function(date,i){return _c('div',{key:i,class:{
          date: true,
          'today-date':
            date.date == new Date().getDate() && date.month == (new Date().getMonth() + 1),
          'clicked-date': date.date == _vm.nowClickedDate.date && date.month == _vm.nowClickedDate.month,
          'not-now-month': date.notNowMonth
        }},[_c('div',{staticClass:"date-text"},[_vm._v(_vm._s(date.date))]),_c('div',{staticClass:"event"},_vm._l((date.events),function(event,i){return _c('div',{key:event.id,class:{ 'event-item': true, 'hide-event-item': _vm.isClickedDate },style:({ 'background-color': event.color })},[(i < 4)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(event.content)+" ")]):_vm._e()])}),0)])})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }