<template>
  <Loading v-if="isLoading" />
  <div v-else class="delete-account">
    <BlackScreen :isShow="isShowModal" />
    <TopBar
      title="회원 탈퇴"
      @clickButton="closeDeleteAccount"
    />
    <div class="message-wrap">
      <div class="message">
        <b>{{ nickname }}</b>님... 이대로 이별인가요?
      </div>
      <div class="message small">
        계정을 삭제하려는 이유가 궁금해요.
      </div>
    </div>
    <div class="reason-wrap">
      <div 
       v-for="reason in reasons"
       :key="reason.id"
       :class="{ reason: true, 'selected-reason': reason.isSelected }"
       @click="selectReason(reason.id)"
      >
        {{ reason.title }}
      </div>
      <textarea
        v-model="customReason"
        :class="{ 'custom-reason': true,
                 'appear-custom-reason': reasons[1].isSelected || reasons[3].isSelected || reasons[4].isSelected }"
        id="customReason"
        placeholder="짧게나마 의견을 남겨주세요!"
        maxlength="300"
      />
    </div>
    <div class="caution-wrap">
      <div class="title">계정 삭제 전 주의사항</div>
      <div class="line" />
      <div class="content-wrap">
        <div class="content">
          - miruzima 계정 삭제 후 7일간 재가입이 불가능합니다.
        </div>
        <div class="content">
          - miruzima 계정 삭제 시 계정의 모든 정보는 삭제되며 재가입 시에도 복구할 수 없습니다.
        </div>
      </div>
    </div>
    <CheckBox
      :isChecked="isChecked"
      @check="check"
      content="주의사항을 모두 확인하였으며 이에 동의합니다."
      :style="{ marginBottom: (reasons[1].isSelected || reasons[3].isSelected || reasons[4].isSelected) ? '100px' : '0' }"
    />
    <SubmitButton
      title="회원 탈퇴"
      :isDisabled="reasons.filter(reason => reason.isSelected).length === 0 || !isChecked"
      @clickButton="openModal"
    />
    <Modal
      title="정말 회원탈퇴를 하시겠습니까?"
      content="회원탈퇴를 하시면 miruzima의 모든 서비스를 이용하실 수 없습니다."
      cancelText="탈퇴"
      confirmText="취소"
      :isShowModal="isShowModal"
      @cancel="closeModal"
      @confirm="deleteAccount"
    />
  </div>
</template>

<script>
const reasons = [
  {
    id: 0,
    title: "쓰지 않는 서비스예요.",
    isSelected: false,
  },
  {
    id: 1,
    title: "원하는 기능이 없어요. (ex 친구 기능)",
    isSelected: false,
  },
  {
    id: 2,
    title: "오류가 많아서 쓸 수가 없어요.",
    isSelected: false,
  },
  {
    id: 3,
    title: "사용하기에 불편함이 있어요.",
    isSelected: false,
  },
  {
    id: 4,
    title: "기타",
    isSelected: false,
  },
];

import BlackScreen from "@/components/BlackScreen.vue";
import Loading from "@/components/Loading.vue";
import TopBar from "@/components/Bar/SecondaryTopBar";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import Modal from "@/components/Alerts/Modal.vue";
import CheckBox from "@/components/Forms/CheckBox.vue";
import axios from "axios";
export default {
  name: "DeleteAccount",
  components: {
    BlackScreen, Loading, TopBar, SubmitButton, Modal, CheckBox,
  },
  data() {
    return {
      nickname: this.$store.state.nickname,
      reasons,
      customReason: "",
      isShowModal: false,
      isChecked: false,
      isLoading: false,
    };
  },
  async mounted() {
    if (this.nickname.length == 0) {
      await this.loadFromServer();
    }
  },
  methods: {
    async loadFromServer() {
      if(this.nickname.length == 0) {
        this.isLoading = true;
      }
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/user/info";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        const response = await axios.get(url, header);
        this.nickname = response.data.nickname;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
    closeDeleteAccount() {
      this.$router.go(-1);
    },
    selectReason(index) {
      this.reasons[index].isSelected = !this.reasons[index].isSelected;
    },
    async deleteAccount() {
      // reasons들의 isSelected값들을 서버로 보내기
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/user/delete";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        const reasons = Object.values(this.reasons.map(reason => reason.isSelected));
        await axios.post(url, {
          'reasons': reasons,
          'customReason': this.customReason,
      }, header).then(() => {
          this.$store.commit('setToken', '');
          this.$store.commit('setIsSignIn', false);
          this.$store.commit('setNickname', '');
          this.$store.commit('setCreatedAt', '');
          this.$store.commit('setAllTodolist', []);
          this.$store.commit('setEditSubjectArray', []);
          localStorage.removeItem("token");
          localStorage.removeItem("isSignIn");

          setTimeout(() => {
            this.$router.push("/signin");
          }, 100);
          this.reasons.forEach(reason => reason.isSelected = false);
          this.customReason = "";
        });
      }
    },
    openModal() {
      if(this.reasons.filter(reason => reason.isSelected).length !== 0 && this.isChecked) {
        this.isShowModal = true;
      }
    },
    closeModal() {
      setTimeout(() => {
        this.isShowModal = false;
      }, 100);
    },
    check() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .delete-account {
    background-color: #1c1c1c;
    & > .message-wrap {
      & > .message {
        color: #cecece;
      }
      & > .small {
        color: #cecece;
      }
    }
    & > .reason-wrap {
      & > .reason {
        background-color: #333333;
      }
      & > .selected-reason {
        background-color: #408cff;
        color: #ffffff;
      }
      & > .custom-reason {
        background-color: #333333;
        color: #cecece;
        &::placeholder {
          color: #808080;
        }
      }
    }
    & > .caution-wrap {
      & > .title {
        color: #606060;
      }
      & > .line {
        background-color: #333333;
      }
      & > .content-wrap {
        & > .content {
          color: #606060;
        }
      }
    }
  }
}
.delete-account {
  z-index: 300;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  min-height: calc( 100vh + 80px );
  & > .message-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 16px 32px 16px;
    & > .message {
      color: #606060;
      font-size: 20px;
      letter-spacing: -1px;
      text-align: left;
      word-break: keep-all;
    }
    & > .small {
      font-size: 16px;
    }
  }
  & > .reason-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 16px 32px 16px;
    & > .reason {
      transition: all 0.2s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;
      padding: 12px;
      border-radius: 4px;
      background-color: #ffffff;

      color: #a0a0a0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -2px;
      user-select: none;
      &:active {
        transition: all 0.1s;
        transform: scale(0.98);
        filter: brightness(0.9);
      }
    }
    & > .selected-reason {
      transition: all 0.2s;
      background-color: #408cff;
      color: #ffffff;
    }
    & > .custom-reason { 
      transition: all 0.2s;
      padding: 16px 12px;
      margin-bottom: -60px;
      border: none;
      border-radius: 4px;
      outline: none;
      resize: none;

      color: #606060;
      font-size: 14px;
      font-weight: 500;
      font-family: "Noto Sans KR", sans-serif;
      letter-spacing: -1px;
      
      user-select: none;
      pointer-events: none;
      transform: scaleY(0);
      transform-origin: top;
      opacity: 0;
      &::placeholder {
        color: #a0a0a0;
      }
    }
    & > .appear-custom-reason {
      transition: all 0.2s;
      margin-bottom: 0px;
      pointer-events: auto;
      transform: scaleY(1);
      background-color: #ffffff;
      opacity: 1;
    }
  }
  & > .caution-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px 16px 16px;
    & > .title {
      color: #a0a0a0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
      margin-bottom: 8px;
    }
    & > .line {
      width: 100%;
      height: 1px;
      background-color: #e3e3e3;
      margin-bottom: 12px;
    }
    & > .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & > .content {
        color: #a0a0a0;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
        text-align: left;
        word-break: keep-all;
      }
    }
  }
}
</style>
