<template>
  <div
    class="todolist"
    @touchstart="startDrag"
    @touchmove="handleDrag"
    @touchend="endDrag"
  >
    <BackgroundScreen />
    <BlackScreen :isShow="isShowPushNotificationPermission" />
    <TopBar
      :today="isToday"
      title="지금 할 일"
      buttonContent="카테고리"
      @changeShow="changeShow"
    />
    <Loading v-if="isLoading" />
    <Empty
      v-else-if="(!isToday && allTodolist.length == 0) || (isToday && todayTodolist.length == 0)"
      :title="(isToday) ? '오늘 할 일을 추가해보세요.' : '카테고리를 추가해보세요.'"
      :isExist="(!isToday && allTodolist.length == 0) || (isToday && todayTodolist.length == 0)"
    />
    <Category
      v-else-if="!isToday"
      v-for="data in allTodolist"
      :key="data.id"
      :categoryId="data.id"
      :categoryName="data.categoryName"
      :categoryColor="data.categoryColor"
      :todos="data.todos"
      @todoNotification="todoNotification"
      :style="{ opacity: 1 - dragY / 30 }"
    />
    <TodayCategory
      v-else
      v-for="data in todayTodolist"
      :key="data.id"
      :deadline="data.deadline"
      :todos="data.todos"
      @todoNotification="todoNotification"
      :style="{ opacity: 1 - dragY / 30 }"
    />
    <AddTodo @todoNotification="todoNotification" />
    <AddCategory @categoryNotification="categoryNotification" />
    <Notification
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
    <PermitModal
      :isShowModal="isShowPushNotificationPermission"
      @permit="closePushNotificationPermission"
    />
  </div>
</template>

<script>
import BackgroundScreen from "@/components/BackgroundScreen.vue";
import BlackScreen from "@/components/BlackScreen.vue";
import Loading from "@/components/Loading.vue";
import Empty from "@/components/Empty.vue";
import TopBar from "@/components/Bar/TopBar";
import Category from "@/components/Todolist/Category.vue";
import TodayCategory from "@/components/Todolist/TodayCategory.vue";
import AddCategory from "@/components/Todolist/AddCategory.vue";
import AddTodo from "@/components/Todolist/AddTodo.vue";
import Notification from "@/components/Alerts/Notification.vue";
import PermitModal from "@/components/PermitModal.vue";
import { storageMixin, dateDiffMixin } from '@/mixins'
import { TodoAPI, UserAPI } from '@/api';

export default {
  name: "Todolist",
  components: {
    BackgroundScreen,
    BlackScreen,
    Loading,
    Empty,
    TopBar,
    Category,
    TodayCategory,
    AddCategory,
    AddTodo,
    Notification,
    PermitModal
  },
  mixins: [storageMixin, dateDiffMixin],
  computed: {
    allTodolist: {
      get() {
        return this.$store.state.allTodolist;
      },
      set(value) {
        this.$store.commit('setAllTodolist', value);
      }
    },
    todayTodolist: {
      get() {
        return this.$store.state.todayTodolist;
      },
      set(value) {
        this.$store.commit('setTodayTodolist', value);
      }
    }
  },
  created() {
    if (window.Notification.permission == 'default') {
      setTimeout(() => {
        this.isShowPushNotificationPermission = true;
      }, 1500);
    }
  },
  async mounted() {
    window.addEventListener('resize', this.checkWindowSize)
    this.checkWindowSize()
    if (this.allTodolist.length == 0 || this.todayTodolist.length == 0) {
      await this.loadFromServer();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    async categoryNotification(mode) {
      await this.loadFromServer();
      this.showNotification('카테고리가 ' + mode + '되었습니다.');
    },

    async todoNotification(mode) {
      this.showNotification('할 일이 ' + mode + '되었습니다.');
      await this.loadFromServer();
    },

    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },

    async loadFromServer() {
      try {
        if ((this.allTodolist.length == 0 && !this.isToday)
          || (this.todayTodolist.length == 0 && this.isToday)) {
          this.isLoading = true;
        }
        if (localStorage.getItem("token")) {
          if (!this.isToday) {
            const response = await TodoAPI.getTodoList();
            this.allTodolist = response.data;
          } else {
            const response = await TodoAPI.getTodayTodoList();
            this.todayTodolist = response.data;
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          const response = await UserAPI.getTodoTheme();
          this.$store.commit('setTodolistTheme', response.data.todolist_theme);
        } else if(window.innerWidth >= 800) {
          this.$router.push("/web/signin");
        } else {
          this.$router.push("/signin");
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async changeShow() {
      this.isToday = !this.isToday;
      await this.loadFromServer();
    },

    checkWindowSize() {
      if (window.innerWidth >= 800) {
        this.$router.push("/web");
      }
    },
    closePushNotificationPermission() {
      this.isShowPushNotificationPermission = false;
    },
    startDrag(event) {
      this.dragging = true;
      this.startY = event.touches[0].clientY;
    },
    handleDrag(event) {

      if (!this.dragging || window.scrollY > 0) return;
      
      const currentY = event.touches[0].clientY;
      const distanceY = currentY - this.startY;

      this.dragY = distanceY / 10;
      if (distanceY >= 300) { // 충분히 드래그 되었을 때
        this.changeTodo = true;
      }
    },
    endDrag() {
      this.dragging = false;
      this.dragY = 0;
      if (this.changeTodo) {
        this.changeShow();
      }
      setTimeout(() => {
        this.changeTodo = false;
      }, 500);
    },
  },
  data() {
    return {
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
      isMobile: window.innerWidth < 800,
      isLoading: false,
      isToday: true,
      isShowPushNotificationPermission: false,

      dragging: false,
      startY: 0,
      dragY: 0,
      changeTodo: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .todolist {
    //background-color: #14171b;
    background-color: #26272c;
  }
}

.todolist {
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  min-height: 100vh;
  padding-bottom: 80px;
}
</style>
