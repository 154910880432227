<template>
  <div class="change-nickname">
    <BlackScreen :isShow="isDone" />
    <TopBar
      title="닉네임 변경"
      @clickButton="closeNickname"
    />
    <div class="wrap">
      <Label label="닉네임" />
      <Input
        type="text"
        placeholder="2~8자의 한글, 영문자, 숫자"
        v-model="inputNickname"
        @focusInput="isDisabled = false;"
        @pressEnter="ChangeNickname"
      />
    </div>
    <SubmitButton
      title="닉네임 변경"
      :isDisabled="!inputNickname || isDisabled"
      @clickButton="ChangeNickname"
    />
    <Notification
      type="error"
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
    <div :class="{ result: true, 'appear-result': isDone }">
      <div class="icon">
        <img src="@/assets/new/change-nickname-icon.svg" />
      </div>
      <div class="text">닉네임 변경이 완료되었어요!</div>
      <SubmitButton
        title="이전 페이지로 돌아가기"
        :isDisabled="false"
        @clickButton="closeNickname"
      />
    </div>
  </div>
</template>


<script>
import TopBar from "@/components/Bar/SecondaryTopBar";
import BlackScreen from "@/components/BlackScreen.vue";
import Label from "@/components/Label.vue";
import Notification from "@/components/Alerts/Notification.vue";
import SubmitButton from "@/components/Buttons/SubmitButton.vue";
import Input from "@/components/Forms/Input.vue";
import axios from "axios";
export default {
  name: "ChangeNickname",
  components: {
    TopBar, BlackScreen, Label, Notification, SubmitButton, Input
  },
  data() {
    return {
      isDone: false,
      inputNickname: "",
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
    };
  },
  methods: {
    closeNickname() {
      this.$router.go(-1);
    },
    async ChangeNickname() {
      if(this.inputNickname.length > 0 && !this.isDisabled) {
        const url = "https://www.iflab.run/api/user/nickname";
        const token = localStorage.getItem("token")
        const header = { headers: { "Authorization": token } };
        await axios.patch(url, {
            nickname: this.inputNickname,
        }, header).catch((error) => {
          if (error.response.status === 400) {
            this.showNotification("유저 정보가 존재하지 않습니다.");
            this.isDisabled = true;
          } else if (error.response.status === 401) {
            this.showNotification("닉네임 형식이 올바르지 않습니다.");
            this.isDisabled = true;
          }
        }).then((response) => {
          if (response.status === 200) {
            this.isDone = true;
            this.$store.commit('setNickname', this.inputNickname);
          }
        });
      }
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .change-nickname {
    background-color: #1c1c1c;
    & > .wrap {
      & > .input-wrap {
        background-color: #3e3e3e;
        -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        & > input {
          background-color: #3e3e3e;
          color: #f0f0f0;
          &::placeholder {
            color: #606060;
          }
        }
      }
    }
    & > .result {
      background-color: #1c1c1c;
    }
  }
}

.change-nickname {
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  min-height: 100vh;
  position: relative;
  & > .wrap {
    display: flex;
    flex-direction: column;
    margin: 24px 16px 16px 16px;
    & > .input-wrap {
      display: flex;

      background-color: #ffffff;
      -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      border-radius: 4px;
      overflow: hidden;
      margin-top: 8px;

      & > input {
        -webkit-tap-highlight-color: transparent;

        padding: 16px 8px;
        border: none;
        outline: none;

        color: #3e3e3e;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -1px;

        flex-grow: 1;

        &::placeholder {
          color: #cecece;
        }
      }
    }
  }
}

.result {
  transition: all 0.3s;
  transform: translateX(100%);
  opacity: 0;
  z-index: 600;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  & > .icon {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #b8d4ff40;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    & > img {
      transform-origin: center;
      transform: scale(0.8);
      opacity: 0;
      width: 48px;
      height: 48px;
    }
  }

  & > .text {
    color: #408cff00;
    font-size: 24px;
    font-weight: bold;
  }
}

.appear-result {
  transition: all 0.5s;
  transform: translateX(0);
  opacity: 1;
  & > .icon {
    animation: appear 0.3s 1s forwards;
    transition: all 1.2s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    width: 180px;
    height: 180px;
    background-color: #b8d4ff;
    & > img {
      transition: all 1.2s cubic-bezier(0.55, 0.06, 0.68, 0.19);
      opacity: 1;
      width: 96px;
      height: 96px;
      animation: change 0.3s 1s forwards cubic-bezier(0.09, 0.73, 0.53, 1.02);

    }
  }
  & > .text {
    transition: all 1s 1.5s;
    color: #408cff;
  }
}

@keyframes change {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
</style>
