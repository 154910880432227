<template>
  <div class="label">
    <div class="title">{{ label }}</div>
    <div class="line" />
  </div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .label {
    & > .title {
      color: #606060;
    }
    & > .line {
      background-color: #3e3e3e;
    }
  }
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > .title {
    color: #a0a0a0;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  & > .line {
    flex-grow: 1;
    height: 1px;
    margin-left: 8px;
    background-color: #e3e3e3;
  }
}
</style>
