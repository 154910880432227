<template>
  <div class="navbar">
    <div class="navbar-item" @click="clickNavbar('')">
      <img v-if="nowNavbar == 'Todolist'" src="@/assets/new/active-todolist-icon.svg">
      <img v-else src="@/assets/new/todolist-icon.svg">
    </div>
    <div class="navbar-item" @click="clickNavbar('calendar')">
      <img v-if="nowNavbar == 'Calendar'" src="@/assets/new/active-calendar-icon.svg">
      <img v-else src="@/assets/new/calendar-icon.svg">
    </div>
    <!--div class="navbar-item" @click="clickNavbar('history')">
      <img v-if="nowNavbar == 'History'" src="@/assets/new/active-history-icon.svg">
      <img v-else src="@/assets/new/history-icon.svg">
    </div-->
    <div class="navbar-item" @click="clickNavbar('timetable')">
      <img v-if="nowNavbar == 'Timetable'" src="@/assets/new/active-timetable-icon.svg">
      <img v-else src="@/assets/new/timetable-icon.svg">
    </div>
    <div class="navbar-item" @click="clickNavbar('setting')">
      <img v-if="nowNavbar == 'Setting'" src="@/assets/new/active-setting-icon.svg">
      <img v-else src="@/assets/new/setting-icon.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  created() {
    this.nowNavbar = this.$route.name;
  },
  watch: {
    $route() {
      this.nowNavbar = this.$route.name;
    }
  },
  methods: {
    clickNavbar(navbar) {
      if(this.$route.fullPath == '/'+navbar) return;
      this.$router.push('/'+navbar);
      this.nowNavbar = this.$route.name;
      window.scrollTo(0, 0);
    },
  },
  data() {
    return {
      nowNavbar: 'Todolist',
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .navbar {
    transition: all 0.3s;
    background-color: #1c1c1c;
    box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
  }
}
.navbar {
  transition: all 0.3s;
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 8px 48px;
  border-radius: 16px 16px 0 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0px -4px 16px rgba(96, 96, 96, 0.25);
  box-shadow: 0px -4px 16px rgba(96, 96, 96, 0.25);

  & > .navbar-item {
    display: flex;
    -webkit-tap-highlight-color: transparent;
    transition: scale 0.2s, transform 0.5s;
    user-select: none;
    scale: 1 1;
    transform: scaleX(1) scaleY(1);
    & > img {
      width: 32px;
      height: 32px;
    }
    &:active {
      transition: all 0.1s;
      -webkit-tap-highlight-color: transparent;
      scale: 1 0.75;
      transform: scaleX(1.3) scaleY(1);
    }
  }
}

@media screen and (min-height: 750px) {
  .navbar {
    padding: 8px 48px 32px 48px;
  }
}
</style>
