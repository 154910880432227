<template>
  <div :class="{ 'result-page': true, 'appear-result-page': isDone }">
    <div class="icon">
      <div v-if="type == 'password'" class="locker">
        <img class="locker-up" src="@/assets/new/locker-up-icon.svg" />
        <img class="locker-down" src="@/assets/new/locker-down-icon.svg" />
      </div>
      <img
        v-else-if="type == 'email'"
        src="@/assets/new/change-email-icon.svg"
      />
    </div>
    <div class="text">{{ changeText }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ChangeResultPage",
  props: {
    type: {
      type: String,
      default: ""
    },
    isDone: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeText() {
      if (this.type == "password") {
        return "비밀번호 변경이 완료되었어요!";
      } else if (this.type == "email") {
        return "이메일 변경이 완료되었어요!";
      } else {
        return "변경이 완료되었어요!";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .result-page {
    background-color: #1c1c1c;
  }
}

.result-page {
  transition: all 0.3s;
  transform: translateX(100%);
  opacity: 0;
  z-index: 600;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;


  & > .icon {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #b8d4ff40;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    & > img {
      transform-origin: center;
      transform: scale(0.8);
      opacity: 0;
      width: 48px;
      height: 48px;
    }
  }

  & > .text {
    color: #408cff00;
    font-size: 24px;
    font-weight: bold;
    transform: translateY(8px);
  }
}

.appear-result-page {
  transition: all 0.5s;
  transform: translateX(0);
  opacity: 1;
  & > .icon {
    animation: appear 0.3s 1s forwards;
    transition: all 1.3s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    width: 180px;
    height: 180px;
    background-color: #b8d4ff;
    & > .locker {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 180px;
      height: 180px;
      & > .locker-up {
        animation: locker-up 0.3s 1s forwards cubic-bezier(0.18, 0.89, 0.5, 1.8);
      }
      & > .locker-down {
        animation: locker-down 0.3s 1s forwards cubic-bezier(0.09, 0.73, 0.53, 1.02);
      }
    }
    & > img {
      transition: all 1.2s cubic-bezier(0.55, 0.06, 0.68, 0.19);
      opacity: 1;
      width: 96px;
      height: 96px;
      animation: show-icon 0.3s 1s forwards cubic-bezier(0.09, 0.73, 0.53, 1.02);
    }
  }
  & > .text {
    transition: all 1s 1.5s;
    color: #408cff;
    transform: translateY(0px);
  }
}

@keyframes show-icon {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes locker-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(18px);
  }
}

@keyframes locker-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-6px);
  }
}

@keyframes appear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
</style>
