<template>
  <SecondaryPage
    title="알림 설정"
    type="password"
  >
    <SettingOption
      :noActive="true"
      title="알림 허용"
      type="toggle"
      :isOn="pushPermission"
      @clickToggle="clickPush"
      style="padding-top: 24px;"
    />
    <div class="line" />
    <SettingOption
      :noActive="true"
      title="아침 8시: 하루 일정 미리 알림"
      subtitle="아침 8시마다 오늘 할 일에 대한 알림을 보내요."
      type="toggle"
      :isOn="morningPush"
      @clickToggle="clickMorningPush"
    />
    <SettingOption
      :noActive="true"
      title="저녁 10시: 하루 일정 마감 알림"
      subtitle="저녁 10시마다 끝내지 못한 알림을 보내요."
      type="toggle" 
      :isOn="nightPush"
      @clickToggle="clickNightPush"
    />
  </SecondaryPage>
</template>

<script>
import SecondaryPage from "@/components/SecondaryPage.vue";
import SettingOption from "@/components/Setting/SettingOption.vue";
export default {
  name: "Push",
  components: {
    SecondaryPage,
    SettingOption,
  },
  data() {
    return {
      pushPermission: false,
      morningPush: false,
      nightPush: false,
    }
  },
  methods: {
    clickPush() {
      this.pushPermission = !this.pushPermission;
    },
    clickMorningPush() {
      this.morningPush = !this.morningPush;
    },
    clickNightPush() {
      this.nightPush = !this.nightPush;
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  height: 1px;
  background-color: #e3e3e3;
  margin: 16px 0;
}
</style>
