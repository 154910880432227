<template>
  <div :class="{ todo: true, 'postit-todo': theme == 'postit', 'done-today-todo': isChecked }">
    <div class="wrap">
      <div class="line" :style="{backgroundColor: categoryColor }"/>
      <div class="content" @click="moreTodo">{{ content }}</div>
      <div class="todo-checkbox-wrap">
        <div
          class="todo-checkbox"
          :style="{ border: '1px solid' + categoryColor, backgroundColor: (isChecked) ? categoryColor : '' }"
          @click="doneTodo"
        >
          <img src="@/assets/new/white-check-icon.svg" :style="{ opacity: isChecked ? 1 : 0, scale: isChecked ? 0.8 : 0}">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Todo",
  props: {
    id: {
      type: String,
      default: ""
    },
    categoryColor: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    deadline: {
      type: String,
      default: ""
    },
    isDone: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async doneTodo() {
      this.isChecked = !this.isChecked;
      if (localStorage.getItem("token")) {
        const url = "https://www.iflab.run/api/todo/done";
        const token = localStorage.getItem("token");
        const header = { headers: { "Authorization": token } };
        await axios.patch(url, {
          todo_id: this.id
        }, header);
      }
      if (this.isChecked) this.$emit("clickDoneTodo");
    },
    moreTodo() {
      this.$store.commit("setEditTodoId", this.id);
      this.$store.commit("setEditTodoContent", this.content);
      this.$store.commit("setEditTodoDeadline", this.deadline);
      this.$emit("clickMoreTodo");
    }
  },
  data() {
    return {
      theme: this.$store.state.todolistTheme,
      isChecked: this.isDone,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .todo {
    & > .wrap {
      background-color: #393b43;
      & > .content {
        color: #d6d9e2;
      }
    }
  }
  & .postit-todo {
    & > .wrap {
      background-color: #00000060;
    }
  }
}

.todo {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  & > .wrap {
    transition: all 0.2s;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 12px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
    flex-grow: 1;
    &:active {
      transition: all 0.1s;
      transform: scale(0.98);
    }
    & > .line {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: #04a3fb;
    }
    & > .content {
      width: 75vw;
      color: #3e3e3e;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: left;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 0;
    }
    & > .todo-checkbox-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 6px 6px 12px;
      -webkit-tap-highlight-color: transparent;
      & > .todo-checkbox {
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        & > img {
          transition: all 0.2s;
        }
      }
    }
  }
}

.button {
  -webkit-tap-highlight-color: transparent;

  position: relative;
  transition: all 0.2s;
  transition-timing-function: cubic-bezier(0.21, 1.28, 0.29, 1.28);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 22px;
  border-radius: 4px;

  user-select: none;
  overflow: hidden;
  margin-left: 4px;
  & > img {
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
  }
  &:active {
    transition: all 0.1s;
    transform: scale(0.95);
    filter: brightness(0.9);
  }
}
.check {
  background-color: #42d40e;
}
.more {
  background-color: #808080;
}

.done-today-todo {
  transition: all 0.2s;
  filter: brightness(0.8);
  transform: scale(0.95);
  & > .wrap {
    & > .content {
      transition: all 0.2s;
      color: #a0a0a0;
      text-decoration: line-through;
    }
  }
}

.postit-todo {
  & > .wrap {
    background-color: #ffffff60;
  }
}
</style>
