<template>
  <div :class="{ 'sign-up': true, disappear: !isSignUp }">
    <ServiceAgreement
      :isShowServiceAgreement="isShowServiceAgreement"
      @closeServiceAgreement="closeAgreement('service')"
    />
    <PrivacyAgreement
      :isShowPrivacyAgreement="isShowPrivacyAgreement"
      @closePrivacyAgreement="closeAgreement('privacy')"
    />
    <BlackScreen
      :isShow="isShowServiceAgreement || isShowPrivacyAgreement"
    />
    <TopBar
      title="회원가입"
      @clickButton="closeSignUp"
    />
    <div class="wrap">
      <Label label="아이디" />
      <Input
        type="text"
        placeholder="4~8자의 영소문자, 숫자"
        v-model="inputUsername"
        @focusInput="isDisabled = false;"
      />
    </div>
    <div class="wrap">
      <Label label="비밀번호" />
      <Input
        type="password"
        placeholder="8~16자의 영문자, 숫자, 특수문자"
        v-model="inputPassword"
        @focusInput="isDisabled = false;"
      />
    </div>
    <div class="wrap">
      <Label label="비밀번호 확인" />
      <Input
        type="password"
        placeholder="8~16자의 영문자, 숫자, 특수문자"
        v-model="inputPasswordCheck"
        @focusInput="isDisabled = false;"
      />
    </div>
    <div class="wrap">
      <Label label="닉네임" />
      <Input
        type="text"
        placeholder="2~8자의 한글, 영문자, 숫자"
        v-model="inputNickname"
        @focusInput="isDisabled = false;"
      />
    </div>
    <div class="wrap">
      <Label label="이메일" />
      <Input
        type="text"
        placeholder="email@example.com"
        v-model="inputEmail"
        @focusInput="isDisabled = false;"
      />
    </div>
    <CheckBox
      :isChecked="isCheckedService"
      underlineContent="서비스 이용약관"
      content="에 동의합니다."
      @check="check('service')"
      @contentClick="openAgreement('service')"
    />
    <CheckBox
      :isChecked="isCheckedPrivacy"
      underlineContent="개인정보 수집 및 이용"
      content="에 동의합니다."
      @check="check('privacy')"
      @contentClick="openAgreement('privacy')"
    />
    <CheckBox
      :isChecked="isCheckedAge"
      content="만 14세 이상입니다."
      @check="check('age')"
    />
    <SubmitButton
      title="회원가입"
      :isDisabled="!inputUsername
      || !inputPassword
      || !inputPasswordCheck
      || !inputNickname
      || !inputEmail
      || !isCheckedService
      || !isCheckedPrivacy
      || !isCheckedAge
      || isDisabled"
      @clickButton="SignUp"
    />
    <Notification
      type="error"
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </div>
</template>

<script>
import BlackScreen from "@/components/BlackScreen.vue";
import TopBar from "@/components/web/SecondaryTopBar.vue";
import ServiceAgreement from "@/components/web/ServiceAgreement.vue";
import PrivacyAgreement from "@/components/web/PrivacyAgreement.vue";
import Label from "@/components/Label.vue";
import Notification from "@/components/web/Notification.vue";
import SubmitButton from "@/components/web/SubmitButton.vue";
import Input from "@/components/Forms/Input.vue";
import CheckBox from "@/components/web/CheckBox.vue";
import axios from "axios";
export default {
  name: "SignUp",
  props: {
    isSignUp: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlackScreen,
    TopBar,
    ServiceAgreement,
    PrivacyAgreement,
    Label,
    Notification,
    SubmitButton,
    Input,
    CheckBox
  },
  methods: {
    async SignUp() {
      if (!this.inputNickname
        || !this.inputPassword
        || !this.inputPasswordCheck
        || !this.inputUsername
        || !this.inputEmail
        || !this.isCheckedService
        || !this.isCheckedPrivacy
        || !this.isCheckedAge) {
        this.isDisabled = true;
        return
      }
      if(this.inputPassword !== this.inputPasswordCheck) {
        this.showNotification("비밀번호가 일치하지 않습니다.");
        this.isDisabled = true;
        return
      }
      const url = "https://www.iflab.run/api/signup";
      await axios.post(url, {
          'username': this.inputUsername,
          'password': this.inputPassword,
          'nickname': this.inputNickname,
          'email': this.inputEmail,
      }).catch((response) => {
        if (response.response.status == 400) {
          this.showNotification("이미 존재하는 아이디입니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 401) {
          this.showNotification("아이디 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 402) {
          this.showNotification("비밀번호 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 403) {
          this.showNotification("닉네임 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 404) {
          this.showNotification("이메일 형식이 올바르지 않습니다.");
          this.isDisabled = true;
          return 0;
        } else if (response.response.status == 405) {
          this.showNotification("사용할 수 없는 아이디입니다.");
          this.isDisabled = true;
          return 0;
        }
      }).then((response) => {
        if (response.status == 200) {
          this.completeSignUp();
          this.inputUsername = "";
          this.inputPassword = "";
          this.inputPasswordCheck = "";
          this.inputNickname = "";
          this.inputEmail = "";
          this.isCheckedService = false;
          this.isCheckedPrivacy = false;
          this.isCheckedAge = false;
        }
      });
    },
    closeSignUp() {
      this.$emit("closeSignUp");
    },
    openAgreement(type) {
      // scroll 방지 이벤트
      if (type === "service") this.isShowServiceAgreement = true;
      else if (type === "privacy") this.isShowPrivacyAgreement = true;
    },
    closeAgreement(type) {
      // scroll 방지 이벤트 해제
      if (type === "service") this.isShowServiceAgreement = false;
      else if (type === "privacy") this.isShowPrivacyAgreement = false;
    },
    completeSignUp() {
      this.$emit("completeSignUp");
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
    check(type) {
      if (type === "service") this.isCheckedService = !this.isCheckedService;
      else if (type === "privacy") this.isCheckedPrivacy = !this.isCheckedPrivacy;
      else if (type === "age") this.isCheckedAge = !this.isCheckedAge;
    },
  },
  data() {
    return {
      inputUsername: "",
      inputPassword: "",
      inputPasswordCheck: "",
      inputNickname: "",
      inputEmail: "",
      isCheckedService: false,
      isCheckedPrivacy: false,
      isCheckedAge: false,
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
      isShowServiceAgreement: false,
      isShowPrivacyAgreement: false,
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .sign-up {
    background-color: #1c1c1c;
    & > .wrap {
      & > .input-wrap {
        background-color: #3e3e3e;
        -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        & > input {
          background-color: #3e3e3e;
          color: #f0f0f0;
          &::placeholder {
            color: #606060;
          }
        }
      }
    }
  }
}
.sign-up {
  transition: all 0.5s;
  z-index: 500;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 375px;
  padding-bottom: 64px;

  display: flex;
  flex-direction: column;

  background-color: #f0f0f0;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 1;
  & > .wrap {
    display: flex;
    flex-direction: column;
    margin: 0 16px 16px 16px;
    & > .input-wrap {
      display: flex;

      background-color: #ffffff;
      -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      border-radius: 4px;
      overflow: hidden;
      margin-top: 8px;

      & > input {
        -webkit-tap-highlight-color: transparent;

        padding: 16px 8px;
        border: none;
        outline: none;

        color: #3e3e3e;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -1px;

        flex-grow: 1;

        &::placeholder {
          color: #cecece;
        }
      }
    }
  }
}

.disappear {
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  top: 60%;
}
</style>
