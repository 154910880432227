<template>
  <div
    :class="{ category: true, 'color-category': true, 'postit-category': theme == 'postit' }"
    :style="{ backgroundColor: theme == 'postit' ? categoryColor + '40' : '' }"
  >
    <div class="header">
      <div
        class="category-name"
        :style="{ backgroundColor: categoryColor }"
        @click="editCategory"
      >
        {{ categoryName }}
      </div>
      <div class="line" :style="{backgroundColor: categoryColor }"/>
      <div
        class="add-todo-button"
        @click="addTodo"
        :style="{ backgroundColor: theme == 'postit' ? categoryColor : '' }"
      >
        <img src="@/assets/new/white-plus-icon.svg">
      </div>
    </div>
    <div class="todo-wrap">
      <Todo
        v-for="data in todos"
        :key="data.id"
        :id=data.id
        :categoryColor=categoryColor
        :content=data.content
        :deadline=data.deadline
        @clickDoneTodo="doneTodo"
        @clickMoreTodo="moreTodo"
      />
    </div>
  </div>
</template>

<script>
import Todo from "@/components/Todolist/Todo.vue";

export default {
  name: "Category",
  props: {
    categoryId: {
      type: String,
      default: ""
    },
    categoryName: {
      type: String,
      default: ""
    },
    categoryColor: {
      type: String,
      default: ""
    },
    todos: {
      type: Array,
    },
  },
  components: {
    Todo
  },
  methods: {
    editCategory() {
      this.$store.commit("setEditCategory", true);
      this.$store.commit("setEditCategoryId", this.categoryId);
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryColor", this.categoryColor);
    },
    addTodo() {
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryId", this.categoryId);
      this.$store.commit("setEditTodo", true);
    },
    doneTodo() {
      this.$emit('todoNotification', '완료');
    },
    moreTodo() {
      this.$store.commit("setEditCategoryName", this.categoryName);
      this.$store.commit("setEditCategoryId", this.categoryId);
      this.$store.commit("setEditTodo", true);
    },
  },
  data() {
    return {
      theme: this.$store.state.todolistTheme,
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .category {
    & > .header {
      & > .category-name {
        color: #26272c;
      }
      & > .add-todo-button {
        background-color: #393B43;
        & > img {
          opacity: 0.5;
        }
      }
    }
  }
}

.category {
  animation: fadeIn 0.5s;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 40px;
  & > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    & > .category-name {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -1px;
      padding: 2px 8px;

      border-radius: 4px;
      user-select: none;
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
    }
    & > .line {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
    }
    & > .add-todo-button {
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px;
      background-color: #cecece;
      border-radius: 4px;

      color: #ffffff;
      font-size: 8px;
      font-weight: bold;
      & > img {
        width: 8px;
        height: 8px;
      }
      &:active {
        transition: all 0.1s;
        transform: scale(0.9);
        filter: brightness(0.7);
      }
    }
  }
}

.postit-category {
  padding: 16px;
  margin: 0 16px 24px 16px;
  border-radius: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
