import { axiosInstance } from "@/api/instance";

/**
 * 할일 목록을 가져옵니다.
 */
const getTodoList = async () => {
  return axiosInstance.get("/todolist");
};


/**
 * 오늘 할일 목록을 가져옵니다.
 */
const getTodayTodoList = async () => {
  return axiosInstance.get("/todaytodolist");
};

export default {
  getTodoList,
  getTodayTodoList
};