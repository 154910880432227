<template>
  <div :class="{ 'add-category': true, disappear: !$store.state.editCategory}">
    <div class="topbar">
      <img src="@/assets/new/close-icon.svg" @click="initCategory">
      <div v-if="$store.state.nowCategoryId == -1" class="title">카테고리 생성</div>
      <div v-else class="title">카테고리 수정</div>
      <DeleteButton
        :isDisabled="$store.state.nowCategoryId == -1"
        @clickButton="deleteCategory"
      />
    </div>
    <div class="wrap">
      <Label label="이름" />
      <div class="input-wrap">
        <input
          type="text"
          v-model="categoryName"
          placeholder="카테고리 이름을 입력해주세요."
          onfocus="this.placeholder='';"
          onblur="this.placeholder='카테고리 이름을 입력해주세요.'"
          @keyup.enter="addCategory(); $event.target.blur()"
        />
        <CharacterCounter
          :length="categoryName.length"
          limit="20"
        />
      </div>
    </div>
    <div class="wrap">
      <Label label="색상" />
      <div class="palette" :style="{'background-image': 'linear-gradient(to right, ' + colorData1[0].colorCode + '80,' +colorData1[3].colorCode + '80,' +colorData1[7].colorCode + '80)'}">
        <div
          v-for="color in colorData1"
          :key="color.colorCode"
          :class="{ color: true, 'pick-color': categoryColor === color.colorCode }"
          :style="{ backgroundColor: color.colorCode }"
          @click="pickColor(color.colorCode)"
        />
      </div>
    </div>
    <SubmitButton
      :title="$store.state.nowCategoryId == -1 ? '생성 완료' : '수정 완료'"
      :isDisabled="categoryName.length == 0 || categoryColor.length == 0"
      @clickButton="addCategory"
      :isGray="true"
    />
  </div>
</template>

<script>
import Label from '@/components/Label.vue';
import DeleteButton from '@/components/Buttons/DeleteButton.vue';
import CharacterCounter from '@/components/CharacterCounter.vue';
import SubmitButton from '@/components/web/SubmitButton.vue';
import axios from 'axios';

const colorData1 = [
  { colorCode: '#DA6FFF', },
  { colorCode: '#FF6ACC', },
  { colorCode: '#FF5A5F', },
  { colorCode: '#FFA34F', },
  { colorCode: '#FFE600', },
  { colorCode: '#51E168', },
  { colorCode: '#408CFF', },
  { colorCode: '#747AFF', },
];

export default {
  name: 'AddCategory',
  components: {
    Label,
    DeleteButton,
    CharacterCounter,
    SubmitButton
  },
  watch: {
    '$store.state.editCategory': function() {
      if(this.$store.state.editCategory) {
        this.categoryId = this.$store.state.nowCategoryId;
        this.categoryName = this.$store.state.nowCategoryName;
        this.categoryColor = this.$store.state.nowCategoryColor;
      }
    },
    categoryName: function() {
      if(this.categoryName.length > 20) {
        this.categoryName = this.categoryName.slice(0, 20);
      }
    },
  },
  methods: {
    async addCategory() {
      if (this.categoryName.length > 0 && this.categoryColor.length > 0) {
        // 카테고리 생성
        if (this.$store.state.nowCategoryId == -1) {
          const url = "https://www.iflab.run/api/category";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.post(url, {
            category_name: this.categoryName,
            category_color: this.categoryColor
          }, header);
          this.$emit('categoryNotification', '생성');
        } else {
          const url = "https://www.iflab.run/api/category";
          const token = localStorage.getItem("token")
          const header = { headers: { "Authorization": token } };
          await axios.patch(url, {
            category_id: this.categoryId,
            category_name: this.categoryName,
            category_color: this.categoryColor
          }, header);
          this.$emit('categoryNotification', '수정');
        }
        this.initCategory();
      }
    },

    async deleteCategory() {
      const url = "https://www.iflab.run/api/category/disappear";
      const token = localStorage.getItem("token")
      const header = { headers: { "Authorization": token } };
      await axios.patch(url, {
        category_id: this.categoryId,
      }, header);
      this.$emit('categoryNotification', '삭제');
      this.initCategory();
    },

    pickColor(colorCode) {
      this.categoryColor = colorCode;
    },

    initCategory() {
      this.$store.commit("setEditCategory", false);
      setTimeout(() => {
        this.categoryId = -1;
        this.categoryName = '';
        this.categoryColor = '';
        this.$store.commit("setEditCategoryId", -1);
        this.$store.commit("setEditCategoryName", '');
        this.$store.commit("setEditCategoryColor", '');
      }, 300);
    },
  },
  data() {
    return {
      colorData1,
      categoryId: -1,
      categoryName: '',
      categoryColor: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.dark-mode {
  & .add-category {
    background-color: #202020;
    & > .topbar {
      background-color: #1c1c1c;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
    & > .wrap {
      & > .input-wrap {
        background-color: #3e3e3e;
        -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
        & > input {
          background-color: #3e3e3e;
          color: #f0f0f0;
          &::placeholder {
            color: #606060;
          }
        }
      }
      & > .palette {
        & > .color {
          outline: 2px solid #00000000;
        }
        & > .pick-color {
          filter: brightness(0.8);
          outline: 2px solid #00000080;
        }
      }
    }
  }
}

.add-category {
  transition: all 0.3s;
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 375px;
  min-height: 480px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  & > .topbar {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px 8px 0 0;

    box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);

    & > img {
      cursor: pointer;
    }

    & > .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: #408cff;
      font-size: 16px;
      font-weight: bold;
    }
  }
  & > .wrap {
    display: flex;
    flex-direction: column;
    margin: 0 16px 16px 16px;
    & > .input-wrap {
      display: flex;
      position: relative;

      background-color: #ffffff;
      -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
      border-radius: 4px;
      overflow: hidden;
      margin-top: 8px;

      & > input {
        -webkit-tap-highlight-color: transparent;

        padding: 16px 48px 16px 8px;
        border: none;
        outline: none;

        color: #3e3e3e;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -1px;

        flex-grow: 1;

        &::placeholder {
          color: #cecece;
        }
      }
    }
    & > .palette {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 12px;
      padding: 16px;
      border-radius: 8px;
      & > .color {
        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        cursor: pointer;
        outline: 2px solid #00000000;
        &:hover {
          transition: all 0.1s;
          filter: brightness(0.9);
        }
        &:active {
          transition: all 0.1s;
          transform: scale(0.9);
        }
      }
      & > .pick-color {
        transition: all 0.2s;
        filter: brightness(0.9);
        outline: 2px solid #ffffff80;
      }
    }
  }
}

.disappear {
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  top: 60%;
}
</style>
