<template>
  <div class="sign-in">
    <SignUp
      :isSignUp="isSignUp"
      @closeSignUp="closeSignUp"
      @completeSignUp="completeSignUp"
    />
    <BlackScreen :isShow="isSignUp" />
    <!--img class="close-button" src="@/assets/new/close-icon.svg" @click="goSetting()" /-->
    <div :class="{ 'logo-wrap': true, 'appear-logo': true, 'disappear-logo': isClickedGoButton }">
      <img class="logo" src="@/assets/web/Logo.svg" />
      <div class="logo-text">더 나은 하루를 보낼 수 있게</div>
    </div>
    <div class="login-wrap">
      <div class="wrap">
        <Label label="아이디" />
        <Input
          type="text"
          placeholder="아이디"
          v-model="inputUsername"
          @focusInput="isDisabled = false; isNotSignIn = false;"
          @pressEnter="SignIn"
        />
      </div>
      <div class="wrap">
        <Label label="비밀번호" />
        <Input
          type="password"
          placeholder="비밀번호"
          v-model="inputPassword"
          @focusInput="isDisabled = false; isNotSignIn = false;"
          @pressEnter="SignIn"
        />
      </div>
      <div class="text-wrap">
        <span :class="{'disappear-text': inputUsername || inputPassword}" @click="openSignUp">
          <u> 아직 회원이 아니신가요?  </u>
        </span>
        <span :class="{error: true, 'disappear-text': !isNotSignIn, 'appear-error': isNotSignIn}">
          아이디 또는 비밀번호가 일치하지 않습니다.
        </span>
      </div>
      <SubmitButton
        title="로그인"
        :isDisabled="!inputUsername || !inputPassword || isDisabled"
        @clickButton="SignIn"
      />
    </div>
    <Notification
      :isAppear="appearNotification"
      :content="notificationContent"
      @closeNotification="appearNotification = false"
    />
  </div>
</template>

<script>
import BlackScreen from "@/components/BlackScreen.vue";
import Label from "@/components/Label.vue";
import SignUp from "@/views/web/SignUp.vue";
import Notification from "@/components/web/Notification.vue";
import SubmitButton from "@/components/web/SubmitButton.vue";
import Input from "@/components/Forms/Input.vue";
import axios from "axios";
export default {
  name: "SignIn",
  components: {
    BlackScreen, Label, SignUp, Notification, SubmitButton, Input
  },
  created() {
    if (this.$store.state.isSignIn && localStorage.getItem("token")) {
      this.goMain();
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkWindowSize)
    this.checkWindowSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    checkWindowSize() {
      if (window.innerWidth < 800) {
        this.$router.push("/signin");
      }
    },
    async SignIn() {
      if(!this.inputUsername || !this.inputPassword) return;
      const url = "https://www.iflab.run/api/signin";
      await axios.post(url, {
        "username": this.inputUsername,
        "password": this.inputPassword,
      },
      {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      }}).then(data => {
        this.$store.commit('setToken', data.data.access_token);
        this.$store.commit('setIsSignIn', true);
        //uthis.$store.commit("setNickname", this.inputUsername);
        //this.$store.commit("setPassword", this.inputPassword);
        //this.$store.commit("setIsLogin", true);
        this.goMain();
      }).catch(error => {
        if(error.response.status == 400) {
          this.isNotSignIn = true;
          this.isDisabled = true;
          setTimeout(() => {
            this.isNotSignIn = false;
          }, 2000);
        }
      })
    },
    goMain() {
      this.isClickedGoButton = true;
      setTimeout(() => {
        this.$router.push("/web");
        this.isClickedGoButton = false;
      }, 500);
    },
    goSetting() {
      this.isClickedGoButton = true;
      setTimeout(() => {
        this.$router.push("/setting");
        this.isClickedGoButton = false;
      }, 500);
    },
    openSignUp() {
      this.isSignUp = true;
    },
    closeSignUp() {
      this.isSignUp = false;
    },
    completeSignUp() {
      this.isSignUp = false;
      this.showNotification("성공적으로 회원가입이 완료되었습니다!");
    },
    showNotification(content) {
      this.appearNotification = true;
      this.notificationCount += 1;
      this.notificationContent = content;
      setTimeout(() => {
        this.notificationCount -= 1;
        if (this.notificationCount == 0) this.appearNotification = false;
      }, 2000)
      setTimeout(() => {
        if (this.notificationCount == 0) this.notificationContent = '';
      }, 2300);
    },
  },
  data() {
    return {
      isSignUp: false,
      inputUsername: "",
      inputPassword: "",
      isClickedGoButton: false,
      isNotSignIn: false,
      isDisabled: false,
      appearNotification: false,
      notificationCount: 0,
      notificationContent: "",
    };
  },
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & .sign-in {
    background-color: #1c1c1c;
    & > .logo-wrap {
      & > img {
        background-color: #1c1c1c;
      }
    }
    & > .login-wrap {
      background-color: #26272c;
      & > .wrap {
        & > .input-wrap {
          background-color: #26272c;
          -webkit-box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
          box-shadow: 0px 0px 0px rgba(96, 96, 96, 0);
          & > input {
            background-color: #3e3e3e;
            color: #f0f0f0;
            &::placeholder {
              color: #606060;
            }
          }
        }
      }
    }
  }
}

.sign-in {
  z-index: 300;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f1f5;
  min-height: 100vh;
  & > .close-button {
    position: fixed;
    top: 16px;
    left: 16px;
    width: 24px;
    height: 24px;
    z-index: 100;
  }
  & > .logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    & > .logo {
      width: 250px;
      height: 50px;
      margin-bottom: 8px;
    }
    & > .logo-text {
      font-family: "Noto Sans KR", sans-serif;
      color: #408cff;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -2px;
    }
  }
  & > .appear-logo {
    animation: appear 1s forwards;
  }
  & > .disappear-logo {
    animation: disappear 0.5s forwards;
  }
  & > .login-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 24px 0 64px;

    background-color: #ffffff;
    border-radius: 16px;
    overflow: hidden;
    & > .wrap {
      display: flex;
      flex-direction: column;
      margin: 0 24px 24px 24px;
      min-width: 343px;
      & > .input-wrap {
        display: flex;

        background-color: #ffffff;
        -webkit-box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
        box-shadow: 0px 4px 16px rgba(96, 96, 96, 0.15);
        border-radius: 4px;
        overflow: hidden;
        margin-top: 8px;

        & > input {
          -webkit-tap-highlight-color: transparent;

          padding: 16px 8px;
          border: none;
          outline: none;

          color: #3e3e3e;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -1px;

          flex-grow: 1;

          &::placeholder {
            color: #cecece;
          }
        }
      }
    }
    & > .text-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 24px;
      height: 17px;
      & > span {
        transition: all 0.5s;
        position: absolute;
        color: #a0a0a0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.5px;
        transform: translateY(0px);
        opacity: 1;
        user-select: none;
        cursor: pointer;
      }
      & > .error {
        color: #ff5151;
        cursor: default;
      }
      & > .disappear-text {
        animation: none;
        transition: all 0.5s;
        transform: translateY(12px);
        opacity: 0;
        pointer-events: none;
        cursor: default;
      }
      & > .appear-error {
        animation: wrongAnimation 0.4s;
      }
    }
  }
}

@keyframes appear {
  0% {
    transform: scale(1.5) translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(1.5) translateY(-40px);
    opacity: 0;
  }
}

@keyframes wrongAnimation {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(-40px);
  }
  40% {
    transform: translateX(30px);
  }
  60% {
    transform: translateX(-20px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
