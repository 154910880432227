<template>
  <div class="circle-wrap">
    <div :class="{ 'img-wrap': true, 'appear-img-wrap': !isTapOpen }" @click="openTap">
      <img
        :class="{ 'circle-eyes': true, 'appear-circle-eyes': isAnimation }"
        src="@/assets/web/circle-eyes.svg"
      />
      <div :class="{ 'circle-bg': true, 'appear-circle-bg': isAnimation }">
        <img class=circle src="@/assets/web/circle.svg" />
      </div>
    </div>
    <div :class="{ 'user-tap': true, 'appear-user-tap': isTapOpen }">
      <div class="topbar">
        <div class="title">설정</div>
        <img src="@/assets/new/close-icon.svg" @click="closeTap">
      </div>
      <div class="content">
        <div :class="{ wrap: true, 'appear-wrap': isTapOpen }" @click="changeMode">
          <div class="title">모드 변경</div>
          <div class="right-wrap">
            {{ nowMode }}
          </div>
        </div>
        <div :class="{ wrap: true, 'appear-wrap': isTapOpen }" @click="goSignIn">
          <div class="title">로그아웃</div>
          <div class="right-wrap">
            <img src="@/assets/new/blue-arrow-icon.svg"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCircle",
  data() {
    return {
      isTapOpen: false,
      isDarkmode: this.$store.state.isDarkmode,
      isSystem: this.$store.state.isSystem,
      isAnimation: true,
      mode: 'system',
    }
  },
  computed: {
    nowMode() {
      if (this.isSystem) {
        return '기기 설정';
      } else if (this.isDarkmode) {
        return '다크 모드';
      } else {
        return '라이트 모드';
      }
    }
  },
  mounted() {
    if (this.isSystem ) {
      this.mode = 'system';
    } else if (!this.isDarkmode) {
      this.mode = 'light';
    } else {
      this.mode = 'dark';
    }
  },
  methods: {
    openTap() {
      this.isTapOpen = true;
      setTimeout(() => {
        this.isAnimation = false;
      }, 300);
    },
    closeTap() {
      this.isTapOpen = false;
      this.isAnimation = true;
    },
    goSignIn() {
      this.$store.commit('setIsSignIn', false);
      this.$store.commit('setNickname', '');
      this.$store.commit('setCreatedAt', '');
      this.$store.commit('setAllTodolist', []);
      this.$store.commit('setEditSubjectArray', []);
      this.$store.commit('setToken', '');
      localStorage.removeItem("token");
      localStorage.removeItem("isSignIn");
      setTimeout(() => {
        this.$router.push("/signin");
      }, 100);
    },
    changeMode() {
      switch(this.mode) {
        case 'system':
          this.isSystem = true;
          this.$store.commit('setIsSystem', this.isSystem)
          this.mode = 'light';
          break;

        case 'light':
          this.isSystem = false;
          this.$store.commit('setIsSystem', this.isSystem)
          this.isDarkmode = false;
          this.$store.commit('setIsDarkmode', this.isDarkmode)
          this.mode = 'dark';
          break;
        
        case 'dark':
          this.isSystem = false;
          this.$store.commit('setIsSystem', this.isSystem)
          this.isDarkmode = true;
          this.$store.commit('setIsDarkmode', this.isDarkmode)
          this.mode = 'system';
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dark-mode {
  & div {
    & > .user-tap {
      & > .topbar {
        transition: all 0.3s;
        background-color: #26272c;
        & > .title {
          transition: all 0.3s;
          color: #c7c9d0;
        }
      }
      & > .content {
        & > .wrap {
          transition: all 0.3s;
          background-color: #373a40;
          & > .title {
            transition: all 0.3s;
            color: #ccd3eb;
          }
          & > .right-wrap {
            transition: all 0.3s;
            color: #9093bf;
          }
        }
      }
    }
  }
}

.circle-wrap {
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.img-wrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  right: 0;
  bottom: 0;
  transition: all 0.3s;

  transform-style: preserve-3d;
  perspective: 200px;
  perspective-origin: center;

  width: 80px;
  height: 80px;
  cursor: pointer;
  scale: 0;
  transform-origin: center;
  opacity: 0;
  &:hover {
    transition: all 0.3s;
    filter: brightness(1.1);
  }
  &:active {
    transition: all 0.1s;
    filter: brightness(0.9);
    transform: scale(1.1, 0.9);
  }
}

.appear-img-wrap {
  transition: all 0.3s;
  scale: 1;
  opacity: 1;
}


.circle-bg {
  position: absolute;
  & > .circle {
    width: 80px;
    height: 80px;
  }
}

.appear-circle-bg {
  animation: moveCircle 9s 3s infinite;
}

.circle-eyes {
  z-index: 100;
  width: 24px;
  height: 20px;
}

.appear-circle-eyes {
  animation: moveCircleEyes 9s 3s infinite;
}

.user-tap {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 448px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  transform: scale(0.5);
  transform-origin: right bottom;
  opacity: 0;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  & > .topbar {
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background-color: #f0f1f5;
    user-select: none;
    & > .title {
      transition: all 0.3s;
      font-size: 20px;
      font-weight: bold;
      color: #2c3e50;
    }
    & > img {
      cursor: pointer;
    }
  }
  & > .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: linear-gradient(to bottom, #4098ff, #0066ff);
    padding: 12px 8px;
    gap: 8px;
    & > .wrap {
      transition: all 0.3s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      background-color: #eff3ff;
      padding: 12px 8px 12px 8px;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      transform: translateY(-40px);
      opacity: 0;
      & > .title {
        font-size: 16px;
        font-weight: bold;
        color: #757f9c;
      }
      &:hover {
        transition: all 0.3s;
        filter: brightness(1.1);
      }
      &:active {
        transition: all 0.1s;
        filter: brightness(0.9);
        transform: scale(0.95);
      }
      & > .right-wrap {
        transition: all 0.3s;
        color: #85a4f0;
        font-size: 12px;
        font-weight: 600;
      }
    }
    & > .appear-wrap {
      transition: all 0.3s;
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

.appear-user-tap {
  transition: all 0.3s;
  transform: scale(1);
  opacity: 1;
  user-select: auto;
  pointer-events: auto;
}

@keyframes moveCircle {
  0% {
    scale: 1;
  }
  1% {
    scale: 1;
  }
  10% {
    scale: 1.5;
  }
  20% {
    scale: 1.5;
  }
  30% {
    scale: 1.5;
  }
  39% {
    scale: 1;
  }
  100% {
    scale: 1;
  }
}
@keyframes moveCircleEyes {
  0% {
    transform: rotateY(0deg) translateZ(0px);
  }
  10% {
    transform: rotateY(-30deg) translateZ(56px);
  }
  20% {
    transform: rotateY(30deg) translateZ(56px);
  }
  30% {
    transform: rotateY(-30deg) translateZ(56px);
  }
  40% {
    transform: rotateY(0deg) translateZ(0px);
  }
  100% {
    transform: rotateY(0deg) translateZ(0px);
  }
}
</style>
