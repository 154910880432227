<template>
  <div
    :class="{
     'action-screen': true,
     'active-action-screen': isShow
    }"
    @click="touchActionScreen"
  />
</template>


<script>
export default {
  name: 'ActionScreen',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    touchActionScreen() {
      this.$emit('touchActionScreen');
    },
  },
}
</script>
<style lang="scss" scoped>
.action-screen {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
.active-action-screen {
  transition: all 0.3s;
  opacity: 1;
  pointer-events: auto;
}
</style>
