import Setting from '../views/Setting'
import ChangeNickname from '../views/ChangeNickname'
import ChangePassword from '../views/ChangePassword'
import ChangeEmail from '../views/ChangeEmail'
import ChangeTheme from '../views/ChangeTheme'
import ServicePolicy from '../views/ServicePolicy'
import PrivacyPolicy from '../views/PrivacyPolicy'
import DevelopmentNote from '../views/DevelopmentNote'
import DeleteAccount from '../views/DeleteAccount'
import Push from '../views/Push'

const SettingRoutes = [
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/setting/changenickname',
    name: 'ChangeNickname',
    component: ChangeNickname
  },
  {
    path: '/setting/changepassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/setting/changeemail',
    name: 'ChangeEmail',
    component: ChangeEmail
  },
  {
    path: '/setting/changetheme',
    name: 'ChangeTheme',
    component: ChangeTheme
  },
  {
    path: '/servicepolicy',
    name: 'ServicePolicy',
    component: ServicePolicy
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/developmentnote',
    name: 'DevelopmentNote',
    component: DevelopmentNote
  },
  {
    path: '/deleteaccount',
    name: 'DeleteAccount',
    component: DeleteAccount
  },
  {
    path: '/setting/push',
    name: 'Push',
    component: Push
  }
]

export default SettingRoutes;
